<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <app-whatsapp-corner></app-whatsapp-corner>
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <section
      id="home"
      class="section welcome-area bg-overlay d-flex align-items-center h-100vh"
      style="color: #1d1d1d !important"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <!-- Welcome Intro Start -->
          <div class="col-12 col-lg-7 d-none d-lg-block">
            <div class="welcome-intro">
              <h1 class="text-white">Create an account!</h1>
              <p class="text-white my-4">
                Come join us and be a part of ONMARKET Friends
              </p>
              <!-- <div class="button-group store-buttons d-flex justify-content-left">
                                <a href="#">
                                    <img src="assets/img/google-play.png" alt="">
                                </a>
                                <a href="#">
                                    <img src="assets/img/app-store.png" alt="">
                                </a>
                            </div> -->
              <!-- <div class="welcome-thumb mx-auto" data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                                <img src="assets/img/for-homepage.png" alt="">
                            </div> -->
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-5 mt-5 padding-box">
            <!-- Contact Box -->
            <div
              class="bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg"
            >
              <!-- Contact Form -->
              <form id="contact-form">
                <div class="contact-top">
                  <h3 class="contact-title" style="color: #1d1d1d">Sign Up</h3>
                  <h5 class="text-secondary fw-3 py-3">
                    Fill all fields so we can get some info about you. We'll
                    never send you spam
                  </h5>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                            ><i class="fas fa-envelope-open"></i
                          ></span>
                        </div>
                        <input
                          type="email"
                          placeholder="Email"
                          class="form-control"
                          required
                          [formControl]="form.controls['email']"
                        />
                      </div>
                      <mat-hint>
                        <small
                          *ngIf="
                            form.controls['email'].hasError('required') &&
                            form.controls['email'].touched
                          "
                          class="text-danger support-text"
                          >Email is required.</small
                        >
                      </mat-hint>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                            ><i class="fas fa-phone"></i
                          ></span>
                        </div>
                        <input
                          class="form-control"
                          placeholder="Phone Number"
                          required
                          [formControl]="form.controls['phone_number']"
                        />
                      </div>
                      <mat-hint>
                        <small
                          *ngIf="
                            form.controls['phone_number'].hasError(
                              'required'
                            ) && form.controls['phone_number'].touched
                          "
                          class="text-danger support-text"
                          >Phone number is required.</small
                        >
                      </mat-hint>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                            ><i class="fas fa-user-alt"></i
                          ></span>
                        </div>
                        <input
                          class="form-control"
                          placeholder="Username"
                          required
                          [formControl]="form.controls['username']"
                        />
                      </div>
                      <mat-hint>
                        <small
                          *ngIf="
                            form.controls['username'].hasError('required') &&
                            form.controls['username'].touched
                          "
                          class="text-danger support-text"
                          >Username is required.</small
                        >
                      </mat-hint>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                            ><i class="fas fa-user-alt"></i
                          ></span>
                        </div>
                        <input
                          class="form-control"
                          placeholder="First Name"
                          required
                          [formControl]="form.controls['firstname']"
                        />
                      </div>
                      <mat-hint>
                        <small
                          *ngIf="
                            form.controls['firstname'].hasError('required') &&
                            form.controls['firstname'].touched
                          "
                          class="text-danger support-text"
                          >First name is required.</small
                        >
                      </mat-hint>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                            ><i class="fas fa-user-alt"></i
                          ></span>
                        </div>
                        <input
                          class="form-control"
                          placeholder="Last Name"
                          required
                          [formControl]="form.controls['lastname']"
                        />
                      </div>
                      <mat-hint>
                        <small
                          *ngIf="
                            form.controls['lastname'].hasError('required') &&
                            form.controls['lastname'].touched
                          "
                          class="text-danger support-text"
                          >First name is required.</small
                        >
                      </mat-hint>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"
                            ><i class="fas fa-unlock-alt"></i
                          ></span>
                        </div>
                        <input
                          type="password"
                          class="form-control"
                          style="background-color: white"
                          placeholder="Password"
                          type="password"
                          required
                          minlength="6"
                          matInput
                          [type]="hide ? 'password' : 'text'"
                          [formControl]="form.controls['password']"
                        />
                      </div>
                      <mat-hint>
                        <small
                          *ngIf="
                            form.controls['password'].hasError('required') &&
                            form.controls['password'].touched
                          "
                          class="text-danger support-text"
                          >Password is required.</small
                        >
                      </mat-hint>
                    </div>

                    <!-- <div id="centerDiv" class="row d-flex checkbox">
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                            <label class="form-check-label contact-bottom" for="exampleCheck1">
                                                <span class="d-inline-block">By signing up, you accept our 
                                                    <a href="/terms-and-conditions" style="color: #3571b6;">Terms</a>
                                                    &amp; <a href="/privacy-policy" style="color: #3571b6;">Privacy
                                                        Policy</a></span></label>
                                        </div> -->
                  </div>

                  <div class="col-12">
                    <button
                      color="primary"
                      style="color: #262626"
                      class="login-button btn btn-lg mt-3 w-100"
                      type="submit"
                      [disabled]="!form.valid"
                      (click)="register()"
                    >
                      Sign Up
                    </button>
                  </div>
                  <div class="col-12">
                    <span class="d-block pt-2 mt-4 border-top clickable"
                      >Already have an account?
                      <a (click)="signIn()" style="color: #3571b6"
                        >Sign In</a
                      ></span
                    >
                  </div>
                </div>
              </form>
              <p class="form-message"></p>
            </div>
          </div>
        </div>
      </div>
      <!-- Shape Bottom -->
      <div class="shape-bottom">
        <svg
          viewBox="0 0 1920 310"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          class="svg replaced-svg"
        >
          <title>sApp Shape</title>
          <desc>Created with Sketch</desc>
          <defs></defs>
          <g
            id="sApp-Landing-Page"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="sApp-v1.0"
              transform="translate(0.000000, -554.000000)"
              fill="#3571b6"
            >
              <path
                d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                id="sApp-v1.0"
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </section>
  </div>
</div>
