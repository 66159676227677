import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute } from '@angular/router';
import { NumberFormatPipe } from '../../../../../number.pipe';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-myprofile',
  templateUrl: './detail-profile.component.html',
  styleUrls: ['./detail-profile.component.css'],
  providers: [NumberFormatPipe, ApiService]
})
export class DetailProfileComponent implements OnInit{
  // imageUrl: string = "https://usapi.onindonesia.id/get-profile-img/"+ localStorage.getItem("username")
  imageUrl: string
  profileData = new FormData()
  genders = ['Laki-Laki', 'Perempuan']
  basicProfileForm: FormGroup

  submitted = false
  userinfo : any = {
    username: null,
    first_name: null,
    last_name: null,
    email: null,
    date_of_birth: null,
    gender: null,
    phone_number: null
  };
  constructor(
    private service: ApiService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    // this.service.userBasicProfile().subscribe(data => {
    //   this.userProfile = data
    //   console.log("DetailProfileComponent-UserProfile")
    //   console.log(data)
    // }, e => {
    //   console.log(e)
    // })
  }

  ngOnInit(): void {
    this.basicProfileForm = this.fb.group({
      profile_img: [null],
      date_of_birth: [null, Validators.compose([Validators.required])],
      phone_number: [null, Validators.compose([Validators.required])],
      gender: [null, Validators.compose([Validators.required])]
    })
    
    this.service.userBasicProfile().subscribe(
      response => {
        // console.log(response)
        this.userinfo = response
        this.imageUrl = this.service.getUserImage(response.username)
        for (const key in this.basicProfileForm.controls) {
          this.basicProfileForm.controls[key].setValue(this.userinfo[key])
        }
      }
    );
  }

  // selectFiles(event) {
  //   if(event.target.files){
  //     var reader = new FileReader()
  //     reader.readAsDataURL(event.target.files[0])
  //     reader.onload = (event:any)=>{
  //       this.imagePath = event.target.result
  //     }
  //   }
  // }

  handleFileInput(file: FileList) {
    // console.log("HR")
    // Set File to Upload
    this.profileData.set('profile_img', file.item(0))

    //Show image preview
    var reader = new FileReader();
    reader.onload = (event:any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(file.item(0));
  }

  OnSubmit(){
    for (const i in this.basicProfileForm.controls) {
      if (this.basicProfileForm.controls.hasOwnProperty(i)) {
        this.basicProfileForm.controls[i].markAsDirty()
        this.basicProfileForm.controls[i].updateValueAndValidity()
      }
    }

    if (this.basicProfileForm.valid) {
      const basicProfileJSON = JSON.stringify({
        gender: this.basicProfileForm.get('gender').value,
        // date_of_birth: dateOfBirth.toDateString(),
        date_of_birth: dayjs(this.basicProfileForm.get('date_of_birth').value).format('YYYY-MM-DD'),
        phone_number: this.basicProfileForm.get('phone_number').value
      })
  
      this.profileData.set('basic_profile', basicProfileJSON)
      // console.log(basicProfileJSON)
      this.service.updateBasicProfile(this.profileData, localStorage.getItem("jwt")).subscribe((res) => {
        this.dialog.open(DialogAlert, {
          width: '300px',
          data: {
            status: true
          }
        });
        
        console.log("SUCCESS")
      }, e => {
        console.log(e)
        this.dialog.open(DialogAlert, {
          width: '300px',
          data: {
            status: false,
            message: 'Gagal mengubah informasi Profil. Mohon coba beberapa saat lagi.'
          }
        });
      })
    } else {
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Mohon lengkapi informasi Profil'
        }
      });
    }
  }

  useDefaultProfileImg(){
    this.imageUrl = "assets/img/user-avatar.png";
  }

}

@Component({
  selector: 'dialog-alert',
  templateUrl: 'dialog-alert.html',
  styleUrls: ['../myprofile.component.css'],
})

export class DialogAlert {
  icon: string = 'check_circle';
  message: string = 'Profil berhasil diubah';
  constructor(
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!data.status) {
      this.icon = 'error'
      this.message = data.message
    }
  }
}

