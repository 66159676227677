<div class="py-3">
  <div class="card mb-2">
      <div class="card-body py-3">
        <div class="col-12 d-flex flex-row flex-wrap" style="padding: 0px;">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <h3>Informasi Kartu</h3>
            <!-- <p>Seluruh jenis pembayaran tampil disini</
              p> -->
              <!-- <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Cari Alamat">
              </mat-form-field> -->
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 d-flex flex-row justify-content-end align-items-center">
              <a class="btn" (click)="openDialog('Tambah',{})"><i class="fas fa-plus mr-2"></i>Tambah Alamat</a>
            </div>
        </div>
      </div>
    </div>
</div>

<div fxLayout="row wrap">
  <div fxFlex="100">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
        <ng-container matColumnDef="id" >
          <th mat-header-cell *matHeaderCellDef> Id </th>
          <td mat-cell *matCellDef="let element"> {{element.id_payment}} </td>
        </ng-container>
        <ng-container matColumnDef="card_information" >
          <th mat-header-cell *matHeaderCellDef> Card </th>
          <td mat-cell *matCellDef="let element"> 
            
            <!-- <div class="d-flex">
              <div class="col-lg-3 col-sm-6 d-flex flex-column d-none d-md-block " >
                <p>Jenis Kartu</p>
                <p>Pemegang Kartu</p>
                <p class="mb-3">Nomor Kartu</p>
                <p>Masa Habis Kartu</p>
                <p>CVV</p>     
              </div>
              <div class="col-lg-9 col-sm-6">
                <p class="bold" style="font-size: 13px;">{{element.card}}</p>
                <p>{{element.card_name}}</p>
                <p class="mb-3">{{element.card_number}}</p>
                <p>{{element.card_exp_m}}/{{element.card_exp_y}}</p>
                <p>{{element.card_cvv}}</p>     
              </div>
            </div> -->
            
            <div >
              <tr>
                <th>Jenis Kartu</th>
                  <td style="font-weight: 800;">{{element.card}}</td>
              </tr>
              <tr>
                <th>Nama Pemegang Kartu</th>
                  <td>{{element.card_name}}</td>
              </tr>
              <tr>
                <th>Nomor Kartu</th>
                  <td>{{element.card_number}}</td>
              <tr>
              <tr>
                <th>Masa Habis Kartu</th>
                  <td>{{element.card_exp_m}}/{{element.card_exp_y}}</td>
              </tr>
              <tr>
                <th>CVV</th>
                  <td>{{element.card_cvv}}</td>
              </tr>
            </div>    
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element" class="action-link">
            <div class="d-flex flex-row wrap" > 
                <a class="btn-action" (click)="openDialog('Hapus',element)" style="cursor: pointer;">
                  <i class="fas fa-trash mr-2" ></i>Hapus
                </a>
                <a class="btn-action" (click)="openDialog('Ubah',element)" style="cursor: pointer;">
                  <i class=" fas fa-edit mr-2"></i>Ubah
                </a>
            </div>
          </td>
        </ng-container>
        
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
      </table>

      <mat-paginator [pageSizeOptions]="[3, 6, 10]" showFirstLastButtons></mat-paginator>

    </div>
  </div>
</div>
