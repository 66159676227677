<div class="main">
    <app-scrollup></app-scrollup>
    <app-header-one></app-header-one>
    <!-- <app-welcome-one></app-welcome-one> -->
    <app-counter></app-counter>
    <app-feature-one></app-feature-one>
    <app-service-one></app-service-one>
    <app-discover-one></app-discover-one>
    <app-work></app-work>
    <app-screenshot-one></app-screenshot-one>
    <app-review-one></app-review-one>
    <app-pricing-one></app-pricing-one>
    <app-faq-one></app-faq-one>
    <app-team></app-team>
    <app-download></app-download>
    <app-subscribe></app-subscribe>
    <app-contact></app-contact>
    <app-footer-one></app-footer-one>
</div> 