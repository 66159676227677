<!-- <mat-tab label="Profil Toko"> -->
    <div class="row">
        <div class="col-12 d-flex flex-wrap justify-content-center py-4">
            <div class="py-1">
                <img src="assets/img/banner1.png" alt="">
            </div>
            <div class="py-1">
                <img src="assets/img/banner2.png" alt="">
            </div>
        </div>
        <div class="col-12">
            <div class="card p-3">
                <div class="card-body">
                    <h3 class="card-title">Informasi Toko</h3>
                    <p class="card-text">{{storeInfos.description}}</p>
                </div>
            </div>
        </div>
        <div class="col-12 pt-5">
            <h3>Best Seller dari Toko</h3>
            <div class="row">
                <div class="d-none d-md-block col-md-4 col-lg-2 mb-3 pl-1 pr-1"
                    *ngFor="let bestSeller of bestSellers">
                    <a href="/product?product_id={{bestSeller.product_id}}">
                        <mat-card class="content h-100" style="overflow: hidden;">
                            <img mat-card-image class="product-card-image"
                                src="{{bestSeller.main_photo_url}}" alt="product image">
                            <mat-card-content>
                                <div>
                                    <p class="over">{{bestSeller.name}}</p>
                                </div>
                                <div>
                                    <p class="bold"
                                        style="margin-bottom: 0px; font-size: 13px;">Rp
                                        {{priceTransform(bestSeller.min_price)}}</p>
                                </div>
                            </mat-card-content>
                            <mat-card-footer align="end">
                                <div>
                                    <p class="desc"><i class=" marker fas fa-star"></i><i
                                            class=" marker fas fa-star"></i><i
                                            class=" marker fas fa-star"></i><i
                                            class=" marker fas fa-star"></i><i
                                            class=" marker fas fa-star"></i></p>
                                </div>
                                <div>
                                    <p class="desc"><i
                                            class=" marker fas fa-map-marker-alt"></i>
                                        {{bestSeller.city}}</p>
                                </div>
                            </mat-card-footer>
                        </mat-card>
                    </a>
                </div>
            </div>
        </div>
    </div>
<!-- </mat-tab> -->