<div class="contact-box bg-white rounded p-sm-5 mt-3 test">
    <div class="col-lg-12 col-sm-12 col-md-12 d-flex flex-row flex-wrap no-marpad mb-1">
        <h3 class="pb-3">Beli dan Bayar Tagihan Listrik</h3>
    </div>

    <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#token-listrik" role="tab" aria-controls="nav-home" aria-selected="true">Token Listrik</a>
            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#pasca-bayar" role="tab" aria-controls="nav-profile" aria-selected="false">Pasca Bayar</a>
        </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="token-listrik" role="tabpanel" aria-labelledby="nav-home-tab">
            <div class="col-12 d-flex h-100 flex-row flex-wrap p-3">
                <h5>Beli Token Listrik</h5>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="No. Meter/ID Pelanggan" aria-label="phone" aria-describedby="basic-addon1">
                </div>
            </div>
            <div class="col-12 d-flex h-100 flex-row flex-wrap ptb_30">
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Combo SAKTI Unlimited 32 GB | 30 Hari</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 74.000</h5>
                    </div>
                </div>
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Special Ramadhan Harian Unlimited</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 12.000</h5>
                    </div>
                </div>
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Kuota Keluarga 25 GB | 30 Hari</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 150.000</h5>
                    </div>
                </div>
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Kuota Ketengan Utama 500MB | 1 Hari</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 3.500</h5>
                    </div>
                </div>
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Paket Langganan CloudX Conference 30GB | 30 Hari</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 250.000</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade" id="pasca-bayar" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div class="col-12 d-flex h-100 flex-row flex-wrap p-3">
                <h5>Bayar Listrik Pasca Bayar</h5>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="No. Meter/ID Pelanggan" aria-label="phone" aria-describedby="basic-addon1">
                </div>
            </div>
            <div class="col-12 pt-3 ">
                <h4><strong>Informasi Pelanggan</strong></h4>
            </div>
            <div class="col-12 d-flex h-100 flex-row flex-wrap ">
                <div class="d-flex row col-md-7 col-sm-12 data-detail">
                    <div class="col-12 d-flex flex-row profile-list">
                        <div class="col-3 d-flex align-items-center" style="padding-left: 5px;">
                            No. Meter
                        </div>
                        <div class="col-8 ">
                            123456789123
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-row profile-list">
                        <div class="col-3 d-flex align-items-center" style="padding-left: 5px;">
                            ID Pelanggan
                        </div>
                        <div class="col-8 ">
                            12345678912300
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-row profile-list">
                        <div class="col-3 d-flex align-items-center" style="padding-left: 5px;">
                            NAMA
                        </div>
                        <div class="col-8">
                            Jhonathan Doe
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-row profile-list">
                        <div class="col-3 d-flex align-items-center" style="padding-left: 5px;">
                            Bulan
                        </div>
                        <div class="col-8">
                            Januari - Maret
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-row profile-list">
                        <div class="col-3 d-flex align-items-center" style="padding-left: 5px;">
                            Harga Bayar
                        </div>
                        <div class="col-8">
                            Rp. 500.000
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-row justify-content-end pt_50">
        <div class="d-flex flex-column justify-content-end text-right mr-3">
            <h5 class="m-0">Total Bayar</h5>
            <h3 class="price">Rp.100.000</h3>
        </div>
        <div>
            <a href="/checkout-finish" class="btn">Beli</a>
        </div>
    </div>
</div>


<div class="contact-box bg-white rounded test mt-3 mb-3">
    <div class="p-3">
        <h3 class="text-center pb-2">Ketentuan Pembelian Token dan Pembayaran Listrik di On Market</h3>
        <p>On Market menyediakan jasa pembelian token listrik dan pembayaran listrik pasca bayar dengan harga terjangkau. Kamu hanya perlu mengisi nomor telpon mu dan memilih paket yang kamu inginkan.</p>
    </div>
</div>