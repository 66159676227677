<div class="blog">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-whatsapp-corner></app-whatsapp-corner>
        <!-- <app-message-corner></app-message-corner> -->
        <app-main-header></app-main-header>
        <section class="section breadcrumb-area bg-overlay d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Breamcrumb Content -->
                        <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                            <h2 class="text-white">Tagihan dan Pembayaran</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section screenshots-area bg-gray">
            <div class="container">
                <div class="col-12 menu">
                    <div class="category">
                        <div class="single-screenshot" id="navbar" *ngFor="let category of category">
                            <a routerLink="{{category.link}}" routerLinkActive="active">
                                <div style="overflow: hidden;">
                                    <div class="d-inline-block  ">
                                        <img class="category-size" src="{{category.image}}" alt="">
                                    </div>
                                    <p class="mt-1">{{category.title}}</p>
                                </div>
                            </a>
                        </div>
                        <div class="single-screenshot" id="navbar">
                            <a (click)="openDialog()">
                                <div style="overflow: hidden;">
                                    <div class="d-inline-block  ">
                                        <img class="category-size" src="assets/img/categories/all-menu.png" alt="">
                                    </div>
                                    <p class="mt-1">Semua</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="blog" class="section blog-area bg-gray ">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </section>
        <app-footer-one></app-footer-one>
    </div>
</div>