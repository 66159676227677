<section id="screenshots" class="section overflow-hidden screenshots-area bg-gray pt_30">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="full-banner owl-carousel owl-theme">
                    <div class="item">
                        <img class="img-fluid" style=" max-width: 100%;" src="assets/img/banner10.png" alt="">
                    </div>
                    <div class="item">
                        <img class="img-fluid" style=" max-width: 100%;" src="assets/img/banner5.png" alt="">
                    </div>
                    <div class="item">
                        <img class="img-fluid" style=" max-width: 100%;" src="assets/img/banner6.png" alt="">
                    </div>
                    <div class="item">
                        <img class="img-fluid" style=" max-width: 100%;" src="assets/img/banner4.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>