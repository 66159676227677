import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { NumberFormatPipe } from 'src/app/number.pipe';

interface FilterObj {
  minPrice: number
  maxPrice: number
  option: string
}

interface QueryTable {
  sort: null | Object
  filters: FilterObj[]
  category_id: number
  pageIndex: number
}

@Component({
  selector: 'app-category-product',
  templateUrl: './category-product.component.html',
  styleUrls: ['./category-product.component.scss'],
  providers: [NumberFormatPipe]
})
export class CategoryProductComponent implements OnInit {

  products: []
  pageIndex: number = 0
  inputnumber = 0
  totalPageSize: any[] = []
  params: ParamMap
  category_id: number = 0
  category_name: string = ''
  form: FormGroup
  option: string = ''
  // TODO problem with pagination (next page cannot open product details)
  constructor(
    private service: ApiService,
    private route: ActivatedRoute,
    private routes: Router,
    private currencyFormat: NumberFormatPipe,
    private formBuilder: FormBuilder,
  ) {
    this.params = this.route.snapshot.queryParamMap
    // if (this.params.get('category_id') === null) {
    //   this.routes.navigate(['/**'])
    // }
    this.category_id = parseInt(this.params.get('category_id'))
    this.pageIndex = (this.params.get('page') == null) ? 0 : parseInt(this.params.get('page')) - 1
    this.fetchProducts()

    this.form = this.formBuilder.group({
      minPrice: [null],
      maxPrice: [null],
    })

    // this.routes.navigate(['/category'], {
    //   queryParams: {
    //     category_id: this.category_id,
    //     page: this.pageIndex + 1
    //   }, replaceUrl: true
    // })
  }

  minPrice: string = null
  maxPrice: string = null

  get minsPrice() { return this.minPrice; }
  set minsPrice(v) { this.minPrice = v !== null ? 'Rp' + v?.replace(/[^0-9.]/g, '') : null; }

  get maxsPrice() { return this.maxPrice; }
  set maxsPrice(v) { this.maxPrice = v !== null ? 'Rp' + v?.replace(/[^0-9.]/g, '') : null; }

  onMinChange(event: any) {
    if (!/^\d+$/.test(event)) {
      event = event.replace(/\D/g,'');
    }

    this.minPrice = 'Rp ' + this.priceTransform(event);
  }

  onMaxChange(event: any) {
    if (!/^\d+$/.test(event)) {
      event = event.replace(/\D/g,'');
    }

    this.maxPrice = 'Rp ' + this.priceTransform(event);
  }

  fetchProducts() {
    this.queryTable.category_id = this.category_id
    this.service.categoryProduct(this.queryTable).subscribe(data => {
      this.products = data.products
      this.category_name = data.category.name
      this.totalPageSize = Array(Math.round(data.total / 24) + 1)
      // console.log("CategoryProductComponent-Product")
      // console.log(data.products)
      // console.log("Raw")
      // console.log(data)
      // console.log(this.totalPageSize)
    }, e => {
      console.log(e)
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    category_id: this.category_id,
    pageIndex: this.pageIndex,
  }

  onQueryParamsChange(params): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.category_id = this.category_id
    this.queryTable.pageIndex = this.pageIndex
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchProducts()
  }

  search() {
    this.queryTable.filters = [
      {
        minPrice: ((this.form.value.minPrice !== null) && (this.form.value.minPrice !== '') && (this.form.value.minPrice !== undefined) && (this.form.value.minPrice.length > 3)) ? (this.form.value.minPrice).replace(/[^\d]/g, '') : null,
        maxPrice: ((this.form.value.maxPrice !== null) && (this.form.value.maxPrice !== '') && (this.form.value.maxPrice !== undefined) && (this.form.value.maxPrice.length > 3)) ? (this.form.value.maxPrice).replace(/[^\d]/g, '') : null,
        option: this.option
      },
    ]

    if (this.option == 'refresh') {
      this.form.controls['minPrice'].setValue(null)
      this.form.controls['maxPrice'].setValue(null)
      this.option = ''
    }
    // console.log(this.queryTable.filters)
    this.fetchProducts()
  }

  minPriceFilter(event: any) {
    // console.log(event.target.value)

    if (event.target.value?.length > 0) {
      this.queryTable.filters = [
        {
          minPrice: ((this.form.value.minPrice !== null) && (this.form.value.minPrice !== '') && (this.form.value.minPrice !== undefined) && (this.form.value.minPrice.length > 3)) ? (this.form.value.minPrice).replace(/[^\d]/g, '') : null,
          maxPrice: ((this.form.value.maxPrice !== null) && (this.form.value.maxPrice !== '') && (this.form.value.maxPrice !== undefined) && (this.form.value.maxPrice.length > 3)) ? (this.form.value.maxPrice).replace(/[^\d]/g, '') : null,
          option: this.option
        },
      ]
      // console.log(this.queryTable.filters)
    } else {
      this.queryTable.filters = []
    }
  }

  maxPriceFilter(event: any) {
    // console.log(event.target.value)

    if (event.target.value?.length > 0) {
      this.queryTable.filters = [
        {
          minPrice: ((this.form.value.minPrice !== null) && (this.form.value.minPrice !== '') && (this.form.value.minPrice !== undefined) && (this.form.value.minPrice.length > 3)) ? (this.form.value.minPrice).replace(/[^\d]/g, '') : null,
          maxPrice: ((this.form.value.maxPrice !== null) && (this.form.value.maxPrice !== '') && (this.form.value.maxPrice !== undefined) && (this.form.value.maxPrice.length > 3)) ? (this.form.value.maxPrice).replace(/[^\d]/g, '') : null,
          option: this.option
        },
      ]
      // console.log(this.queryTable.filters)
    } else {
      this.queryTable.filters = []
    }
  }

  arrowPagination(arg: string, number: number = 0) {
    if (arg === 'previous') {
      this.pageIndex = this.pageIndex - 1
    } else if (arg === 'next') {
      this.pageIndex = this.pageIndex + 1
    }
    this.fetchProducts()
  }

  numberPagination(index: number = 0) {
    this.pageIndex = index
    this.fetchProducts()
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  ngOnInit(): void {
  }

  plus() {
    this.inputnumber = this.inputnumber + 1;
  }
  minus() {
    if (this.inputnumber != 0) {
      this.inputnumber = this.inputnumber - 1;
    }
  }

}
