import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import { clearLocalStorage } from "src/app/utility.service";
import { LoginComponent } from "../accounts/login/login.component";
import { FormControl } from "@angular/forms";
import { NumberFormatPipe } from "../../number.pipe";

import {
  debounceTime,
  tap,
  switchMap,
  finalize,
  catchError,
  map,
  startWith,
} from "rxjs/operators";
import { of } from "rxjs";
import { DialogAlert } from "../accounts/profile/myprofile/detail-profile/detail-profile.component";

@Component({
  selector: "app-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.css"],
  providers: [NumberFormatPipe],
})
export class MainHeaderComponent implements OnInit {
  searchQuery: FormControl = new FormControl();
  categories: any[];
  carts: any;
  total: any;
  filteredOptions: any;
  isLoading = false;
  errorMsg: string;
  loggedin: boolean = false;
  loggedinUser: string;
  message: string;

  constructor(
    public dialog: MatDialog,
    private routes: Router,
    private service: ApiService,
    private route: ActivatedRoute,
    private currencyFormat: NumberFormatPipe
  ) {
    this.route.queryParamMap.subscribe((params) => {
      this.searchQuery.patchValue(params.get("keyword"));
    });

    this.service.categoryList().subscribe(
      (data) => {
        this.categories = data;
        console.log(data);
        // console.log("Categories-main-header-Component")
        // console.log(data)
      },
      (e) => {
        console.log(e);
      }
    );

    this.service.isUpdateCart.subscribe((r) => {
      // console.log("This is Update Cart", r)
      if (r) {
        this.fetchCarts();
      }
    });

    this.service.deletedFromCart.subscribe((r) => {
      // console.log("This is Delete from Cart", r)
      if (r) {
        this.fetchCarts();
      }
    });
  }

  fetchCarts() {
    if (this.loggedin) {
      this.service.cartList().subscribe(
        (data) => {
          this.carts = data.carts;
          this.total = data.total;
          // console.log("CartList-main-header-Component")
          // console.log(data)
        },
        (e) => {
          console.log(e);
        }
      );
    } else {
      this.total = 0;
    }
  }
  // condition() {
  //   this.carts.product_variant.variant_name != 'default'
  // }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  ngOnInit(): void {
    //Test if user status still loggedin
    if (
      localStorage.getItem("username") != null &&
      localStorage.getItem("jwt") != null
    ) {
      this.service.verify(localStorage.getItem("jwt")).subscribe(
        (r) => {
          this.loggedinUser = localStorage.getItem("username");
          this.loggedin = true;
          this.fetchCarts();
        },
        (e) => {
          clearLocalStorage();
        }
      );
    } else {
      clearLocalStorage();
    }

    this.searchQuery.valueChanges
      .pipe(
        startWith(""),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredOptions = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.service
            .searchBox(value)
            .pipe(
              catchError(() => of({ results: [] })),
              map((res: any) => {
                console.log(res);
                return res;
              })
            )
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        // console.log('data')
        this.filteredOptions = data;
      });

    if (history.state.signIn != null || history.state.signIn != undefined) {
      this.openDialog();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoginComponent, {
      id: "LoginDialog",
    });
  }

  logout() {
    // console.log(localStorage.getItem('jwt'));
    this.service.logOff(localStorage.getItem("jwt")).subscribe(
      (data) => {
        clearLocalStorage();
        window.location.reload();
        // this.routes.navigate(['/'])
      },
      (e) => {
        console.log(e);
        this.dialog.open(DialogAlert, {
          width: "300px",
          data: {
            status: false,
            message: "Gagal logout. Mohon coba beberapa saat lagi.",
          },
        });
      }
    );
  }

  search() {
    this.routes
      .navigate(["/search"], {
        queryParams: {
          keyword: this.searchQuery.value,
          page: 1,
        },
        replaceUrl: true,
      })
      .then(() => {
        window.location.reload();
      });
  }

  categoryProd(event) {
    this.routes
      .navigate(["/category"], {
        queryParams: {
          category_id: event.category_id,
          page: 1,
        },
        replaceUrl: true,
      })
      .then(() => {
        window.location.reload();
      });
  }

  cartCheck() {
    if (this.loggedin) {
      this.routes.navigate(["/cart"]);
    } else {
      this.openDialog();
    }
  }
}
