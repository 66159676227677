import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NumberFormatPipe } from '../../../number.pipe';
import { DatePipe, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  providers: [Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }, NumberFormatPipe]
})
export class ProductComponent implements OnInit {

  product: any
  storeProducts: any
  store_id: any
  pageIndex: number = 0
  totalPageSize: any[] = []
  params: ParamMap

  constructor(
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    public datepipe: DatePipe,
  ) {
    this.params = this.route.snapshot.queryParamMap
    this.pageIndex = 0
    this.store_id = this.params.get('store_id')

    this.fetchstoreProduct(this.store_id, this.pageIndex)
  }

  fetchstoreProduct(store_id: number, pageIndex: number = 0) {
    // console.log(pageIndex)
    this.service.storeProduct(store_id, pageIndex).subscribe(data => {
      this.storeProducts = data.products
      this.totalPageSize = Array(Math.round(data.total / 24) + 1)
      // console.log("PersonalStoreComponent-storeProduct")
      // console.log(data)
    }, e => {
      console.log(e)
    })
  }

  arrowPagination(arg: string, number: number = 0) {
    if (arg === 'previous') {
      this.pageIndex = this.pageIndex - 1
    } else if (arg === 'next') {
      this.pageIndex = this.pageIndex + 1
    }
    this.fetchstoreProduct(this.store_id, this.pageIndex)
  }
  numberPagination(index: number = 0) {
    this.pageIndex = index
    this.fetchstoreProduct(this.store_id, this.pageIndex)
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  ngOnInit(): void {
  }

}
