<div class="blog">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-whatsapp-corner></app-whatsapp-corner>
        <!-- <app-message-corner></app-message-corner> -->
        <app-main-header></app-main-header>
        <section class="section service-area overflow-hidden ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6 order-2 order-lg-1">
                        <!-- Service Text -->
                        <div class="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                            <h2 class="text-capitalize mb-4">Organize all your media content easily</h2>
                            <!-- Service List -->
                            <ul class="service-list style-two">
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Fully layered dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Customizable design dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Drop ipsum dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Marketing chart dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                        <!-- Service Thumb -->
                        <div class="service-thumb mx-auto">
                            <img src="assets/img/service_thumb_1.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-footer-one></app-footer-one>
    </div>
</div>