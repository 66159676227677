<div class="contact-box bg-white rounded p-sm-5 mt-3 test">
    <div class="col-lg-12 col-sm-12 col-md-12 d-flex flex-row flex-wrap no-marpad mb-1">
        <h3 class="pb-3">Bayar BPJS Ketenagakerjaan & Kesehatan</h3>
    </div>

    <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#token-listrik" role="tab" aria-controls="nav-home" aria-selected="true">BPJS Kesehatan</a>
            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#pasca-bayar" role="tab" aria-controls="nav-profile" aria-selected="false">BPJS Ketenagakerjaan</a>
        </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active mt-3" id="token-listrik" role="tabpanel" aria-labelledby="nav-home-tab">
            <div class="col-12 d-flex h-100 flex-row flex-wrap p-3">
                <h5>Input No BPJS Kesehatan</h5>
                <div class="input-group mb-3">
                    <select class="form-control" id="exampleFormControlSelect1">
                        <option>Plih Jumlah Bulan</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="No. Meter/ID Pelanggan" aria-label="phone" aria-describedby="basic-addon1">
                </div>
            </div>
        </div>

        <div class="tab-pane fade mt-3" id="pasca-bayar" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div class="col-12 d-flex h-100 flex-row flex-wrap p-3">
                <h5>Input No BPJS Ketenagakerjaan</h5>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="No. Meter/ID Pelanggan" aria-label="phone" aria-describedby="basic-addon1">
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-row justify-content-end pt_50">
        <div class="d-flex flex-column justify-content-end text-right mr-3">
            <h5 class="m-0">Total Bayar</h5>
            <h3 class="price">Rp.100.000</h3>
        </div>
        <div>
            <a href="/checkout-finish" class="btn">Beli</a>
        </div>
    </div>
</div>


<div class="contact-box bg-white rounded test mt-3 mb-3">
    <div class="p-3">
        <h3 class="text-center pb-2">Ketentuan Pembelian Token dan Pembayaran Listrik di On Market</h3>
        <p>On Market menyediakan jasa pembelian token listrik dan pembayaran listrik pasca bayar dengan harga terjangkau. Kamu hanya perlu mengisi nomor telpon mu dan memilih paket yang kamu inginkan.</p>
    </div>
</div>