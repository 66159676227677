<ng-template [ngIf]="!isLoading">
  <ng-container *ngIf="this.data.voucher.length > 0; else elseNoVoucher">
    <mat-card
      style="width: 100%; border: 1px solid #dfdfdf; margin-bottom: 20px"
      *ngFor="let voucher of this.data.voucher"
    >
      <!-- <div class="details" style="position: relative;">
            <div class="detail-group yes">
                <div class="details-label">Tambah Voucher</div>
                <div class="details-wrapper flex flex-auto flex-no-overflow">
                    <div class="flex flex-auto flex-no-overflow">
                        <div class="d-flex row col-md-7 col-sm-12">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
      <!-- <div class="card" style="cursor: pointer;" (click)="onSelectVoucher(voucher.voucher_id, voucher.name)">
                <div class="card-header" style="font-weight: bold; background-color: white;">{{voucher.name}}</div>
                <div class="card-body">
                        <p class="card-text" style="font-weight: bold; margin-bottom: 0px;">Diskon {{voucher.value}}{{voucher.type == 'percentage' ? '%' : ''}}</p>
                    <ng-template [ngIf]="(voucher.min_price)">
                        <p class="card-text" style="margin-bottom: 0px;">Min. Blj Rp{{voucher.min_price}}
                        </p>
                    </ng-template>
                    <ng-template [ngIf]="(voucher.max_discount)">
                        <p class="card-text" style="margin-bottom: 0px;">Max. Disc
                            Rp{{voucher.max_discount}}</p>
                    </ng-template>
                    <p class="card-text">S/D: {{voucher.expired_date}}</p>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                        <mat-panel-title>
                            Deskripsi
                        </mat-panel-title>
                        </mat-expansion-panel-header>
                            <p class="card-text">{{voucher.description}}</p>
                    </mat-expansion-panel>
    
                    <mat-expansion-panel (opened)="termsOpenState = true" (closed)="termsOpenState = false">
                        <mat-expansion-panel-header>
                        <mat-panel-title>
                            Syarat & Ketentuan
                        </mat-panel-title>
                        </mat-expansion-panel-header>
                            <p class="card-text">{{voucher.terms}}</p>
                    </mat-expansion-panel>
                </div>
            </div> -->
      <div class="p-2">
        <div class="row">
          <div class="col-sm-4">
            <!-- <div class="card overflow-auto" > -->
            <img
              src="{{ voucher.image_url }}"
              class="card-img-top"
              style="
                border-radius: 3px;
                border-color: rgb(204, 204, 204);
                height: 112px;
                width: 800px;
                object-fit: cover;
              "
            />
            <!-- <div class="card-body">
                                <h4 class="card-title" style="font-weight: bold; padding-bottom: 5px;">{{voucher.name}}</h4>
                                <p class="card-title" style="font-weight: bold; margin-bottom: 0px;">Masa Berlaku</p>
                                <p class="card-text" style="padding-bottom: 15px;">{{voucher.start_date}} - {{voucher.expired_date}}</p>
                                <p class="card-title" style="font-weight: bold; margin-bottom: 0px;">Deskripsi</p>
                                <p class="card-text" [innerText]="voucher.description" style="padding-bottom: 15px;"></p>
                                <p class="card-title" style="font-weight: bold; margin-bottom: 0px;">Syarat dan Ketentuan</p>
                                <p class="card-text" [innerText]="voucher.terms" style="padding-bottom: 5px;"></p>
                            </div> -->
            <!-- </div> -->
          </div>
          <div class="col-sm-5">
            <!-- <div class="card"> -->
            <!-- <div class="card-header" style="font-weight: bold; background-color: white;">{{voucher.name}}</div> -->
            <div class="card-body">
              <ng-template [ngIf]="voucher.type == 'percentage'">
                <p
                  class="card-text"
                  style="font-weight: bold; margin-bottom: 0px"
                >
                  Diskon {{ voucher.value }}%
                </p>
              </ng-template>
              <ng-template [ngIf]="voucher.type == 'numeric'">
                <p
                  class="card-text"
                  style="font-weight: bold; margin-bottom: 0px"
                >
                  Diskon Rp {{ voucherTransform(voucher.value) }}
                </p>
              </ng-template>
              <ng-template [ngIf]="voucher.min_price">
                <p class="card-text" style="margin-bottom: 0px">
                  Min. Blj Rp{{ voucherTransform(voucher.min_price) }}
                </p>
              </ng-template>
              <p class="card-text">S/D: {{ voucher.expired_date }}</p>
              <!-- <ng-template [ngIf]="(voucher.max_discount)">
                                    <p class="card-text" style="margin-bottom: 0px;">Max. Disc Rp{{voucherTransform(voucher.max_discount)}}</p>
                                </ng-template> -->
            </div>
            <!-- <ul class="list-group list-group-flush">
                                <li class="list-group-item" style="font-weight: bold;">{{voucher.name}}</li>
                                <li class="list-group-item">
                                    <ng-template [ngIf]="(voucher.type == 'percentage')">
                                        <p class="card-text" style="font-weight: bold; margin-bottom: 0px;">Diskon {{voucher.value}}%</p>
                                    </ng-template>
                                    <ng-template [ngIf]="(voucher.type == 'numeric')">
                                        <p class="card-text" style="font-weight: bold; margin-bottom: 0px;">Diskon Rp {{voucherTransform(voucher.value)}}</p>
                                    </ng-template>
                                    <ng-template [ngIf]="(voucher.min_price)">
                                        <p class="card-text" style="margin-bottom: 0px;">Min. Blj Rp{{voucherTransform(voucher.min_price)}}</p>
                                    </ng-template>
                                    <ng-template [ngIf]="(voucher.max_discount)">
                                        <p class="card-text" style="margin-bottom: 0px;">Max. Disc Rp{{voucherTransform(voucher.max_discount)}}</p>
                                    </ng-template>
                                    <p class="card-text" style="margin-bottom: 0px;">S/D: {{voucher.expired_date}}</p>
                                </li>
                            </ul> -->
            <!-- <div class="card-body">
                                <p class="card-text">{{voucher.expired_date}}</p>
                                <ng-template [ngIf]="(voucher.min_price)">
                                    <p class="card-text">Min. priceTransform({{voucher.min_price}})</p>
                                </ng-template>
                                <ng-template [ngIf]="(voucher.max_discount)">
                                    <p class="card-text">Max. priceTransform({{voucher.max_discount}})</p>
                                </ng-template>
                            </div> -->
            <!-- <a class="btn btn-primary" style="color: #c48811; padding-top: 20px;" (click)="onSelectVoucher(voucher.voucher_id)">Klaim</a> -->
            <!-- </div> -->
          </div>
          <div class="col-sm-3">
            <div class="text-center" style="margin-top: 30px">
              <div
                *ngIf="
                  voucher.allow_claim && voucher.voucher_id !== voucher_id;
                  else elseBlock
                "
              >
                <button
                  class="btn btn-action"
                  style="background: #3571b6 !important"
                  (click)="onSelectVoucher(voucher)"
                >
                  Klaim
                </button>
              </div>
              <ng-template #elseBlock>
                <ng-container *ngIf="!voucher.allow_claim">
                  <p
                    class="btn btn-action isDisabled"
                    style="background: #b6244f !important; cursor: not-allowed"
                  >
                    Tidak Bisa Diklaim
                  </p>
                </ng-container>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-container>
  <ng-template #elseNoVoucher>
    <mat-card style="width: 100%; height: 100%; border: 1px solid #dfdfdf">
      <div class="row justify-content-center" style="justify-content: center">
        <div>
          <img
            class="shopping-cart-img"
            style="align-self: center"
            src="/assets/img/no-voucher.png"
          />
        </div>
        <!-- <div class="col-1">
                    <a href="/cart" style="color: #13AAD4">View Cart</a>
                </div> -->
        <!-- <div class="col-6">
                    <button mat-raised-button style="color: #fff; background-color: #13AAD4;" class="m-1">
                        Checkout
                    </button>
                </div> -->
      </div>
      <div style="text-align: center; margin-bottom: 20px">
        <div style="color: #1d1d1d">
          <br />
          Belum ada voucher
        </div>
      </div>
    </mat-card>
  </ng-template>
</ng-template>
<ng-template [ngIf]="isLoading">
  <div class="col ptb_50" style="text-align: center">
    <mat-spinner style="margin: 80px auto"></mat-spinner><br />
    <h4>Loading</h4>
  </div>
</ng-template>
