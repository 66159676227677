<section id="screenshots" class="section screenshots-area bg-gray pb-4" style="background-color: white; padding-top: 15px;">
    <div class="container">
        <!-- <mat-card class="p-4"> -->
        <div class="ro">
            <div class="col-12 text-center">
                <h3 class="m-3">PROMO OF THE MONTH</h3>
            </div>
            <!-- <div class="col-12 app-screenshots">
                    <div class="single-screenshot">
                        <img src="assets/img/banner5.png" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="assets/img/2.jpg" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="assets/img/3.jpg" alt="">
                    </div>
                </div> -->
            <div class="col-12 d-flex flex-row promo-slide pt-3">
                <a class="col-4 promo-banner" href="campaign">
                    <img class="img-fluid" src="assets/img/februari.png" alt="">
                </a>
                <a class="col-4 promo-banner" href="campaign">
                    <img class="img-fluid" src="assets/img/februari.png" alt="">
                </a>
                <a class="col-4 promo-banner" href="campaign">
                    <img class="img-fluid" src="assets/img/februari.png" alt="">
                </a>
                <a class="col-4 promo-banner" href="campaign">
                    <img class="img-fluid" src="assets/img/februari.png" alt="">
                </a>
            </div>
            <!-- <div class="col-12 d-flex flex-row promo-slide pt-3">
                <a class="col-4 promo-banner" href="campaign">
                    <img class="img-fluid" src="assets/img/OnApps2.jpg" alt="">
                </a>
                <a class="col-4 promo-banner" href="campaign">
                    <img class="img-fluid" src="assets/img/OnApps3.jpg" alt="">
                </a>
                <a class="col-4 promo-banner" href="campaign">
                    <img class="img-fluid" src="assets/img/OnApps4.jpg" alt="">
                </a>
                <a class="col-4 promo-banner" href="campaign">
                    <img class="img-fluid" src="assets/img/OnApps5.jpg" alt="">
                </a>
            </div> -->
        </div>
        <!-- </mat-card> -->
    </div>
</section>