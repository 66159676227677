import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tiket-kereta',
  templateUrl: './tiket-kereta.component.html',
  styleUrls: ['./tiket-kereta.component.css']
})
export class TiketKeretaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
