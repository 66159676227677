<section class="section counter-area ptb_50">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="mb-5">HISTORY</h2>
                    <p class="about-desc mt-5 text-gray ">We are an Indonesian company dedicated to helmet and corporate
                        essentials manufacture. From the beginning in 2007, we have been concentrating on unique
                        know-how acquired through our intense experience in the production of new technologies which
                        characterize the sector of personalizing and safety products. This innovation is the genesis of
                        today's work. </p>
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-center"> -->
        <!-- <div class="col-5 col-sm-3 single-counter text-center">
                <div class="counter-inner p-3 p-md-0"> -->
        <!-- Counter Item -->
        <!-- <div class="counter-item d-inline-block mb-3">
                        <span class="counter fw-7">10</span><span class="fw-7">M</span>
                    </div>
                    <h5>Pengguna</h5>
                </div>
            </div>
            <div class="col-5 col-sm-3 single-counter text-center">
                <div class="counter-inner p-3 p-md-0"> -->
        <!-- Counter Item -->
        <!-- <div class="counter-item d-inline-block mb-3">
                        <span class="counter fw-7">23</span><span class="fw-7">K</span>
                    </div>
                    <h5>Unduhan</h5>
                </div>
            </div>
            <div class="col-5 col-sm-3 single-counter text-center">
                <div class="counter-inner p-3 p-md-0"> -->
        <!-- Counter Item -->
        <!-- <div class="counter-item d-inline-block mb-3">
                        <span class="counter fw-7">9</span><span class="fw-7">M</span>
                    </div>
                    <h5>Pelanggan</h5>
                </div>
            </div>
            <div class="col-5 col-sm-3 single-counter text-center">
                <div class="counter-inner p-3 p-md-0"> -->
        <!-- Counter Item -->
        <!-- <div class="counter-item d-inline-block mb-3">
                        <span class="counter fw-7">12</span><span class="fw-7">K</span>
                    </div>
                    <h5>Toko</h5>
                </div>
            </div> -->
        <!-- </div> -->
    </div>
</section>