<ng-template [ngIf]="!isLoading">
  <div class="blog">
    <div class="main">
      <app-whatsapp-corner></app-whatsapp-corner>
      <!-- <app-message-corner></app-message-corner> -->
      <app-main-header></app-main-header>
      <section id="blog" class="section blog-area ptb_30 bg-gray">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <!-- Single Product Details -->
              <article class="single-blog-details">
                <!-- Product Summary -->
                <div id="container">
                  <div class="card">
                    <div class="card-body">
                      <div
                        class="row justify-content-start"
                        style="padding: 10px"
                      >
                        <div class="col-12 col-lg-4 col-md-4">
                          <div class="d-flex flex-column">
                            <div class="product-img float-start pb-4">
                              <div class="item" style="max-width: 270px">
                                <figure
                                  class="zoom"
                                  (mousemove)="hoverZoom($event)"
                                  style="background-image: url({{selectedPhoto}});"
                                >
                                  <img
                                    class="img-responsive"
                                    style="cursor: zoom-in"
                                    id="featured"
                                    src="{{ selectedPhoto }}"
                                  />
                                </figure>
                              </div>
                              <section >
                                <div id="carousel" class="screenshots mt-3">
                                  <!-- <button class="slick-prev slick-arrow"></button> -->
                                  <div
                                    *ngFor="
                                      let image of productDetails.photo_url
                                    "
                                  >
                                    <img
                                      (mouseenter)="selectThumbnail(image)"
                                      class="thumbnail"
                                      [src]="image"
                                    />
                                    <!-- [ngClass]="{'active': i === selectedPhoto}" -->
                                  </div>
                                  <!-- <button class="slick-next slick-arrow"></button> -->
                                </div>
                              </section>
                            </div>
                            <div
                              class="d-flex flex-row col-12 justify-content-left"
                            >
                              <a
                                class="mr-4 clickable highlight"
                                (click)="shareProduct()"
                              >
                                <span class="material-icons pr-2">share</span
                                >Share
                              </a>
                              <!-- <a class="mr-2" href="#">
                                                                <span class="material-icons-round pr-2"
                                                                    style="color: #3571b6;">favorite </span>Favorite
                                                            </a> -->
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-lg-7 col-md-7 px-4 pt-2 detail">
                          <div class="d-flex flex-column">
                            <div>
                              <h3>{{ productDetails.name }}</h3>
                            </div>
                            <!--! <div class="d-flex flex-row rate">
                                                            <div class="pr-2 first">
                                                                <a href="#" class="link"><span class="material-icons-round"
                                                                        style="color: #ffc53e;">star</span>
                                                                    {{productDetails.avg_rating}}
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    class="material-icons divider">fiber_manual_record</span>
                                                            </div>
                                                            <div class="pl-2 pr-2 first">
                                                                <a href="#" class="link">{{reviews.total}} </a>Ulasan
                                                            </div>
                                                            <div>
                                                                <span
                                                                    class="material-icons divider">fiber_manual_record</span>
                                                            </div>
                                                            <div class="pl-2 first">
                                                                <a href="#" class="link">{{productDetails.total_sold}}
                                                                </a>Terjual
                                                            </div>
                                                        </div> -->

                            <hr />

                            <div class="pt-3 pb-4">
                              <h2 style="color: #3571b6">
                                Rp {{ priceTransform(productPrice) }}
                              </h2>
                            </div>

                            <!-- Voucher and Promo -->
                            <div class="flex flex-column">
                              <ng-template [ngIf]="voucherDetails.total > 0">
                                <div
                                  class="details popover__wrapper"
                                  style="position: relative"
                                >
                                  <div class="detail-group yes">
                                    <div class="details-label">
                                      Voucher Toko
                                    </div>
                                    <div
                                      class="details-wrapper d-flex flex-column flex-no-wrap"
                                    >
                                      <div class="exp flex-wrap">
                                        <div
                                          class="promo-ticket flex clickable"
                                          *ngFor="
                                            let voucher of voucherDetails.vouchers
                                          "
                                          (click)="voucherDetail(voucher)"
                                        >
                                          <div class="">
                                            <ng-template
                                              [ngIf]="
                                                voucher.type === 'numeric'
                                              "
                                            >
                                              <span
                                                >Rp
                                                {{
                                                  voucherTransform(
                                                    voucher.value
                                                  )
                                                }}
                                                OFF</span
                                              >
                                            </ng-template>
                                            <ng-template
                                              [ngIf]="
                                                voucher.type === 'percentage'
                                              "
                                            >
                                              <span
                                                >{{ voucher.value }}% OFF</span
                                              >
                                            </ng-template>
                                          </div>
                                        </div>
                                        <!-- <div class="promo-ticket flex pb-sm-2">
                                                                                <div class=""><span>Rp5RB OFF</span></div> -->
                                      </div>
                                      <!-- <div class="popover__content">
                                                                                <p class="popover__message"><b>Voucher Toko</b></p>
                                                                                <img alt="Joseph Francis Joey Tribbiani, Jr." src="https://media.giphy.com/media/11SIBu3s72Co8w/giphy.gif">
                                                                            </div> -->
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                              <!-- Expeditions Information -->
                              <div class="details" style="position: relative">
                                <div class="detail-group yes">
                                  <div class="details-label">Pengiriman</div>
                                  <div
                                    class="details-wrapper flex flex-auto flex-no-overflow"
                                  >
                                    <div
                                      class="flex flex-auto flex-no-overflow"
                                    >
                                      <div
                                        class="city clickable"
                                        [matMenuTriggerFor]="menu"
                                        (click)="pickDestination()"
                                      >
                                        <span
                                          >{{ destinationCityName }}
                                          <span class="material-icons-round"
                                            >arrow_drop_down</span
                                          >
                                        </span>
                                        <mat-menu #menu="matMenu">
                                          <span
                                            mat-menu-item
                                            [disableRipple]="true"
                                            (click)="$event.stopPropagation()"
                                            style="height: 60px"
                                          >
                                            <!-- popover content begin -->
                                            <div
                                              class="col-sm-9"
                                              *ngIf="selectDest"
                                            >
                                              <input
                                                class="form-control"
                                                style="height: 85px"
                                                matInput
                                                [formControl]="
                                                  form.controls['dest']
                                                "
                                                placeholder="Ketik Kota atau Kecamatan"
                                                [matAutocomplete]="autoDest"
                                                style="
                                                  padding-left: 21px;
                                                  width: 280px;
                                                "
                                              />
                                              <mat-autocomplete
                                                autoActiveFirstOption
                                                #autoDest="matAutocomplete"
                                                [displayWith]="displayFnDest"
                                              >
                                                <mat-option
                                                  *ngFor="
                                                    let option of destinationSearchData
                                                      | async
                                                  "
                                                  [value]="option"
                                                >
                                                  <span
                                                    matTooltip="{{
                                                      option.urban_name
                                                    }}, {{
                                                      option.sub_district_name
                                                    }}, {{
                                                      option.city_name
                                                    }}, Prov.{{
                                                      option.province_name
                                                    }}, {{
                                                      option.postal_code
                                                    }}"
                                                    (click)="
                                                      onDestChange(option)
                                                    "
                                                    >{{ option.urban_name }},
                                                    {{
                                                      option.sub_district_name
                                                    }}, {{ option.city_name }},
                                                    Prov.{{
                                                      option.province_name
                                                    }},
                                                    {{
                                                      option.postal_code
                                                    }}</span
                                                  >
                                                </mat-option>
                                              </mat-autocomplete>
                                            </div>
                                            <!-- popover content end  -->
                                          </span>
                                        </mat-menu>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- Ongkir Information -->
                              <div class="details" style="position: relative">
                                <div class="detail-group yes">
                                  <div class="details-label">Ongkos Kirim</div>
                                  <div
                                    class="details-wrapper flex flex-auto flex-no-overflow popover__wrapper"
                                  >
                                    <div
                                      class="flex flex-auto flex-no-overflow"
                                    >
                                      <div>
                                        <div
                                          class="info clickable"
                                          (click)="(pickService)"
                                        >
                                          <div
                                            *ngIf="
                                              minServiceFee != maxServiceFee;
                                              else elseBlock
                                            "
                                          >
                                            <span>
                                              Estimasi Ongkir Rp.
                                              {{
                                                priceTransform(minServiceFee)
                                              }}
                                              - Rp.
                                              {{
                                                priceTransform(maxServiceFee)
                                              }}
                                              <span class="material-icons-round"
                                                >arrow_drop_down</span
                                              >
                                            </span>
                                          </div>
                                          <ng-template #elseBlock>
                                            <span>
                                              Estimasi Ongkir Rp.
                                              {{
                                                priceTransform(minServiceFee)
                                              }}
                                              <span class="material-icons-round"
                                                >arrow_drop_down</span
                                              >
                                            </span>
                                          </ng-template>
                                        </div>
                                        <div class="popover__content">
                                          <!-- <p class="popover__message">Jasa Pengiriman Lainnya</p>
                                                                                    <mat-divider></mat-divider> -->
                                          <div *ngFor="let service of services">
                                            <div class="row">
                                              <div class="col">
                                                <p
                                                  style="
                                                    font-weight: bold;
                                                    margin-bottom: 0px;
                                                  "
                                                >
                                                  {{ service.name }}
                                                </p>
                                                <p style="margin-bottom: 15px">
                                                  Estimasi sampai setelah
                                                  {{ service.eta }}
                                                </p>
                                              </div>
                                              <p>
                                                Rp.
                                                {{
                                                  priceTransform(service.total)
                                                }}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Variant selection -->
                            <div *ngIf="productDetails.total_variant > 1">
                              <div class="details" style="position: relative">
                                <div class="detail-group yes">
                                  <div class="details-label">Variasi</div>
                                  <div
                                    class="flex flex-auto flex-no-overflow"
                                    *ngFor="
                                      let variant of productDetails.product_variants
                                    "
                                  >
                                    <div
                                      class="flex flex-auto flex-no-overflow"
                                    >
                                      <div>
                                        <div
                                          [ngClass]="{
                                            isDisabledInputNumber:
                                              0 >= productStock
                                          }"
                                          class="radio-toolbar flex flex-row"
                                        >
                                          <input
                                            type="radio"
                                            [(ngModel)]="radioSelected"
                                            id="{{ variant.variant_id }}"
                                            name="variantProduct"
                                            value="{{ variant.variant_id }}"
                                            (change)="variantSwitch($event)"
                                          />
                                          <label
                                            for="{{ variant.variant_id }}"
                                            >{{ variant.variant_name }}</label
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- <div class="details " style="position: relative;">
                                                            <div class="detail-group yes">
                                                                <div class="details-label">Ukuran</div>
                                                                <div class="flex flex-auto flex-no-overflow">
                                                                    <div class="flex flex-auto flex-no-overflow">
                                                                        <div>
                                                                            <div class="radio-toolbar flex flex-row">
                                                                                <input type="radio" id="square"
                                                                                    name="variasi2" value="square">
                                                                                <label for="square">Square</label>
    
                                                                                <input type="radio" id="pashmina"
                                                                                    name="variasi2" value="pashmina">
                                                                                <label for="pashmina">Pashmina</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->

                            <!-- Quantities Add -->
                            <div
                              class="details pt-2"
                              style="position: relative"
                            >
                              <div class="detail-group yes">
                                <div class="details-label">Kuantitas</div>
                                <div class="exp flex-wrap flex-row">
                                  <div class="exp">
                                    <div
                                      [ngClass]="{
                                        isDisabledInputNumber: 0 >= productStock
                                      }"
                                      class="btn-varian"
                                      type="button"
                                      (click)="minus()"
                                    >
                                      <i class="fa fa-minus"></i>
                                    </div>
                                    <ng-template [ngIf]="productStock > 0">
                                      <div class="form-check-inline">
                                        <input
                                          type="number"
                                          class="form-quantities"
                                          (keyup)="onKeyUp($event)"
                                          [(ngModel)]="inputnumber"
                                        />
                                      </div>
                                    </ng-template>
                                    <ng-template [ngIf]="0 >= productStock">
                                      <div
                                        [ngClass]="{
                                          isDisabledInputNumber:
                                            0 >= productStock
                                        }"
                                        class="form-check-inline"
                                      >
                                        <input
                                          type="number"
                                          class="form-quantities"
                                          (keyup)="onKeyUp($event)"
                                          [(ngModel)]="inputnumber"
                                          readonly
                                        />
                                      </div>
                                    </ng-template>
                                    <div
                                      [ngClass]="{
                                        isDisabledInputNumber: 0 >= productStock
                                      }"
                                      class="btn-varian"
                                      type="button"
                                      (click)="plus()"
                                    >
                                      <i class="fa fa-plus"></i>
                                    </div>
                                  </div>
                                  <ng-template [ngIf]="productStock > 0">
                                    <small
                                      >*Produk tersisa
                                      {{ productStock }}
                                      buah</small
                                    >
                                  </ng-template>
                                  <ng-template [ngIf]="productStock === 0">
                                    <small style="color: #b6244f"
                                      >*Produk habis</small
                                    >
                                  </ng-template>
                                </div>
                              </div>
                            </div>

                            <!-- Button -->
                            <div *ngIf="loggedin === 'true'">
                              <div class="d-flex flex-row flex-wrap my-3 pt-2">
                                <div class="pb-5 pr-1 col-xs-6">
                                  <a
                                    [ngClass]="{
                                      isDisabled: 0 >= productStock
                                    }"
                                    class="product-btn"
                                    style="
                                      background: #b6244f;
                                      text-align: center;
                                      cursor: pointer;
                                    "
                                    (click)="addToCart()"
                                  >
                                    <span class="material-icons pr-2"
                                      >add_shopping_cart</span
                                    >Masukkan ke Keranjang
                                  </a>
                                </div>
                                <div class="col-xs-6">
                                  <a
                                    [ngClass]="{
                                      isDisabled: 0 >= productStock
                                    }"
                                    (click)="buyNow()"
                                    class="product-btn"
                                    style="background: #3571b6; cursor: pointer"
                                  >
                                    <span class="material-icons pr-2"
                                      >local_shipping</span
                                    >Beli Sekarang
                                  </a>
                                </div>
                              </div>
                            </div>

                            <!-- Button -->
                            <div *ngIf="loggedin === 'false'">
                              <div class="d-flex flex-row flex-wrap my-3 pt-2">
                                <div class="pb-5 pr-1 col-xs-6">
                                  <a
                                    [ngClass]="{
                                      isDisabled: 0 >= productStock
                                    }"
                                    class="product-btn"
                                    style="
                                      background: #b6244f;
                                      text-align: center;
                                      cursor: pointer;
                                    "
                                    (click)="openDialog()"
                                  >
                                    <span class="material-icons pr-2"
                                      >add_shopping_cart</span
                                    >Masukkan ke Keranjang
                                  </a>
                                </div>
                                <div class="col-xs-6">
                                  <a
                                    [ngClass]="{
                                      isDisabled: 0 >= productStock
                                    }"
                                    (click)="openDialog()"
                                    class="product-btn"
                                    style="background: #3571b6; cursor: pointer"
                                  >
                                    <span class="material-icons pr-2"
                                      >local_shipping</span
                                    >Beli Sekarang
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="card mt-3">
                                        <div class="card-body py-4">
                                            <div class="col-12 d-flex flex-row flex-wrap xs">
                                                <div class="d-flex flex-row flex-wrap">
                                                    <div class="d-flex flex-row flex-wrap col-lg-6 col-md-6 xs">
                                                        <div style="padding-right: 25px; padding-bottom: 10px;">
                                                            <a href="/store/store-profile?store_id={{productDetails.store.store_id}}">
                                                                <img class="align-self-start image-shop"
                                                                src={{productDetails.store.image_url}} alt="">
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex flex-column">
                                                                <a href="/store/store-profile?store_id={{productDetails.store.store_id}}">
                                                                    <h3>{{productDetails.store.name}}</h3>
                                                                </a>
                                                                <p class="gray" style="font-size: 12px; margin: 0px;">Online
                                                                    9 menit lalu</p>
                                                                <p class="gray" style="font-size: 12px;"><span
                                                                        class="material-icons gray">location_on</span>
                                                                    {{productDetails.store.city}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex flex-row flex-wrap col-lg-5 col-md-6 xs">
                                                        <div class="d-flex flex-row col-12">
                                                            <div class="pr-2 first text-center">
                                                                <a href="#">Rating</a>
                                                                <p class="bold" style="color: #3571b6;">
                                                                    {{productDetails.store.rating}}</p>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    class="material-icons divider">fiber_manual_record</span>
                                                            </div>
                                                            <div class="pl-2 pr-2 first text-center">
                                                                <a href="#">Reputasi</a>
                                                                <p class="bold" style="color: #3571b6;">
                                                                    {{productDetails.store.reputation}}</p>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    class="material-icons divider">fiber_manual_record</span>
                                                            </div>
                                                            <div class="pl-2 pr-2 text-center">
                                                                Produk
                                                                <a href="/store/store-profile?store_id={{productDetails.store.store_id}}#1"><p class="bold" style="color: #3571b6;">
                                                                    {{productDetails.store.total_product}}</p></a>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex flex-row col-12"
                                                            style="padding-bottom:20px; padding-left: 0px;">
                                                            <button class="chat-toko mr-2" style="background: #B6244F">
                                                                <span class="material-icons pr-2">forum</span>Chat Toko
                                                            </button>
                                                            <a class="chat-toko"
                                                                href="/store?store_id={{productDetails.store.store_id}}">
                                                                <span class="material-icons pr-2">store</span>Kunjungi Toko
                                                            </a>
                                                        </div>
    
                                                    </div>
    
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                </div>

                <div class="card mt-3">
                  <div class="card-body mx-4">
                    <div class="blog-content sApp-blog ptb_30">
                      <!-- Meta Info -->
                      <div
                        class="meta-info d-flex flex-wrap align-items-center py-1"
                      >
                        <h3>Detail Produk</h3>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="mx-4">
                    <div class="d-flex justify-content-start">
                      <div
                        class="col-lg-2 col-md-4 pr-2 d-flex justify-content-start"
                      >
                        <p class="pl-2">Kategori Barang</p>
                      </div>
                      <div class="col-sm-2">
                        <p>{{ productDetails.category_name }}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-start">
                      <div
                        class="col-lg-2 col-md-4 pr-2 d-flex justify-content-start"
                      >
                        <p class="pl-2">Merek</p>
                      </div>
                      <div class="col-sm-2">
                        <p>{{ productDetails.brand_name }}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-start">
                      <div
                        class="col-lg-2 col-md-4 pr-2 d-flex justify-content-start"
                      >
                        <p class="pl-2">Berat</p>
                      </div>
                      <div class="col-sm-2">
                        <p>{{ productDetails.weight }} kg</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-start">
                      <div
                        class="col-lg-2 col-md-4 pr-2 d-flex justify-content-start"
                      >
                        <p class="pl-2">Stok</p>
                      </div>
                      <div class="col-sm-2">
                        <p>
                          {{ productDetails.product_variants[0].variant_stock }}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-start">
                      <div
                        class="col-lg-2 col-md-4 pr-2 d-flex justify-content-start"
                      >
                        <p class="pl-2">Dikirim Dari</p>
                      </div>
                      <div class="col-sm-2">
                        <p>{{ productDetails.store.city }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="p-4 mx-4">
                    <h4 style="font-weight: 500">Deskripsi Produk</h4>
                    <div [innerText]="productDetails.description">
                      <!-- <p>{{productDetails.description}}</p> -->
                    </div>
                  </div>
                </div>

                <!-- !<div class="card mt-3" style="margin-bottom: 50px;">
                                    <div class="card-body">
                                        <div class="blog-content sApp-blog ptb_30"> -->
                <!-- Product Content -->
                <!--! <mat-tab-group dynamicHeight>
                                                <mat-tab label="Rating & Ulasan">
                                                    <div class="col-12 d-flex flex-row py-3">
                                                        <div class="col-lg-2 col-md-3 col-sm-2">
                                                            <div class="pr-3 text-center">
                                                                <h2>
                                                                    <span class="material-icons-round"
                                                                        style="color: #ffc53e; font-size: 50px; padding :0px; ">star</span>{{productDetails.avg_rating | number:'1.1-1'}}<span style="font-size: 14px">/5</span>
                                                                    <p class="pl-2" style="color: #B6244F;">({{reviews.total}} Ulasan)</p>
                                                                </h2>
                                                            </div>
                                                        </div> -->
                <!-- <div class="separator"></div> -->
                <!--! <div class="col-9 pt-2">
                                                            <div class="flex flex-auto flex-no-overflow">
                                                                <div>
                                                                    <div class="radio-toolbar flex flex-row" style="font-size: 25px">
                                                                        <input type="radio" id="all" name="review"
                                                                            value="all" checked>
                                                                        <label for="all">Semua</label>
    
                                                                        <input type="radio" id="with_media" name="review"
                                                                            value="with_media">
                                                                        <label for="with_media">Dengan Media</label>
    
                                                                        <input type="radio" id="rate-5" name="review"
                                                                            value="rate-5">
                                                                        <label for="rate-5">5<span
                                                                                class="material-icons-round rate-star">star</span></label>
    
                                                                        <input type="radio" id="rate-4" name="review"
                                                                            value="rate-4">
                                                                        <label for="rate-4">4<span
                                                                                class="material-icons-round rate-star">star</span></label>
    
                                                                        <input type="radio" id="rate-3" name="review"
                                                                            value="rate-3">
                                                                        <label for="rate-3">3<span
                                                                                class="material-icons-round rate-star">star</span></label>
    
                                                                        <input type="radio" id="rate-2" name="review"
                                                                            value="rate-2">
                                                                        <label for="rate-2">2<span
                                                                                class="material-icons-round rate-star">star</span></label>
    
                                                                        <input type="radio" id="rate-1" name="review"
                                                                            value="rate-1">
                                                                        <label for="rate-1">1<span
                                                                                class="material-icons-round rate-star">star</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <mat-divider></mat-divider>
                                                    <div *ngIf="(reviews.message) != null">
                                                        <div class="pt-2 mx-5 my-4">
                                                            <div class="row justify-content-center">
                                                                <p class="ml-3">{{reviews.message}}</p>
                                                            </div>
                                                        </div>
                                                    </div> -->
                <!-- TODO output user's image -->
                <!-- !<div class="margin" *ngFor="let review of reviews.reviews">
                                                        <div class="row m-2">
                                                            <div class="col-12 d-flex flex-row flex-wrap p-0">
                                                                <div class="col-lg-3 col-sm-6" style="padding: 0;;">
                                                                    <div class="d-flex flex-column">
                                                                        <div class="d-flex flex-row">
                                                                            <div>
                                                                                <img class="image-costumer mr-3"
                                                                                    src="https://usapi.onindonesia.id/get-profile-img/{{review.username}}" alt="">
                                                                            </div>
                                                                            <div class="row">
                                                                                <h4 class="col-12" style="margin: 0px;">
                                                                                    {{review.username}}
                                                                                </h4>
                                                                                <p class="col-12">
                                                                                    <span class="material-icons"
                                                                                        style="color: #ffc53e;">star</span>
                                                                                    <span class="material-icons"
                                                                                        style="color: #ffc53e;">star</span>
                                                                                    <span class="material-icons"
                                                                                        style="color: #ffc53e;">star</span>
                                                                                    <span class="material-icons"
                                                                                        style="color: #ffc53e;">star</span>
                                                                                    <span class="material-icons"
                                                                                        style="color: #ffc53e;">star</span>
                                                                                </p>
    
                                                                            </div>
                                                                        </div>
                                                                        <p class="comment"><span
                                                                                class="material-icons mr-1">drive_file_rename_outline</span>{{review.created_at}}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-9 col-sm-6">
                                                                    <div class="d-flex flex-row justify-content-end">
                                                                        <p class="comment mr-2">Variasi :
                                                                            {{review.variant_name}}</p>
                                                                    </div>
                                                                    <div class="d-flex flex-row justify-content-end">
                                                                        <div>
                                                                            <img class="image-costumer mr-3"
                                                                                src="assets/img/ss-5.jpg" alt="">
                                                                        </div>
                                                                        <div>
                                                                            <img class="image-costumer mr-3"
                                                                                src="assets/img/ss-5.jpg" alt="">
                                                                        </div>
    
                                                                        <div>
                                                                            <img class="image-costumer mr-3"
                                                                                src="assets/img/ss-5.jpg" alt="">
                                                                        </div>
    
                                                                        <div>
                                                                            <img class="image-costumer mr-3"
                                                                                src="assets/img/ss-5.jpg" alt="">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-3 pl-0">
                                                                <p>{{review.description}}</p>
                                                            </div>
                                                        </div>
                                                        <mat-divider></mat-divider>
                                                    </div> -->

                <!-- <mat-divider></mat-divider> -->

                <!-- !<div class="row">
                                                        <div class="col-12"> -->
                <!-- TODO fix pagination -->
                <!-- Pagination -->
                <!--! <ul class="pagination justify-content-center">
                                                                <li class="px-1"
                                                                    [ngClass]="{'disabled': reviewPageIndex === 0}">
                                                                    <a href="javascript:void(0)" aria-label="Previous"
                                                                        (click)="arrowPagination('previous')">
                                                                        <i class="fas fa-arrow-left"></i>
                                                                    </a>
                                                                </li>
                                                                <li class="px-1"
                                                                    *ngFor="let page of reviewTotalPage;index as i"
                                                                    [ngClass]="{'active': reviewPageIndex === i}">
                                                                    <a href="javascript:void(0)"
                                                                        (click)="reviewPageIndex = i;numberPagination(i)">{{i+1}}</a>
                                                                </li>
                                                                <li
                                                                    [ngClass]="{'disabled': reviewPageIndex  === reviewTotalPage.length - 1}">
                                                                    <a href="javascript:void(0)" aria-label="Next"
                                                                        (click)="arrowPagination('next')">
                                                                        <i class="fas fa-arrow-right"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </mat-tab>
                                                <mat-tab label="Diskusi Produk">
                                                    <div *ngIf="(discussions.message) != null">
                                                        <div class="pt-2 mx-5 my-4">
                                                            <div class="row justify-content-center">
                                                                <p class="ml-3">{{discussions.message}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="pt-2 mx-5 my-4"
                                                        *ngFor="let discussion of discussions.discussions">
                                                        <div class="row"> -->
                <!-- <div class="d-flex flex-row">
                                                                <div>
                                                                    <img class="image-costumer mr-3"
                                                                        src="assets/img/ss-5.jpg" alt="">
                                                                </div>
                                                                <div class="row">
                                                                    <h4 class="col-12" style="margin: 0px;">
                                                                        {{review.username}}
                                                                    </h4>
                                                                    <p class="col-12">
                                                                        <span class="material-icons"
                                                                            style="color: #ffc53e;">star</span>
                                                                        <span class="material-icons"
                                                                            style="color: #ffc53e;">star</span>
                                                                        <span class="material-icons"
                                                                            style="color: #ffc53e;">star</span>
                                                                        <span class="material-icons"
                                                                            style="color: #ffc53e;">star</span>
                                                                        <span class="material-icons"
                                                                            style="color: #ffc53e;">star</span>
                                                                    </p>
    
                                                                </div>
                                                            </div> -->
                <!--! <div class="col-12 d-flex flex-row flex-wrap">
                                                                <div class="col-lg-3 col-sm-12" style="padding: 0;">
                                                                    <h4 class="col-12" style="margin: 0px;">
                                                                        {{discussion.username}}</h4>
                                                                    <p class="comment ml-3"><span
                                                                            class="material-icons mr-1">drive_file_rename_outline</span>{{dateTransform(discussion.created_at)}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-3">
                                                                <p class="ml-3">{{discussion.comment}}</p>
                                                            </div>
                                                            <div class=" p-3 mx-5 my-4 w-100"
                                                                style="background-color: rgb(219, 215, 215);">
                                                                <div class="row"
                                                                    *ngFor="let child of discussion.child_discussion">
                                                                    <h4 class="col-12" style="margin: 0px;">
                                                                        {{child.username}} Menjawab :
                                                                    </h4>
                                                                    <p class="comment ml-3"><span 
                                                                        class="material-icons mr-1">drive_file_rename_outline</span>{{dateTransform(child.created_at)}}</p>
                                                                    <div class="col-12 mb-3">
                                                                        <p >{{child.comment}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <mat-divider></mat-divider>
                                                </mat-tab>
                                            </mat-tab-group>
                                        </div>
                                    </div>
                                </div> -->
              </article>
            </div>
          </div>
        </div>
      </section>
      <app-footer-one></app-footer-one>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="isLoading">
  <div class="col ptb_50" style="text-align: center">
    <mat-spinner style="margin: 80px auto"></mat-spinner><br />
    <h4>Loading</h4>
  </div>
</ng-template>
