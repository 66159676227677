import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { ApiService } from "src/app/api.service";
import { NumberFormatPipe } from "src/app/number.pipe";
import { faTruckFast } from "@fortawesome/free-solid-svg-icons";

interface ServiceData {
  service_id: number;
  service_name: string;
  est_time: string;
  price: number;
}

@Component({
  selector: "app-select-service",
  templateUrl: "./select-service.component.html",
  styleUrls: ["./select-service.component.css"],
})
export class SelectServiceComponent implements OnInit {
  @Output() selectServiceEvent = new EventEmitter<string>();
  isLoading: boolean = false;
  totalData: number = 0;
  pageEvent: PageEvent;
  faTruckFast = faTruckFast;
  services: any[];
  service_id: number;

  constructor(
    public dialogRef: MatDialogRef<SelectServiceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private currencyFormat: NumberFormatPipe,
    private service: ApiService
  ) {}

  // @Input() service_id: string

  ngOnInit(): void {
    // console.log('this.data')
    this.services = this.data.services;
    // this.service_id = this.data.service
    this.service_id = this.data.selectedService;

    console.log("CONSOLE THIS DATA: " + this.data);
    console.log("------------------------------");
    console.log("CONSOLE SERVICE ID: " + this.service_id);
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  onSelectService(service: any) {
    // console.log(service)
    if (service) {
      this.dialogRef.close(service);
    } else {
      this.dialogRef.close(this.data.services[0]);
    }
  }
}
