import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-template',
  templateUrl: './campaign-template.component.html',
  styleUrls: ['./campaign-template.component.css']
})
export class CampaignTemplateComponent implements OnInit {

  highlight : any

  constructor() { 
    this.highlight=[
      {
        "image": "assets/img/1.jpg",
        "productname":"Standing Camera untuk iphone dan iPad warna hitam metalic bla bla bla",
        "price":"Rp. 50.000",
        "rating":"5",
        "location":"Jakarta Utara",
        },{
        "image": "assets/img/1.jpg",
        "productname":"News",
        "price":"Rp. 50.000",
        "rating":"5",
        "location":"Jakarta Utara"
      },{
        "image": "assets/img/1.jpg",
        "productname":"News",
        "price":"Rp. 50.000",
        "rating":"4.5",
        "location":"Medan"
      },{
        "image": "assets/img/1.jpg",
        "productname":"Standing Camera",
        "price":"Rp. 50.000",
        "rating":"3",
        "location":"Surabaya"
      },{
        "image": "assets/img/1.jpg",
        "productname":"News",
        "price":"Rp. 50.000",
        "rating":"5",
        "location":"Bandung"
      },{
        "image": "assets/img/1.jpg",
        "productname":"News",
        "price":"Rp. 50.000",
        "rating":"4",
        "location":"Jakarta Selatan"
      },{
        "image": "assets/img/1.jpg",
        "productname":"News",
        "price":"Rp. 50.000",
        "rating":"4.5",
        "location":"Medan"
      },{
        "image": "assets/img/1.jpg",
        "productname":"Standing Camera",
        "price":"Rp. 50.000",
        "rating":"3",
        "location":"Surabaya"
      },{
        "image": "assets/img/1.jpg",
        "productname":"News",
        "price":"Rp. 50.000",
        "rating":"5",
        "location":"Bandung"
      },{
        "image": "assets/img/1.jpg",
        "productname":"News",
        "price":"Rp. 50.000",
        "rating":"4",
        "location":"Jakarta Selatan"
      },{
        "image": "assets/img/1.jpg",
        "productname":"News",
        "price":"Rp. 50.000",
        "rating":"4.5",
        "location":"Medan"
      },{
        "image": "assets/img/1.jpg",
        "productname":"Standing Camera",
        "price":"Rp. 50.000",
        "rating":"3",
        "location":"Surabaya"
      }
    ]
  }

  ngOnInit(): void {
  }
}
