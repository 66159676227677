import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { DialogSignUp } from '../signup/signup.component';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  form: FormGroup

  constructor(
    private service: ApiService,
    private routes: Router,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required])]
    })
  }

  resetPassword() {
    this.service.requestResetPwd(this.form.value.email).subscribe(r => {
      const dialogRef = this.dialog.open(DialogReset, {
        data: {
          title: "Password reset requested successfully.",
          heading: "Please check your email to get password reset link"
        }
      })
    }, e => {
      if (e.status == 400 && e.error.message !== 'Invalid JSON Format') {
        const dialogRef = this.dialog.open(DialogReset, {
          data: {
            title: "Password reset failed.",
            heading: e.error.message
          }
          // disableClose: true
        });
      } else {
        const dialogRef = this.dialog.open(DialogReset, {
          data: {
            title: "Password reset failed.",
            heading: "Please try again later"
          }
          // disableClose: true
        });
      }
    })
  }

}

@Component({
  selector: 'dialog-reset',
  templateUrl: 'dialog-reset.html',
})

export class DialogReset {
  
  title: string
  heading: string

  constructor(
    public dialogRef: MatDialogRef<DialogSignUp>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private routes: Router,) {
      this.title = data.title
      this.heading = data.heading
    }
  
    OK(): void{
      if (this.title === 'Password reset successfully.') {
        this.dialogRef.close()
        this.routes.navigate([''])
      } else {
        this.dialogRef.close()
      }
    }

}
