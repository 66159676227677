<!-- <section class="section team-area team-style-two bg-gray py-2">
    <div class="container">    
        <div style="padding: 10px;">           
            <div class="category d-flex flex-wrap justify-content-center flex-row overflow-auto" >
                <div class="no-marpad single-team" *ngFor="let category of category">
                    <a href="{{category.link}}" >
                        <div class="text-center m-lg-0">
                            <div  class="d-inline-block  ">
                                <img src="{{category.image}}" alt="">
                            </div>
                            <p class="mt-1">{{category.title}}</p>
                        </div>
                    </a> 
                </div>
                <div class="prev">
                    <button type="button" class="slick-prev"><img src="assets/img/arrow-prev.png" style="width: 35px;"></button>
                </div>
                <div class="next">
                    <button type="button" class="slick-prev"><img src="assets/img/arrow-next.png" style="width: 35px;"></button>
                </div>
            </div>
        </div>   
    </div> 
</section> -->


<section class="section screenshots-area bg-gray">
    <div class="container">
        <div class="col-12 menu">
            <div class="category">
                <div class="single-screenshot" *ngFor="let category of category">
                    <a href="{{category.link}}">
                        <div style="overflow: hidden; height: 125px;">
                            <div class="d-inline-block  ">
                                <img src="{{category.image}}" alt="">
                            </div>
                            <p class="mt-1" style="color: #262626;">{{category.title}}</p>
                        </div>
                    </a>
                </div>
            </div>
            <!-- <div class="prev">
                <button type="button" class="slick-prev"><img src="assets/img/arrow-prev.png" style="width: 35px;"></button>
            </div>
            <div class="next">
                <button type="button" class="slick-prev"><img src="assets/img/arrow-next.png" style="width: 35px;"></button>
            </div> -->
        </div>
    </div>
</section>