import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  category: any

  constructor() {
    this.category = [
      {
        "image": "assets/img/categories/promo1.png",
        "link": "/promo",
        "title": "ALL PROMO",
      },{
        "image": "assets/img/categories/electronic1.png",
        "link": "/electronic",
        "title": "ELEKTRONIK",
      }, {
        "image": "assets/img/categories/otomotif1.png",
        "link": "/otomotif",
        "title": "OTOMOTIF",
      }, {
        "image": "assets/img/categories/beauty1.png",
        "link": "/beauty",
        "title": "KECANTIKAN",
      }, {
        "image": "assets/img/categories/sport1.png",
        "link": "/sport",
        "title": "OLAHRAGA",
      }, {
        "image": "assets/img/categories/shopping1.png",
        "link": "/fashion",
        "title": "PAKAIAN",
      }, {
        "image": "assets/img/categories/phone.png",
        "link": "/handphone-acc",
        "title": "HANDPHONE",
      }, {
        "image": "assets/img/categories/kesehatan.png",
        "link": "/healthy",
        "title": "KESEHATAN",
      }, {
        "image": "assets/img/categories/food.png",
        "link": "/food-baverage",
        "title": "MAKANAN & MINUMAN",
      }, {
        "image": "assets/img/categories/perlengkapan-rumah.png",
        "link": "/living",
        "title": "PERLENGKAPAN RUMAH",
      }, {
        "image": "assets/img/categories/ibu-anak.png",
        "link": "/mom-baby",
        "title": "IBU & BAYI",
      }, {
        "image": "assets/img/categories/perlengkapan-sekolah.png",
        "link": "/study",
        "title": "PERLENGKAPAN SEKOLAH", 
      },{
        "image": "assets/img/categories/bills1.png",
        "link": "/all-bills",
        "title": "PEMBAYARAN",
      }
    ]
  }

  ngOnInit(): void {
  }

}
