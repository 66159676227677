import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tiket-pesawat',
  templateUrl: './tiket-pesawat.component.html',
  styleUrls: ['./tiket-pesawat.component.css']
})
export class TiketPesawatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
