import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NumberFormatPipe, VoucherFormatPipe } from '../../number.pipe';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LoginComponent } from '../accounts/login/login.component';
import { clearLocalStorage } from 'src/app/utility.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { $ } from 'jquery';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { VoucherDetailsComponent } from './voucher-details/voucher-details.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogAlert } from '../accounts/profile/myprofile/detail-profile/detail-profile.component';

declare var zoomOnHover: any;

interface variantDetails {
  product_id: number;
  product_name: string;
  variant_id: number;
  variant_name: string;
  quantity: number;
  variant_price: number;
  product_image: any;
  subtotal: number;
  store_address_id: number;
  voucher_id: number;
  voucher_code: string;
  voucher_name: string;
}

interface productDetails {
  product_id: number;
  name: string;
  store: any;
  brand_name: string;
  description: string;
  product_variants: any;
  photo_url: any;
  category_id: number;
  breadth: number;
  length: number;
  avg_rating: string;
  category_name: string;
  min_order: number;
  total_sold: number;
  total_variant: number;
  weight: number;
  vouchers: any;
  total_voucher: number;
}

interface checkFeeParam {
  originId: number;
  destinationId: number;
  weight: number;
  dimension: {
    length: number;
    height: number;
    width: number;
  }
}

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
  providers: [NumberFormatPipe, VoucherFormatPipe, ApiService]
})

export class ProductDetailsComponent implements OnInit {
  productDetails: productDetails

  variantDetails: variantDetails = {
    product_id: 0,
    product_name: "",
    variant_id: 0,
    variant_name: "",
    quantity: 0,
    variant_price: 0,
    product_image: "",
    subtotal: 0,
    store_address_id: 0,
    voucher_id: 0,
    voucher_code: "",
    voucher_name: ""
  }

  checkFeeParam: checkFeeParam = {
    originId: 0,
    destinationId: 0,
    weight: 1,
    dimension: {
      length: 1,
      height: 1,
      width: 1
    }
  }

  reviewInfos: any
  reviews: any
  discussions: any
  selectedPhoto: string
  reviewPageIndex: number = 0
  reviewTotalPage: any[] = []
  params: ParamMap
  product_id: any
  // store_id: any
  radioSelected: any
  selectRadio: boolean = false
  selectDest: boolean = false
  message: string
  // store: any
  loggedin: string = 'false'
  isLoading: boolean = false

  productPrice: number | null = null
  productStock: number | null = null
  variantId: number | null = null

  form: FormGroup
  destinationSearchData: any = [];
  destinationCityName: string
  services: any[]
  minServiceFee: number
  maxServiceFee: number

  // public autoCorrect = true
  // public min = 1
  // public max = 10

  // TODO voucher, image, user image for review, fix created_at format for review + discussion, ongkos kirim
  // TODO last online, selected product images, quantity min max follows chosen 

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    private voucherFormat: VoucherFormatPipe,
    private datepipe: DatePipe,
    public dialog: MatDialog,
    private routes: Router,
    private clipboard: Clipboard,
    private fb: FormBuilder
  ) {
    this.params = this.route.snapshot.queryParamMap
    this.product_id = this.params.get('product_id')
    this.fetchVouchers()
    this.fetchProductDetails()
    this.fetchReviews(this.product_id, this.reviewPageIndex)
    this.fetchDiscussions()

    // this.service.getStoreId().subscribe(data => {
    //   this.store = data
    //   console.log("ProductDetailsComponent-getStoreId")
    //   console.log(data)
    // }, e => {
    //   this.store = null
    //   console.log(e)
    // })
  }

  fetchProductDetails() {
    this.isLoading = true
    // console.log(this.loggedin)
    // console.log(this.store)
    this.service.productDetail(this.product_id).subscribe(data => {
      this.productDetails = data
      this.selectedPhoto = data.photo_url[0]
      for (let i = 0; i < data.product_variants.length; i++) {
        // if (data.product_variants[i].variant_stock !== 0) {
          if (!this.selectRadio) {
            this.radioSelected = this.productDetails.product_variants[i].variant_id
            this.variantId = this.productDetails.product_variants[i].variant_id
            this.productStock = this.productDetails.product_variants[i].variant_stock
            this.productPrice = this.productDetails.product_variants[i].variant_price
            break
          }

          if (!data.height) {
            this.checkFeeParam = {
              originId: this.productDetails.store.osas_log_id,
              destinationId: null,
              weight: this.productDetails.weight,
              dimension: {
                length: this.productDetails.length,
                width: this.productDetails.breadth,
                height: 1,
              }
            }
            
          } else {
            this.checkFeeParam = {
              originId: this.productDetails.store.osas_log_id,
              destinationId: null,
              weight: this.productDetails.weight,
              dimension: {
                length: this.productDetails.length,
                width: this.productDetails.breadth,
                height: data.height,
              }
            }
          }
        // } else {
          // if (this.selectRadio) {
          //   this.radioSelected = this.productDetails.product_variants[i].variant_id
          //   this.variantId = this.productDetails.product_variants[i].variant_id
          //   this.productStock = 0
          //   this.productPrice = this.productDetails.product_variants[i].variant_price
          //   break
          // }
        // }
      }

      if (this.checkFeeParam.destinationId == 0) {
        this.destinationCityName = data.store.city
        this.checkFeeParam = {
          originId: this.productDetails.store.osas_log_id,
          destinationId: data.store.osas_log_id,
          weight: this.productDetails.weight,
          dimension: {
            length: this.productDetails.length,
            width: this.productDetails.breadth,
            height: this.checkFeeParam.dimension.height,
          }
        }

        this.pickService(this.checkFeeParam)
      }
      // console.log(this.checkFeeParam)
      // console.log(data)
      // console.log(this.productDetails.vouchers)
      this.isLoading = false
    }, e => {
      console.log(e)
      this.isLoading = false
    })
  }

  hoverZoom(e) {
    new zoomOnHover(e)
  }

  selectThumbnail(i) {
    // console.log(i)
    this.selectedPhoto = i
  }

  shareProduct() {
    this.clipboard.copy(this.service.BASEURL + 'product?product_id=' + this.product_id)
    this.openSnackBar("Tautan berhasil di salin", "Ok")
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoginComponent, {
      id: 'LoginDialog'
    });
  }

  ngOnInit(): void {
    this.isLoading = true

    this.form = this.fb.group({
      dest: [null, Validators.compose([Validators.required])]
    });

    this.fetchVouchers()
    this.fetchProductDetails()
    
    //Test if user status still loggedin
    if ((localStorage.getItem('username') != null) && (localStorage.getItem('jwt') != null)) {
      this.service.verify(localStorage.getItem('jwt')).subscribe((r) => {
        // console.log(r)
        this.loggedin = 'true'
        this.service.userMainAddress().subscribe(mainAddress => {
          // console.log(mainAddress)
          this.service.userAddressDetail(mainAddress.address_id).subscribe(addressDetail => {
            this.checkFeeParam.destinationId = addressDetail.osas_log_id
            this.destinationCityName = addressDetail.city
            this.isLoading = false
          })
        })
        // console.log(this.loggedin)
      }, e => {
        console.log(e)
        clearLocalStorage()
      })
    } else {
      clearLocalStorage()
    }
    // this.getLocation()

    this.fetchReviews(this.product_id, this.reviewPageIndex)
    this.fetchDiscussions()

    // $('#carousel').attr("style", "display: none").then(this.isLoading = false);
  }

  // getLocation(): void {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const longitude = position.coords.longitude;
  //       const latitude = position.coords.latitude;
  //     });
  //   } else {
  //     console.log("No support for geolocation")
  //   }
  // }

  private destination_serv(value: string): any {
    return this.service.destinationSearch(value).pipe(map(res => {
      for (var i = res.length; i--;) {
        if (res[i].urban_name.slice(0, 9) == "KECAMATAN") {
          res.splice(i, 1)
        }
      }
      return res
    }), catchError(err => of([])))
  }

  displayFnDest(consignee?: any): string | undefined {
    return consignee ? consignee.urban_name + ", " + consignee.sub_district_name + ", " + consignee.city_name + ", Prov. " + consignee.province_name + ", " + consignee.postal_code : undefined;
  }

  dateTransform(date) {
    return this.datepipe.transform(date, 'd MMMM yyyy HH:mm');
  }

  variantSwitch(event) {
    const productVariant = this.productDetails.product_variants
    const index = productVariant.findIndex((item) => {
      return item.variant_id == event.target.id
    })
    this.radioSelected = event.target.id
    this.variantId = event.target.id
    this.productStock = productVariant[index].variant_stock
    this.productPrice = productVariant[index].variant_price
    this.inputnumber = 1
    this.selectRadio = true
    // console.log('Variant Switch')
    // console.log(event.target.id)
  }

  voucherDetails: any

  fetchVouchers() {
    // this.isLoading = true
    this.service.voucherList().subscribe(data => {
      // console.log(data)
      this.voucherDetails = data
      // this.isLoading = false
    }, e => {
      console.log(e)
      // this.isLoading = false
    })
  }

  voucherDetail(e) {
    // console.log(e)

    var detailData = e

    for (let i = 0; i < this.productDetails.product_variants.length; i++) {
      if (this.productDetails.product_variants[i].variant_id == this.variantId) {
        this.variantDetails.subtotal = (this.productDetails.product_variants[i].variant_price) * (this.inputnumber)
      }
    }

    let dialogRef = this.dialog.open(VoucherDetailsComponent, {
      width: '950px',
      height: '470px',
      data: {
        voucher: detailData,
        currentPrice: this.variantDetails.subtotal
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(voucher => {
      if (voucher) {
        this.variantDetails.voucher_id = voucher.voucher_id
        this.variantDetails.voucher_code = voucher.code
        this.variantDetails.voucher_name = voucher.title
        this.openSnackBar('Voucher berhasil digunakan', 'OK')
      }
      this.fetchVouchers()
    });
  }

  fetchReviews(productId: number, pageIndex: number = 0) {
    this.service.reviewList(productId, pageIndex, null).subscribe(data => {
      this.reviews = data
      this.reviewTotalPage = Array(Math.round(data.total / 2))
      // console.log("ProductDetailsComponent-Review")
      // console.log(data)
    }, e => {
      console.log(e)
    })
  }

  pickDestination() {
    this.selectDest = true
    this.destinationSearchData = this.form.get('dest')?.valueChanges.pipe(
      // debounceTime(250),
      distinctUntilChanged(),
      startWith(''),
      switchMap(value => this.destination_serv(value))
    );
  }

  pickService(checkFeeParam) {
    // console.log(checkFeeParam)
    this.service.checkDeliveryFee(checkFeeParam).subscribe(r => {
      // console.log(r)
      this.services = r.services
      this.minServiceFee = r.services[0].total
      this.maxServiceFee = r.services[0].total
      for (let i = 0; i < r.services.length; i++) {
        if (this.minServiceFee > r.services[i].total) {
          this.minServiceFee = r.services[i].total
        }

        if (this.maxServiceFee < r.services[i].total) {
          this.maxServiceFee = r.services[i].total
        }
      }
    })
  }

  onDestChange(option) {
    // console.log(option)
    this.checkFeeParam = {
      originId: this.productDetails.store.osas_log_id,
      destinationId: option.id,
      weight: this.checkFeeParam.weight,
      dimension: {
        length: this.checkFeeParam.dimension.length,
        width: this.checkFeeParam.dimension.width,
        height: this.checkFeeParam.dimension.height,
      }
    }
    this.destinationCityName = option.city_name

    this.pickService(this.checkFeeParam)
  }

  fetchDiscussions() {
    this.service.discussionList(this.product_id, this.reviewPageIndex).subscribe(data => {
      this.discussions = data
      // console.log("ProductDetailsComponent-Discussion")
      // console.log(data)
    }, e => {
      console.log(e)
    })
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  voucherTransform(value: number) {
    return this.voucherFormat.transform(value)
  }

  arrowPagination(arg: string, number: number = 0) {
    if (arg === 'previous') {
      this.reviewPageIndex = this.reviewPageIndex - 1
    } else if (arg === 'next') {
      this.reviewPageIndex = this.reviewPageIndex + 1
    }
    this.fetchReviews(this.product_id, this.reviewPageIndex)
  }

  numberPagination(index: number = 0) {
    this.reviewPageIndex = index
    this.fetchReviews(this.product_id, this.reviewPageIndex)
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'center'

    });
  }

  inputnumber = 1;

  plus() {
    // console.log(this.radioSelected)
    // console.log(this.variantId)
    if (this.inputnumber < this.productStock) {
      this.inputnumber = this.inputnumber + 1;
    }

    this.fetchProductDetails()
    this.fetchReviews(this.product_id, this.reviewPageIndex)
    this.fetchDiscussions()
  }
  minus() {
    // console.log(this.radioSelected)
    // console.log(this.variantId)
    if (this.inputnumber > 1) {
      this.inputnumber = this.inputnumber - 1;
    }

    this.fetchProductDetails()
    this.fetchReviews(this.product_id, this.reviewPageIndex)
    this.fetchDiscussions()
  }

  onKeyUp(x) {
    //const typedValue = x.keyCode
    const num = this.inputnumber

    // console.log("DAAADA")
    // console.log(num)
    if ((num > this.productStock)) {
      // console.log("HIIII")
      this.inputnumber = this.productStock
    } else if ((num < 1)) {
      // console.log("HIIII")
      this.inputnumber = 0
    }

    this.fetchProductDetails()
    this.fetchReviews(this.product_id, this.reviewPageIndex)
    this.fetchDiscussions()
  }

  addToCart() {
    const quantity = this.inputnumber
    const variant_id = this.variantId

    this.service.addCart(quantity, variant_id).subscribe(data => {

      // console.log("asdokaod")
      // console.log(data)
      this.service.isUpdateCart.next(true)
      this.openSnackBar('Item berhasil dimasukkan ke keranjang', 'Ok')
      this.fetchProductDetails()
      this.fetchReviews(this.product_id, this.reviewPageIndex)
      this.fetchDiscussions()

    }, e => {
      this.message = e.error.message
      console.log(e)
      // console.log(e.error.message)
      // this.openSnackBar('Tidak bisa membeli produk sendiri', 'Ok')
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Gagal menambah produk kedalam keranjang. Mohon coba beberapa saat lagi.'
        }
      });
      this.fetchProductDetails()
      this.fetchReviews(this.product_id, this.reviewPageIndex)
      this.fetchDiscussions()
    })
    // let currentUrl = this.routes.url;
    // this.routes.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.routes.onSameUrlNavigation = 'reload';
    // this.routes.navigate([currentUrl]);
    // console.log(currentUrl)
  }

  buyNow() {
    this.variantDetails.product_name = this.productDetails.name
    this.variantDetails.variant_id = this.variantId
    this.variantDetails.product_id = this.productDetails.product_id
    for (let i = 0; i < this.productDetails.product_variants.length; i++) {
      if (this.productDetails.product_variants[i].variant_id == this.variantId) {
        this.variantDetails.variant_name = this.productDetails.product_variants[i].variant_name
        this.variantDetails.variant_price = this.productDetails.product_variants[i].variant_price
        this.variantDetails.subtotal = (this.productDetails.product_variants[i].variant_price) * (this.inputnumber)
      }
    }
    this.variantDetails.quantity = this.inputnumber
    this.variantDetails.product_image = this.productDetails.photo_url[0]
    this.variantDetails.store_address_id = this.productDetails.store.address_id
    // console.log([this.variantDetails])
    this.routes.navigateByUrl('/checkout', { state: { orderInfo: [this.variantDetails] } })
  }

}
