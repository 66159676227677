import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { NumberFormatPipe } from '../../number.pipe';

@Component({
  selector: 'app-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.css'],
  providers: [NumberFormatPipe]
})
export class HighlightComponent implements OnInit {

  highlights: any
  currency: any

  constructor(
    private service: ApiService,
    private currencyFormat: NumberFormatPipe
  ) {
    this.service.randomizeProducts().subscribe(data => {
      this.highlights = data
      // console.log("HighlightComponent")
      // console.log(data)
    }, e => {
      console.log(e)
    }
    )
  }

  priceTransform(price:number){
    return this.currencyFormat.transform(price)
  }

  ngOnInit(): void {
  }

}
