<div style="padding-bottom: none; padding-top: 1rem!important;">
  <div class="card mb-2">
    <div class="card-body py-3">
      <div class="col-12 d-flex flex-row flex-wrap" style="padding: 0px;">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <h3>Daftar Alamat</h3>
          <mat-form-field>
            <input matInput [formControl]="form.controls['keyword']" (keyup)="search($event)" placeholder="Cari Alamat">
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 d-flex flex-row justify-content-end align-items-center">
          <a class="btn" (click)="openDialog('Tambah',{})"><i class="fas fa-plus mr-2"></i>Tambah Alamat</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row wrap">
  <div fxFlex="100">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="table wrap">
        <ng-container matColumnDef="#">
          <th mat-header-cell *matHeaderCellDef> # </th>
          <td mat-cell *matCellDef="let element"> {{element.address_id}} </td>
        </ng-container>
        <ng-container matColumnDef="recipient">
          <th mat-header-cell *matHeaderCellDef> Recipient </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <div class="col-lg-3 col-sm-6 d-flex flex-column d-none d-md-block ">
                <p>Penerima</p>
                <p class="mb-3">Telepon</p>
                <p>Kategori</p>
                <p>Alamat</p>
                <p></p>
              </div>
              <div class="col-lg-9 col-sm-6">
                <div class="d-flex flex-row flex-wrap">
                  <p class="bold" style="font-size: 13px;">{{element.recipient}}</p>
                  <ng-template [ngIf]="element.primary_address">
                    <p class="text-center highlight" style="margin-left: 15px;">Utama</p>
                  </ng-template>
                </div>
                <p class="mb-3">+62{{element.phone}}</p>
                <p class="bold">{{element.category}}</p>
                <p style="height: 18px; width: 214px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                  {{element.address_input}}</p>
                <p style="height: 18px; width: 214px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                  {{element.province}}, {{element.district}}, ID {{element.post_id}}</p>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element" class="action-link">
            <div class="d-flex flex-row wrap">
              <a class="btn-action" (click)="openDialog('Ubah',element)" style="cursor: pointer;">
                <i class=" fas fa-edit mr-2"></i>Ubah
              </a>
              <a class="btn-action" (click)="openDialog('Hapus',element)" style="cursor: pointer;">
                <i class="fas fa-trash mr-2"></i>Hapus
              </a>
            </div>

          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

      <ng-template [ngIf]="0 >= totalData">
        <div class="row justify-content-center" style="justify-content: center;">
            <div>
                <img class="shopping-cart-img" style="align-self: center; width: 200px;"
                    src="/assets/img/empty.png">
            </div>
        </div>
        <div style="text-align: center;">
            <div style="color: #1d1d1d;">
                <br>
                Belum ada alamat
            </div>
        </div>
      </ng-template>

      <mat-paginator #paginator [pageSize]="10" [pageIndex]="pageIndex" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>

    </div>
  </div>
</div>