<section id="screenshots" class="section screenshots-area bg-gray pb-4">
  <div class="container">
    <mat-card class="p-4">
      <div class="row">
        <div class="col-12">
          <h3 class="text-center trending">Trending Now</h3>
        </div>
        <div class="col-12">
          <div class="app-screenshots">
            <div
              class="single-screenshot"
              *ngFor="let recommendation of recommendations"
            >
              <a href="/product?product_id={{ recommendation.product_id }}">
                <mat-card
                  class="content h-100!important"
                  style="overflow: hidden"
                >
                  <img
                    mat-card-image
                    class="product-card-image"
                    src="{{ recommendation.main_photo_url }}"
                    alt="product image"
                  />
                  <mat-card-content>
                    <div>
                      <p class="over">{{ recommendation.name }}</p>
                    </div>
                    <div>
                      <p
                        class="bold"
                        style="margin-bottom: 0px; font-size: 13px"
                      >
                        Rp {{ priceTransform(recommendation.min_price) }}
                      </p>
                    </div>
                  </mat-card-content>
                  <mat-card-footer align="end">
                    <div>
                      <p class="desc">
                        <i class="marker fas fa-star"></i
                        ><i class="marker fas fa-star"></i
                        ><i class="marker fas fa-star"></i
                        ><i class="marker fas fa-star"></i
                        ><i class="marker fas fa-star"></i>
                      </p>
                    </div>
                    <div>
                      <p class="desc">
                        <i class="marker fas fa-map-marker-alt"></i>
                        {{ recommendation.city }}
                      </p>
                    </div>
                  </mat-card-footer>
                </mat-card>
              </a>
            </div>
          </div>
          <div class="arrow_prev">
            <button type="button" class="slick-prev">
              <img src="assets/img/arrow-prev.png" style="width: 35px" />
            </button>
          </div>
          <div class="arrow_next">
            <button type="button" class="slick-prev">
              <img src="assets/img/arrow-next.png" style="width: 35px" />
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</section>
