import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DetailComponent } from './detail/detail.component';
import { ApiService } from 'src/app/api.service';
import { DatePipe } from '@angular/common';
import { NumberFormatPipe } from 'src/app/number.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  orders: any
  process: any
  orderTotal: number
  processTotal: number
  pageIndex: number = 0
  statusOrder: string = 'Semua'

  constructor(public dialog: MatDialog, private routes: Router,
    private snackBar: MatSnackBar,
    private service: ApiService,
    public datepipe: DatePipe,
    private currencyFormat: NumberFormatPipe) { }

  dateTransform(date) {
    return this.datepipe.transform(date, 'dd MMM yyyy');
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  handlePage(e: any) {
    this.pageIndex = e.pageIndex

    // console.log(this.statusOrder)
    // console.log(this.pageIndex)

    if (this.statusOrder === 'Semua') {
      this.service.listOrderHistory(this.pageIndex).subscribe(allOrder => {
        // console.log(allOrder)
        this.orders = allOrder.orders
        this.orderTotal = allOrder.total
      })
    } else {
      this.service.listProcess(this.pageIndex, this.statusOrder).subscribe(data => {
        // console.log('LABEL HERE')
        this.orders = data.orders
        this.orderTotal = data.total
        // console.log("OrderComponent-listProcess")
        // console.log(data)
      }, e => {
        console.log(e)
      })
    }
  }

  ngOnInit(): void {
    this.service.listOrderHistory(0).subscribe(data => {
      this.orders = data.orders
      this.orderTotal = data.total
      // console.log("OrderComponent-listOrder")
      // console.log(this.orders)
    }, e => {
      console.log(e)
    })
    // this.service.listUserOrderHistory(0).subscribe((r: any) => {
    //   console.log("HEREEEE")
    //   console.log(r)
    //   // this.dataSource = r.addresses
    //   // this.totalData = r.total
    // })
  }

  orderStatus(event) {
    // console.log("FIDIDIIIIIIIII")
    // console.log(event)
    // console.log(event.tab.textLabel)
    this.statusOrder = event.tab.textLabel

    if (event.tab.textLabel === 'Semua') {
      this.service.listOrderHistory(0).subscribe(allOrder => {
        // console.log(allOrder)
        this.orders = allOrder.orders
        this.orderTotal = allOrder.total
      }, e => {
        console.log(e)
      })
    } else {
      this.service.listProcess(0, event.tab.textLabel).subscribe(data => {
        // console.log('LABEL HERE')
        this.orders = data.orders
        this.orderTotal = data.total
        // console.log("OrderComponent-listProcess")
        // console.log(data)
      }, e => {
        console.log(e)
      })
    }
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'center'

    });
  }

  detail(e) {
    // console.log(e.target.id)

    var detailData = {
      snapshoot: '',
      total_price: 0,
      final_price: 0,
      insurance_fee: 0,
      expedition_price: 0,
      expedition_type: '',
      invoice: '',
      created_at: '',
      waybill_number: '',
      message: '',
      address_id: 0,
      voucher_id: 0,
      booking_number: ''
    }

    for (let i = 0; i < this.orders.length; i++) {
      if (this.orders[i].invoice === e.target.id) {
        detailData.snapshoot = this.orders[i].snapshoot_lists
        detailData.total_price = this.orders[i].total_price
        detailData.final_price = this.orders[i].final_price
        detailData.insurance_fee = this.orders[i].insurance_fee
        detailData.expedition_price = this.orders[i].expedition_price
        detailData.expedition_type = this.orders[i].expedition_type
        detailData.invoice = this.orders[i].invoice
        detailData.created_at = this.orders[i].created_at
        detailData.waybill_number = this.orders[i].waybill_number
        detailData.booking_number = this.orders[i].booking_number
        detailData.message = this.orders[i].message
        detailData.address_id = this.orders[i].address_id
        detailData.voucher_id = this.orders[i].voucher_id
      }
    }

    // console.log(detailData, 'ORDER DETAIL')

    let dialogRef = this.dialog.open(DetailComponent, {
      width: '750px',
      height: '470px',
      data: detailData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'Cancelled') {
        this.openSnackBar('Pesanan berhasil dibatalkan', '')
      } else if (result.data === 'Paid') {
        this.openSnackBar('Pembayaran berhasil dilakukan', '')
      }
      if (this.statusOrder === 'Semua') {
        this.service.listOrderHistory(this.pageIndex).subscribe(allOrder => {
          // console.log(allOrder)
          this.orders = allOrder.orders
          this.orderTotal = allOrder.total
        })
      } else {
        this.service.listProcess(this.pageIndex, this.statusOrder).subscribe(data => {
          // console.log('LABEL HERE')
          this.orders = data.orders
          this.orderTotal = data.total
          // console.log("OrderComponent-listProcess")
          // console.log(data)
        }, e => {
          console.log(e)
        })
      }
    });
  }

}