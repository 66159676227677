<!-- <mat-tab label="Produk"> -->
    <!-- <ng-template mat-tab-label>
        <a [routerLink]="['.']" [queryParams]="{ tab: 'product' }"
            queryParamsHandling="merge"></a>
    </ng-template> -->
    <!-- <app-store-profile></app-store-profile> -->
    <div class="row" id="1">
        <div class="col-12 col-lg-3 ">
            <div class="sticky-side">
                <aside class="sidebar ">
                    <h4 class="cat-title bold"><span
                            class="material-icons">filter_alt</span> FILTER</h4>
                    <!-- Single Widget -->
                    <div class="single-widget">
                        <!-- Post Widget -->
                        <div class="accordions widget catagory-widget" id="cat-accordion">
                            <div class="single-accordion">
                                <h5>
                                    <a role="button"
                                        class="collapse show text-uppercase d-block p-3 bold"
                                        data-toggle="collapse" href="#accordion1">Pilihan
                                        Lokasi
                                    </a>
                                </h5>
                                <!-- Post Widget Content -->
                                <div id="accordion1"
                                    class="accordion-content widget-content collapse show"
                                    data-parent="#post-accordion">
                                    <!-- Post Widget Items -->
                                    <ul class="widget-items">
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Jakarta</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Kalimantan
                                                    Tengah</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Jawa Timur</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Sumatera
                                                    Utara</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Kepulauan
                                                    Riau</label>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Single Widget -->
                    <!-- <div class="single-widget"> -->
                        <!-- Post Widget -->
                        <!-- <div class="accordions widget catagory-widget" id="cat-accordion">
                            <div class="single-accordion">
                                <h5>
                                    <a role="button"
                                        class="collapse show text-uppercase d-block p-3 bold"
                                        data-toggle="collapse" href="#accordion2">ON PROMO
                                    </a>
                                </h5> -->
                                <!-- Post Widget Content -->
                                <!-- <div id="accordion2"
                                    class="accordion-content widget-content collapse show"
                                    data-parent="#post-accordion"> -->
                                    <!-- Post Widget Items -->
                                    <!-- <ul class="widget-items">
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox" class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Voucher Diskon</label>
                                            </div>
                                        </li> -->
                                        <!-- <li>
                                            <div class="filter">
                                                <input type="checkbox" class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Diskon</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox" class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Cashback</label>
                                            </div>
                                        </li> -->
                                    <!-- </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="single-widget">
                        <!-- Post Widget -->
                        <div class="accordions widget catagory-widget" id="cat-accordion">
                            <div class="single-accordion">
                                <h5>
                                    <a role="button"
                                        class="collapse show text-uppercase d-block p-3 bold"
                                        data-toggle="collapse" href="#accordion3">Pengiriman
                                    </a>
                                </h5>
                                <!-- Post Widget Content -->
                                <div id="accordion3"
                                    class="accordion-content widget-content collapse show"
                                    data-parent="#post-accordion">
                                    <!-- Post Widget Items -->
                                    <ul class="widget-items">
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">ONDELIVERY</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Go Send</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Paxel</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">J&T</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">JNE</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="filter">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1">
                                                <label class="form-check-label pl-1"
                                                    for="exampleCheck1">Pos
                                                    Indonesia</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Single Widget -->
                    <div class="single-widget">
                        <!-- Post Widget -->
                        <div class="accordions widget post-widget" id="post-accordion">
                            <div class="single-accordion">
                                <h5>
                                    <a role="button"
                                        class="collapse show text-uppercase d-block p-3 bold"
                                        data-toggle="collapse" href="#accordion4">Batas
                                        Harga
                                    </a>
                                </h5>
                                <!-- Post Widget Content -->
                                <div id="accordion4"
                                    class="accordion-content widget-content collapse show"
                                    data-parent="#post-accordion">
                                    <!-- Post Widget Items -->
                                    <div class="p-3">
                                        <input type="text" class="form-control"
                                            placeholder="Harga Terendah">
                                    </div>
                                    <div class="p-3">
                                        <input type="text" class="form-control"
                                            placeholder="Harga Tertinggi">
                                    </div>
                                    <div class="w-100">
                                        <button class="btn">Cari Harga</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
        <div class="col-12 col-lg-9 pt-5">
            <div class="row">
                <div class="d-none d-md-block col-md-4 col-lg-3 mb-3 pl-1 pr-1"
                    *ngFor="let storeProduct of storeProducts">
                    <a href="/product?product_id={{storeProduct.product_id}}">
                        <mat-card class="content h-100">
                            <img mat-card-image class="product-card-image"
                                src="{{storeProduct.main_photo_url}}" alt="product image">
                            <mat-card-content>
                                <div>
                                    <p class="over">{{storeProduct.name}}</p>
                                </div>
                                <div>
                                    <p class="bold"
                                        style="margin-bottom: 0px; font-size: 13px;">Rp
                                        {{priceTransform(storeProduct.min_price)}}</p>
                                </div>
                            </mat-card-content>
                            <mat-card-footer align="end">
                                <div>
                                    <p class="desc"><i class=" marker fas fa-star"></i><i
                                            class=" marker fas fa-star"></i><i
                                            class=" marker fas fa-star"></i><i
                                            class=" marker fas fa-star"></i><i
                                            class=" marker fas fa-star"></i></p>
                                </div>
                                <div>
                                    <p class="desc"><i
                                            class=" marker fas fa-map-marker-alt"></i>
                                        {{storeProduct.city}}</p>
                                </div>
                            </mat-card-footer>
                        </mat-card>
                    </a>
                </div>
            </div>
            <mat-card class="d-block .d-sm-none d-md-none mb-3"
                *ngFor="let storeProduct of storeProducts">
                <a href="/product?product_id={{storeProduct.product_id}}">
                    <div class="col-12">
                        <div class="row d-flex flex-wrap">
                            <div class="col-4 col-md-2 pl-0 pr-0">
                                <img class="align-self-center thumbnail-cart"
                                    src="{{storeProduct.main_photo_url}}" alt="">
                            </div>
                            <div class="col-8 col-md-6">
                                <p class="over">{{storeProduct.name}}</p>
                                <p class="bold">Rp
                                    {{priceTransform(storeProduct.min_price)}}</p>
                                <p class="desc text-right"><i
                                        class=" marker fas fa-star"></i><i
                                        class=" marker fas fa-star"></i><i
                                        class=" marker fas fa-star"></i><i
                                        class=" marker fas fa-star"></i><i
                                        class=" marker fas fa-star"></i></p>
                                <p class="desc text-right"><i
                                        class=" marker fas fa-map-marker-alt"></i>
                                    {{storeProduct.city}}</p>
                            </div>
                        </div>
                    </div>
                </a>
            </mat-card>

            <div class="row">
                <div class="col-12">
                    <!-- Pagination -->
                    <ul class="pagination justify-content-center">
                        <li class="px-1" [ngClass]="{'disabled': pageIndex === 0}">
                            <a href="/product/list?store_id={{storeProducts[0].store_id}}"
                                aria-label="Previous" (click)="arrowPagination('previous')">
                                <i class="fas fa-arrow-left"></i>
                            </a>
                        </li>
                        <li class="px-1" *ngFor="let page of totalPageSize;index as i"
                            [ngClass]="{'active': pageIndex === i}">
                            <a href="/product/list?store_id={{storeProducts[0].store_id}}"
                                (click)="pageIndex = i;numberPagination(i)">{{i+1}}</a>
                        </li>
                        <li
                            [ngClass]="{'disabled': pageIndex  === totalPageSize.length - 1}">
                            <a href="/product/list?store_id={{storeProducts[0].store_id}}"
                                aria-label="Next" (click)="arrowPagination('next')">
                                <i class="fas fa-arrow-right"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
<!-- </mat-tab> -->