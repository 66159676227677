<div class="col ptb_50" style="text-align: center" *ngIf="loadingMode">
  <mat-spinner style="margin: 80px auto"></mat-spinner><br />
  <h4>Loading</h4>
</div>
<div class="card" style="overflow: hidden" *ngIf="!loadingMode">
  <div class="card-body pt-4 py-2">
    <div class="col-12">
      <h3 style="text-align: center">Detail Belanja</h3>
      <mat-divider class="py-2"></mat-divider>
      <div class="row">
        <!-- <div class="d-flex"> -->
        <div class="col-lg-6 col-sm-6 d-flex flex-column d-none d-md-block">
          <p style="font-size: 14.5px">
            <strong>{{
              details.snapshoot[0].product_snapshoot.process_status
            }}</strong>
          </p>
        </div>
        <div class="col-lg-6 col-sm-6">
          <ng-template [ngIf]="!seeDetails">
            <div class="row justify-content-end">
              <p
                class="bold clickable text-right"
                style="color: #3571b6"
                (click)="toggleCollapse()"
              >
                Lihat Detail
              </p>
              <mat-icon
                class="clickable"
                style="color: #3571b6"
                (click)="toggleCollapse()"
              >
                keyboard_arrow_down</mat-icon
              >
            </div>
          </ng-template>
          <ng-template [ngIf]="seeDetails">
            <div class="row justify-content-end">
              <p
                class="bold clickable text-right"
                style="color: #3571b6"
                (click)="toggleCollapse()"
              >
                Sembunyikan
              </p>
              <mat-icon
                class="clickable"
                style="color: #3571b6"
                (click)="toggleCollapse()"
              >
                keyboard_arrow_up</mat-icon
              >
            </div>
          </ng-template>
        </div>
        <!-- </div> -->
      </div>
      <ng-template [ngIf]="seeDetails">
        <div
          style="
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 5px;
            margin-bottom: 10px;
          "
        >
          <div class="row" style="margin: 5px">
            <mat-vertical-stepper [linear]="isLinear" #stepper>
              <ng-template matStepperIcon="place">
                <mat-icon>place</mat-icon>
              </ng-template>
              <mat-step
                [completed]="true"
                state="place"
                label="{{ formatDate(historyItem.updated_at) }}"
                *ngFor="let historyItem of trackingDetail"
              >
                {{ historyItem.status }}
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </div>
      </ng-template>
      <div class="row">
        <!-- <div class="d-flex"> -->
        <div class="col-lg-6 col-sm-6 d-flex flex-column d-none d-md-block">
          <p class="mb-3">Invoice No.</p>
          <p>Tanggal Pembelian</p>
        </div>
        <div class="col-lg-6 col-sm-6 justify-content-end">
          <p class="mb-3 text-right">{{ details.invoice }}</p>
          <p class="text-right">{{ details.created_at }} WIB</p>
        </div>
        <!-- </div> -->
      </div>
      <mat-divider class="py-2"></mat-divider>
      <p class="bold" style="font-size: 14.5px">Detail Produk</p>
      <div *ngFor="let detail of details.snapshoot">
        <div class="row">
          <!-- <div class="d-flex"> -->
          <div class="col-lg-4 col-md-12 col-sm-12">
            <h4 style="margin: 0px">{{ detail.product_snapshoot.name }}</h4>
            <p class="text-muted">
              Variasi : {{ detail.product_snapshoot.variant_name }}
            </p>
          </div>
          <div
            class="col-lg-5 col-md-12 col-sm-12 align-self-center"
            style="text-align: center"
          >
            <p>
              {{ detail.product_snapshoot.quantity }} x Rp
              {{ priceTransform(detail.product_snapshoot.price) }}
            </p>
          </div>
          <div
            class="col-lg-3 col-md-12 col-sm-12 pr-2 align-self-center justify-align-right text-right"
          >
            <p>Total Produk :</p>
            <p>Rp {{ priceTransform(detail.product_snapshoot.subtotal) }}</p>
          </div>
          <!-- </div> -->
        </div>
        <mat-divider class="py-2"></mat-divider>
      </div>
      <ng-template [ngIf]="details.insurance_fee">
        <div class="row">
          <!-- <div class="d-flex"> -->
          <div class="col-lg-4 col-md-12 col-sm-12">
            <!-- <h4 style="margin: 0px;">Biaya Asuransi : </h4> -->
            <!-- <p class="text-muted">Variasi : {{detail.product_snapshoot.variant_name}}</p> -->
          </div>
          <div
            class="col-lg-5 col-md-12 col-sm-12 align-self-center"
            style="text-align: right"
          >
            <h4>Biaya Asuransi :</h4>
          </div>
          <div
            class="col-lg-3 col-md-12 col-sm-12 pr-2 align-self-center justify-align-right text-right"
          >
            <!-- <p>Total Produk : </p> -->
            <p>Rp {{ priceTransform(details.insurance_fee) }}</p>
          </div>
          <!-- </div> -->
        </div>
        <!-- <mat-divider class="py-2"></mat-divider> -->
      </ng-template>
      <ng-template
        [ngIf]="
          details.voucher_id !== null &&
          details.voucher_id !== undefined &&
          details.voucher_id !== '0'
        "
      >
        <div class="row">
          <!-- <div class="d-flex"> -->
          <div class="col-lg-4 col-md-12 col-sm-12">
            <!-- <h4 style="margin: 0px;">Diskon : </h4> -->
            <!-- <p class="text-muted">Variasi : {{detail.product_snapshoot.variant_name}}</p> -->
          </div>
          <div
            class="col-lg-5 col-md-12 col-sm-12 align-self-center"
            style="text-align: right"
          >
            <!-- <p>{{details.voucher_code}}</p> -->
            <h4>Diskon :</h4>
          </div>
          <div
            class="col-lg-3 col-md-12 col-sm-12 pr-2 align-self-center justify-align-right text-right"
          >
            <!-- <p>Total Produk : </p> -->
            <p>- Rp {{ priceTransform(details.total_discount) }}</p>
          </div>
          <!-- </div> -->
        </div>
        <mat-divider class="py-2"></mat-divider>
      </ng-template>
      <p class="bold" style="font-size: 14.5px">Info Pengiriman</p>
      <ng-template [ngIf]="details.waybill_number">
        <div class="row">
          <div class="col-lg-2 col-sm-6 d-flex flex-column d-none d-md-block">
            <p class="mb-3">No. Resi</p>
          </div>
          <div>
            <p class="mb-3">:</p>
          </div>
          <div class="col-lg-6 col-sm-6 justify-content-start">
            <p class="mb-3">{{ details.waybill_number }}</p>
          </div>
        </div>
      </ng-template>
      <div class="row">
        <div class="col-lg-2 col-sm-6 d-flex flex-column d-none d-md-block">
          <p>Alamat</p>
        </div>
        <div>
          <p>:</p>
        </div>
        <div class="col-lg-6 col-sm-6 justify-content-start">
          <p>{{ address.recipient }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 col-sm-6 d-flex flex-column d-none d-md-block">
          <!-- <p></p> -->
        </div>
        <div>
          <!-- <p></p> -->
        </div>
        <div class="col-lg-6 col-sm-6 justify-content-start">
          <p>+62{{ address.phone }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 col-sm-6 d-flex flex-column d-none d-md-block">
          <!-- <p></p> -->
        </div>
        <div>
          <!-- <p></p> -->
        </div>
        <div class="col-lg-6 col-sm-6 justify-content-start">
          <p>{{ address.address_input }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 col-sm-6 d-flex flex-column d-none d-md-block">
          <!-- <p></p> -->
        </div>
        <div>
          <!-- <p></p> -->
        </div>
        <div class="col-lg-6 col-sm-6 justify-content-start">
          <p>
            {{ address.province }}, {{ address.district }}, ID
            {{ address.post_id }}
          </p>
        </div>
      </div>
      <mat-divider class="py-2"></mat-divider>
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <p>
            ONDELIVERY ({{ details.expedition_type }}) -
            <strong>Rp {{ priceTransform(details.expedition_price) }}</strong>
          </p>
        </div>
        <div class="col-lg-6 col-md-4">
          <div class="row justify-content-end" style="margin-right: 2px">
            <p>
              Total Belanja:
              <strong>Rp {{ priceTransform(details.final_price) }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="text-right" *ngIf="!loadingMode && buttonAppear">
  <button
    (click)="cancelOrder()"
    type="button"
    class="btn btn-checkout m-1"
    style="background: #b6244f; margin: 15px 15px 0px 0px !important"
  >
    Cancel Order
  </button>
  <button
    (click)="payOrder()"
    type="button"
    class="btn btn-checkout m-1"
    style="background: #3571b6; margin: 15px 0px 0px 0px !important"
  >
    Bayar Sekarang
  </button>
</div>
