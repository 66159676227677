<h4
  mat-dialog-title
  style="padding-top: 10px; text-align: center; color: white"
>
  {{title}}
</h4>
<h4 mat-dialog-title style="text-align: center; color: white">{{heading}}</h4>
<div class="d-flex flex-row-reverse" style="padding-top: 10px">
  <button
    class="login-button btn btn-lg"
    style="background-color: #3571b6; color: #262626"
    mat-button
    mat-dialog-close
    (click)="OK()"
  >
    Confirm
  </button>
</div>
