<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <app-whatsapp-corner></app-whatsapp-corner>
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <section id="blog" class="section blog-area mb-3 pb_50">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12 w-100 pt-2 ">
                        <mat-card class="mt-3">
                            <div class="custom-control custom-checkbox">
                                <span>
                                    <mat-checkbox class="w-100">
                                        {{task.name}}
                                    </mat-checkbox>
                                </span>
                            </div>
                            <hr>
                        </mat-card>
                    </div> -->

          <div class="col-12 w-100">
            <mat-card class="mt-3">
              <div class="custom-control custom-checkbox">
                <span>
                  <mat-checkbox
                    (change)="checkAll($event.checked)"
                    [checked]="allComplete"
                    [indeterminate]="allSelected()"
                  >
                    Pilih semua produk
                  </mat-checkbox>
                </span>
                <!-- <input type="checkbox" class="custom-control-input" id="store-check">
                                <label class="custom-control-label form-check-label pl-2" for="store-check">TOKO ONLINE SHOP</label> -->
              </div>
              <hr />
              <ng-template [ngIf]="total > 0">
                <div
                  class="col-12"
                  *ngFor="let cart of checklist.subChecklists; index as i"
                >
                  <!-- <div class="row d-flex flex-wrap py-3 col-12 w-100" > -->
                  <div
                    class="custom-control custom-checkbox align-items-middle d-flex flex-row py-3"
                  >
                    <!-- (change)="$event ? selection.toggle(cart.cart_id) : null" -->
                    <!-- (change)="onChecked($event)" -->
                    <!-- [checked]="selection.isSelected(cart.cart_id)" -->
                    <mat-checkbox
                      [(ngModel)]="cart.completed"
                      (ngModelChange)="selectAll()"
                      id="{{ cart.cart_id }}"
                      (change)="onChecked($event)"
                    >
                    </mat-checkbox>
                    <div class="d-flex flex-wrap flex-row col-12">
                      <div class="col-md-4 col-sm-12">
                        <div class="d-flex flex-row">
                          <div>
                            <a
                              href="/product?product_id={{
                                cart.product_variant.product.product_id
                              }}"
                            >
                              <img
                                class="image-costumer mr-3"
                                src="{{
                                  cart.product_variant.product.main_photo_url
                                }}"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="row m-0">
                            <a
                              href="/product?product_id={{
                                cart.product_variant.product.product_id
                              }}"
                            >
                              <p class="col-10" style="margin: 0px">
                                {{ cart.product_variant.product.name }}
                              </p>
                            </a>
                            <div
                              *ngIf="
                                cart.product_variant.variant_name != 'default'
                              "
                            >
                              <p class="col-12" style="margin: 0px">
                                Variasi :
                                {{ cart.product_variant.variant_name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 col-sm-12 align-self-center justify-content-center"
                      >
                        <div class="row">
                          <p class="ml-3 m-0 d-md-none d-sm-block">Harga :</p>
                          <p class="m-0">
                            Rp
                            {{
                              priceTransform(cart.product_variant.variant_price)
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-12 align-self-center">
                        <div class="d-flex flex-row">
                          <div class="exp flex-wrap flex-row">
                            <div class="exp">
                              <div
                                class="btn-varian"
                                type="button"
                                (click)="minus(i)"
                              >
                                <i class="fa fa-minus"></i>
                              </div>
                              <div class="form-check-inline">
                                <input
                                  type="number"
                                  class="form-quantities"
                                  id="{{ cart.product_variant.variant_id }}"
                                  [(ngModel)]="cart.quantity"
                                  (keyup)="onKeyUp($event)"
                                />
                              </div>
                              <div
                                class="btn-varian"
                                type="button"
                                (click)="plus(i)"
                              >
                                <i class="fa fa-plus"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 col-sm-10 align-self-center justify-content-center"
                      >
                        <button
                          class="btn"
                          [(ngModel)]="deleted"
                          id="{{ cart.product_variant.variant_id }}"
                          (click)="deleteFromCart($event)"
                        >
                          <span class="material-icons">delete</span> Hapus
                        </button>
                      </div>
                      <hr />
                    </div>
                    <!-- </label> -->
                  </div>

                  <!-- </div> -->
                </div>
              </ng-template>
              <ng-template [ngIf]="0 >= total">
                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <div>
                    <img
                      class="shopping-cart-img"
                      style="align-self: center"
                      src="/assets/img/shopping-basket1.png"
                    />
                  </div>
                  <!-- <div class="col-1">
                                        <a href="/cart" style="color: #13AAD4">View Cart</a>
                                    </div> -->
                  <!-- <div class="col-6">
                                        <button mat-raised-button style="color: #fff; background-color: #13AAD4;" class="m-1">
                                            Checkout
                                        </button>
                                    </div> -->
                </div>
                <div style="text-align: center">
                  <div style="color: #1d1d1d">
                    <br />
                    Keranjangmu kosong! Yuk belanja!
                  </div>
                </div>
              </ng-template>
            </mat-card>
          </div>
        </div>
      </div>
    </section>
    <div class="footer-cart">
      <div class="container">
        <div class="row p-3">
          <div class="col-6 pl-5 custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
            />
            <mat-checkbox
              (change)="checkAll($event.checked)"
              [checked]="allComplete"
              [indeterminate]="allSelected()"
              style="color: #3571b6"
              >Pilih semua produk</mat-checkbox
            >
          </div>
          <div class="col-6 d-flex align-items-end flex-column">
            <h4 id="total-cart" style="color: #3571b6">
              Total Harga: Rp {{ priceTransform(totalPrice) }}
            </h4>
            <a
              [ngClass]="{ isDisabled: !checked }"
              (click)="checkout()"
              class="btn m-1"
              style="background-color: #3571b6; color: #1d1d1d"
            >
              Checkout
            </a>
          </div>
        </div>
      </div>
    </div>
    <app-footer-one></app-footer-one>
  </div>
</div>
