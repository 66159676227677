<div class="homepage-3">
  <div class="main">
    <app-whatsapp-corner></app-whatsapp-corner>
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <app-full-banner></app-full-banner>
    <!-- <app-full-banner-demo></app-full-banner-demo> -->
    <app-category></app-category>
    <!-- <app-feature-tabs></app-feature-tabs> -->
    <!-- <app-feature-tabs-demo></app-feature-tabs-demo> -->
    <!-- <app-banner-section></app-banner-section> NO -->
    <app-product-recommendation></app-product-recommendation>
    <!-- <app-shop-by-collection></app-shop-by-collection> -->
    <app-promotion-slider></app-promotion-slider>
    <!-- <app-brand></app-brand> -->
    <app-highlight></app-highlight>
    <app-footer-one></app-footer-one>
  </div>
</div>
