import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  imageUrl: string = "https://usapi.onindonesia.id/get-profile-img/"+ localStorage.getItem("username")
  username: string
  //!highlioght kategori, mei shop ilangin
  constructor(
    private routes: Router,
    private service: ApiService,) { 
      this.service.userBasicProfile().subscribe(data => {
          this.username = data.username
          // console.log("DetailProfileComponent-UserProfile")
          // console.log(data)
        }, e => {
          console.log(e)
        })
    }

  ngOnInit(): void {
  }

  useDefaultProfileImg(){
    this.imageUrl = "assets/img/user-avatar.png";
  }

}
