<mat-dialog-content style="overflow: hidden !important;">
    <mat-card class="mat-elevation-z12">
        <mat-card-title style="text-align:center; padding-bottom: 2%;">
            <h3>Masuk</h3>
        </mat-card-title>
        <mat-card-content>
            <form class="mt-4" style="text-align: center;" [formGroup]="form" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline">
                    <input  matInput placeholder="Username" style="color: #262626;" required [formControl]="form.controls['username']">
                    <small *ngIf="form.controls['username'].hasError('required') && form.controls['username'].touched" class="text-danger support-text">Username is Required</small>
                    <small *ngIf="InvalidEmailPassword[0]" class="text-danger support-text"><br />Invalid Username/Email</small>
                    <small *ngIf="InvalidEmailPassword[2]" class="text-danger support-text"><br />Username is Disabled</small>
                    <mat-icon matSuffix style="color: #262626;">person</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Password" type="password" required minlength="6" matInput [type]="hide ? 'password' : 'text'" [formControl]="form.controls['password']">
                    <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" class="text-danger support-text">Password is Required</small>
                    <small *ngIf="InvalidEmailPassword[1]" class="text-danger support-text"><br />Invalid Password</small>
                    <span required="required" style="cursor: pointer;" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon style="font-size: 21px; padding-bottom: 30px; color: #262626;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                </mat-form-field>
                <small *ngIf="InvalidCredential" class="text-danger support-text"><br />Username / password salah</small>
                <!-- <mat-form-field appearance="outline">
                    <input  matInput placeholder="Phone" required [formControl]="form.controls['phone']">
                    <small *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched" class="text-danger support-text">Phone Number Required</small>
                    <small *ngIf="InvalidEmailPassword[0]" class="text-danger support-text"><br />Invalid Username/Email</small>
                    <small *ngIf="InvalidEmailPassword[2]" class="text-danger support-text"><br />Username is Disabled</small>
                    <mat-icon matSuffix>person</mat-icon>
                </mat-form-field>
                -->
                <button color="primary" class="login-button btn btn-lg w-100" type="submit" [disabled]="!form.valid">Sign In</button>              
            </form>
            <span style="font-size: 12px; margin-top: 5px;">
                    <a href="/reset" style="color: #B6244F;" >Forgot password?</a>
                </span>  
        </mat-card-content>
        <mat-card-content>
            <span style="font-size: 12px; margin-top: 5px; align-items: center;">
                Don't have an account? <a href="/signup" style="color: #B6244F;" >Daftar</a>
            </span>
        </mat-card-content>          
    </mat-card>
</mat-dialog-content>

