<div class="top d-none d-sm-block">
  <div class="container">
    <div class="row">
      <div class="col-xs-6">
        <div
          class="d-flex justify-content-start align-items-middle pl-2 pr-2 m-1"
        >
          <p class="pl-1 pr-4">
            <a class="gray" href="/about-us">
              <span class="material-icons pr-1">apartment</span>Tentang On
              Market
            </a>
          </p>
          <p class="pr-4">
            <a class="gray" href="/help">
              <span class="material-icons pr-1">contact_support</span>Bantuan
            </a>
          </p>
          <p class="pr-4">
            <a class="gray" href="#">
              <span class="material-icons pr-1">rate_review</span>Blog
            </a>
          </p>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="d-flex justify-content-end align-items-end pl-2 pr-2 m-1">
          <div class="pr-4">
            <p>
              <a
                class="gray"
                href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps"
                target="_blank"
                ><span class="material-icons pr-1">phone_iphone</span>Unduh
                Aplikasi
              </a>
            </p>
          </div>

          <div class="pr-2">
            <p>Find us on</p>
          </div>
          <div class="pr-2">
            <p>
              <a
                href="https://www.instagram.com/ondeliveryid/?hl=en"
                target="_blank"
                ><i class="fab fa-instagram fa-lg" style="color: #7e8085"></i
              ></a>
            </p>
          </div>
          <div class="pr-2">
            <p>
              <a href="https://www.facebook.com/Ondeliveryid" target="_blank"
                ><i class="fab fa-facebook fa-lg" style="color: #7e8085"></i
              ></a>
            </p>
          </div>
          <div class="pr-4">
            <p>
              <a
                href="https://www.youtube.com/@ondeliveryindonesia"
                target="_blank"
                ><i class="fab fa-youtube fa-lg" style="color: #7e8085"></i
              ></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
  <div class="container position-relative">
    <a class="navbar-brand" href="index.html">
      <img
        class="navbar-brand-regular"
        src="assets/img/logo-onmarket.png"
        alt="brand-logo"
        style="width: 200px"
      />
      <img
        class="navbar-brand-sticky"
        src="assets/img/logo-onmarket.png"
        alt="sticky brand-logo"
        style="width: 200px"
      />
    </a>

    <button
      class="navbar-toggler d-lg-none mx-5"
      id="mini-search-menu"
      type="button"
      data-toggle="navbarToggler"
      aria-label="Toggle navigation"
    >
      <i class="fa fa-search"></i>
    </button>

    <button
      class="navbar-toggler d-lg-none"
      type="button"
      data-toggle="navbarToggler"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <form class="form-inline my-2 w-50">
      <!-- <mat-form-field> -->
      <input
        matInput
        class="form-control"
        id="search-field"
        type="search"
        placeholder="Cari Barang..."
        aria-label="Search"
        [matAutocomplete]="auto"
        [formControl]="searchQuery"
        (keyup.enter)="search()"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option
            *ngFor="let option of filteredOptions"
            [value]="option.name"
            (click)="search()"
          >
            <span>{{ option.name }}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      <!-- </mat-form-field> -->
      <a
        mat-raised-button
        style="color: #1254a0"
        id="search-btn"
        (click)="search()"
        ><i class="fa fa-search"></i
      ></a>

      <br />

      <ng-container *ngIf="errorMsg">
        {{ errorMsg }}
      </ng-container>

      <!-- <mat-slide-toggle
            [checked]="searchQuery.disabled"
            (change)="searchQuery.disabled ? searchQuery.enable() : searchQuery.disable()">
        Disable Input?</mat-slide-toggle> -->
    </form>

    <div class="navbar-inner">
      <!--  Mobile Menu Toggler -->
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="navbarToggler"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <nav>
        <ul class="navbar-nav" id="navbar-nav">
          <li class="nav-item mb-3" id="mobile-menu-logo">
            <img
              class="navbar-brand-regular"
              src="assets/img/logo-onmarket.png"
              alt="brand-logo"
              style="width: 200px"
            />
          </li>
          <li class="nav-item">
            <form class="form-inline my-2 w-100">
              <input
                class="form-control mx-3"
                id="mini-search-field"
                type="search"
                placeholder="Cari Barang..."
                aria-label="Search"
                [matAutocomplete]="auto"
                [formControl]="searchQuery"
                (keyup.enter)="search()"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngIf="isLoading" class="is-loading"
                  >Loading...</mat-option
                >
                <ng-container *ngIf="!isLoading">
                  <mat-option
                    *ngFor="let option of filteredOptions"
                    [value]="option.name"
                    (click)="search()"
                  >
                    <span>{{ option.name }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
              <button
                mat-raised-button
                style="background-color: #1194b8"
                id="mini-search-btn"
                (click)="search()"
              >
                <i class="fa fa-search"></i>
              </button>

              <br />

              <ng-container *ngIf="errorMsg">
                {{ errorMsg }}
              </ng-container>
            </form>
          </li>
          <li class="nav-item">
            <button
              (click)="openDialog()"
              class="mx-1 btn btn-lg"
              *ngIf="!loggedin"
            >
              <span class="material-icons pr-1 text-white">person</span>
              Masuk/Daftar
            </button>

            <button
              *ngIf="loggedin"
              (click)="(false)"
              class="mx-1 btn btn-lg"
              [matMenuTriggerFor]="acc"
            >
              <span class="material-icons pr-1 text-white">person</span>
              Welcome {{ loggedinUser }}
            </button>
            <mat-menu #acc="matMenu">
              <a mat-menu-item href="/user" style="color: black">
                <span class="material-icons pr-1">contact_page</span>
                My Profile
              </a>
              <button (click)="logout()" mat-menu-item>
                <span class="material-icons pr-1">logout</span>
                Logout
              </button>
            </mat-menu>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="shopping-cart-menu" href="/cart">
              <span class="material-icons" style="font-size: 30px">
                shopping_cart
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>

<div class="top">
  <div class="container">
    <div class="mainmenu nav">
      <ul id="nav">
        <li class="menuborder">
          <a>Kategori <i class="fas fa-angle-down ml-1"></i></a>
          <ul>
            <li class="sub-sub-menu" *ngFor="let category of categories">
              <a
                href="/category?category_id={{ category.category_id }}"
                (click)="categoryProd(category)"
                >{{ category.name }}</a
              >
              <ul class="wrap">
                <li *ngFor="let children of category.children">
                  <a
                    href="/category?category_id={{ children.category_id }}"
                    (click)="categoryProd(children)"
                    >{{ children.name }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <!-- <li class='sub-sub-menu'><a href="#">Brand <i class="fas fa-angle-down  ml-1"></i></a>
                    <ul>
                        <li><a href="#">Xiaomi</a></li>
                        <li><a href="#">Samsung</a></li>
                        <li><a href="#">Wardah</a></li>
                    </ul>
                </li> -->
        <li><a href="#">Promo of The Day </a></li>
      </ul>
    </div>
  </div>
</div>

<!-- Shopping Cart -->
<div *ngIf="loggedin != false && total > 0; else elseBlock">
  <div class="container" id="shopping-cart-mini-container">
    <mat-card class="shopping-cart-mini">
      <div class="row">
        <div class="col-6">
          <a>Cart ({{ total }})</a>
        </div>
        <div class="col-6">
          <a href="/cart" style="color: #13aad4" class="float-right"
            >View Cart</a
          >
        </div>
      </div>

      <div *ngFor="let cart of carts">
        <hr />
        <a href="/cart">
          <div class="row">
            <div class="col-4">
              <img
                class="align-self-center shopping-cart-thumbnail"
                src="{{ cart.product_variant.product.main_photo_url }}"
                alt=""
              />
            </div>
            <hr />
            <div class="col-8">
              <div
                *ngIf="
                  cart.product_variant.variant_name != 'default';
                  else elseBlock
                "
              >
                <span class="shopping-cart-mini-title"
                  >{{ cart.product_variant.product.name }} -
                  {{ cart.product_variant.variant_name }}
                </span>
              </div>
              <ng-template #elseBlock>
                <span class="shopping-cart-mini-title">{{
                  cart.product_variant.product.name
                }}</span>
                <br />
                <br />
              </ng-template>
              <br />
              <span>x{{ cart.quantity }}</span
              >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <span
                >Rp
                {{ priceTransform(cart.product_variant.variant_price) }}</span
              >
            </div>
            <!-- <div class="col-2">
                            <a href="#">
                                <i class="fa fa-times"></i>
                            </a>
                        </div> -->
          </div>
        </a>
      </div>

      <!-- <div class="row m-2" id="shopping-cart-mini-total-price-container">
                <div class="col-6" id="shopping-cart-mini-total-text">
                    <span>Total Harga</span>
                </div>
                <div class="col-6">
                    <span>Rp xxx xxx</span>
                </div>
            </div> -->

      <!-- <div class="row" id="shopping-cart-mini-btn">
                <div class="col-6">
                    <a href="/cart" mat-raised-button style="color: #fff; background-color: #13AAD4;" class="m-1">
                        View Cart
                    </a>
                </div> -->
      <!-- <div class="col-6">
                    <button mat-raised-button style="color: #fff; background-color: #13AAD4;" class="m-1">
                        Checkout
                    </button>
                </div> -->
      <!-- </div> -->
    </mat-card>
  </div>
</div>
<ng-template #elseBlock>
  <div class="container" id="shopping-cart-mini-container">
    <mat-card class="shopping-cart-mini2">
      <div class="row justify-content-center">
        <div>
          <img
            class="shopping-cart-img"
            style="align-self: center"
            src="https://cdn-user-icons.flaticon.com/35844/35844091/1634267795089.svg?token=exp=1634268805~hmac=1ba26360158bcd077b960adbfc6dedaa"
          />
        </div>
        <div>
          <br />
          Keranjangmu kosong! Yuk belanja!
        </div>
        <!-- <div class="col-1">
                    <a href="/cart" style="color: #13AAD4">View Cart</a>
                </div> -->
        <!-- <div class="col-6">
                    <button mat-raised-button style="color: #fff; background-color: #13AAD4;" class="m-1">
                        Checkout
                    </button>
                </div> -->
      </div>
    </mat-card>
  </div>
</ng-template>
