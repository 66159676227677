<div class="contact-box bg-white rounded p-sm-5 mt-3 test">
    <div class="col-lg-12 col-sm-12 col-md-12 d-flex flex-row flex-wrap no-marpad mb-1">
        <h3>Voucher Game</h3>
        <div class="input-group">
            <div class="col-12 d-flex h-100 flex-row flex-wrap ptb_30">
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Combo SAKTI Unlimited 32 GB | 30 Hari</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 74.000</h5>
                    </div>
                </div>
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Special Ramadhan Harian Unlimited</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 12.000</h5>
                    </div>
                </div>
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Kuota Keluarga 25 GB | 30 Hari</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 150.000</h5>
                    </div>
                </div>
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Kuota Ketengan Utama 500MB | 1 Hari</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 3.500</h5>
                    </div>
                </div>
                <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="box p-20 text-center">
                        <h4 class="price m-0">Paket Langganan CloudX Conference 30GB | 30 Hari</h4>
                        <h5 class="absolute m-0 pt-3">Harga Rp. 250.000</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-box bg-white rounded p-sm-5 mt-3 test">
    <div class="col-lg-12 col-sm-12 col-md-12 d-flex flex-row flex-wrap no-marpad mb-1">
        <h3 class="pb-3">Pilihan Paket Data </h3>
    </div>

    <div class="d-flex flex-row justify-content-end pt_30">
        <div class="d-flex flex-column justify-content-end text-right mr-3">
            <h5 class="m-0">Total Bayar</h5>
            <h3 class="price">Rp.100.000</h3>
        </div>
        <div>
            <a href="/checkout-finish" class="btn">Beli</a>
        </div>
    </div>
</div>

<div class="contact-box bg-white rounded test mt-3 mb-5">
    <div class="p-3">
        <h3 class="text-center pb-2">Ketentuan Pembelian Voucher Game di On Market</h3>
        <p>On Market menyediakan jasa pembelian voucher game dari berbagai vendor.</p>
    </div>
</div>