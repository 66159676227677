<div class="contact-box bg-white rounded p-sm-5 mt-3 test">
    <div class="col-lg-12 col-sm-12 col-md-12 d-flex flex-row flex-wrap no-marpad mb-1">
        <h3>Isi Pulsa</h3>
        <div class="input-group">
            <input type="text" class="form-control" placeholder="Masukkan no telepon" aria-label="phone" aria-describedby="basic-addon1">
        </div>
    </div>

    <div class="d-flex flex-column justify-content-start ptb_30">
        <h4>Jenis Provider</h4>
        <img class="logo-provider" src="assets/img/provider/logo-telkomsel.png">
    </div>

    <div class="col-12 d-flex flex-row flex-wrap no-marpad">
        <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
            <div class="box 0p-20 text-center">
                <h4 class="price m-0">Rp. 10.000</h4>
                <h5 class="text-muted m-0">Harga Rp. 11.500</h5>
            </div>
        </div>
        <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
            <div class="box p-20 text-center">
                <h4 class="price m-0">Rp. 15.000</h4>
                <h5 class="text-muted m-0">Harga Rp. 16.500</h5>
            </div>
        </div>
        <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
            <div class="box p-20 text-center">
                <h4 class="price m-0">Rp. 25.000</h4>
                <h5 class="text-muted m-0">Harga Rp. 26.500</h5>
            </div>
        </div>
        <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
            <div class="box p-20 text-center">
                <h4 class="price m-0">Rp. 50.000</h4>
                <h5 class="text-muted m-0">Harga Rp. 56.500</h5>
            </div>
        </div>
        <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
            <div class="box p-20 text-center">
                <h4 class="price m-0">Rp. 75.000</h4>
                <h5 class="text-muted m-0">Harga Rp. 76.500</h5>
            </div>
        </div>
        <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
            <div class="box p-20 text-center">
                <h4 class="price m-0">Rp. 100.000</h4>
                <h5 class="text-muted m-0">Harga Rp. 100.000</h5>
            </div>
        </div>
        <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
            <div class="box p-20 text-center">
                <h4 class="price m-0">Rp. 150.000</h4>
                <h5 class="text-muted m-0">Harga Rp. 150.00</h5>
            </div>
        </div>
        <div fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100">
            <div class="box p-20 text-center">
                <h4 class="price m-0">Rp. 10.000</h4>
                <h5 class="text-muted m-0">Harga Rp. 11.500</h5>
            </div>
        </div>

    </div>

    <div class="d-flex flex-row justify-content-end pt_50">
        <div class="d-flex flex-column justify-content-end text-right mr-3">
            <h5 class="text-muted m-0">Total Bayar</h5>
            <h3 class="price">Rp.100.000</h3>
        </div>
        <div>
            <a href="/checkout-finish" class="btn">Beli</a>
        </div>
    </div>
</div>

<div class="contact-box bg-white rounded test mt-3">
    <div class="p-3">
        <h3 class="text-center pb-2">Ketentuan Pengisian Pulsa di On Market</h3>
        <p>On Market menyediakan jasa isi pulsa dengan bermacam nominal dan harga terjangkau. Kamu hanya perlu mengisi nomor telpon mu dan memilih nominal pulsa yang ingin diisi sesuai provider.</p>
    </div>
</div>

<div class="contact-box bg-white rounded test mt-3 mb-3">
    <div class="p-3">
        <h3 class="text-center pb-2">Provider yang tersedia</h3>
    </div>
    <div class="d-flex flex-row justify-content-center ptb_30">
        <img class="logo-provider" src="assets/img/provider/logo-telkomsel.png">
        <img class="logo-provider" src="assets/img/provider/logo-xl.png">
        <img class="logo-provider" src="assets/img/provider/logo-indosat.png">
        <img class="logo-provider" src="assets/img/provider/logo-axis.png">
    </div>
</div>