import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { DomSanitizer } from '@angular/platform-browser';
import { NumberFormatPipe, VoucherFormatPipe } from 'src/app/number.pipe';

interface voucherDetails {
  voucher_id: string;
  name: string;
  code: string;
  description: string;
  terms: string;
  expired_date: string;
  start_date: string;
  max_discount: number;
  min_price: number;
  image_url: string;
  type: string;
  quota: number;
  value: number;
}

@Component({
  selector: 'app-voucher-details',
  templateUrl: './voucher-details.component.html',
  styleUrls: ['./voucher-details.component.css'],
  providers: [NumberFormatPipe, VoucherFormatPipe]
})
export class VoucherDetailsComponent implements OnInit {
  @Output() claimVoucherEvent = new EventEmitter<string>();
  isLoading: boolean = false
  allowClaim: boolean = false
  // totalData: number = 0
  // pageEvent: PageEvent;
  // faTruckFast = faTruckFast
  voucher: voucherDetails = {
    voucher_id: "",
    name: "",
    code: "",
    description: "",
    terms: "",
    expired_date: "",
    start_date: "",
    max_discount: 0,
    min_price: 0,
    image_url: "",
    type: "",
    quota: 0,
    value: 0
  }

  constructor(
    public dialogRef: MatDialogRef<VoucherDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private currencyFormat: NumberFormatPipe,
    private voucherFormat: VoucherFormatPipe,
    // private sanitized: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    this.voucher = this.data.voucher

    if (this.data.voucher.min_price <= this.data.currentPrice) {
      this.allowClaim = true
    }

    // this.data.description = this.sanitized.bypassSecurityTrustHtml(this.data.description)
    // console.log('this.data')
    // console.log(this.data)
    this.isLoading = false
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  voucherTransform(value: number) {
    return this.voucherFormat.transform(value)
  }

  onSelectVoucher(voucher: any) {
    // console.log(voucher)
    if (voucher) {
      if (voucher.type === 'percentage') {
        voucher.title = voucher.value + "%"
      } else {
        voucher.title = "Rp " + this.voucherTransform(voucher.value)
      }
      this.dialogRef.close(voucher);
    } else {
      this.dialogRef.close(null);
    }
  }

}