import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute, ParamMap, Router, UrlSegment } from '@angular/router';
import { NumberFormatPipe } from '../../number.pipe';
import { DatePipe, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-personal-store',
  templateUrl: './personal-store.component.html',
  styleUrls: ['./personal-store.component.css'],
  providers: [Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }, NumberFormatPipe]
})

export class PersonalStoreComponent implements OnInit {
  // TODO pagination
  // TODO fix mini-sized best seller
  product: any
  bestSellers: any
  storeProducts: any
  storeInfos: any
  store_id: any
  reviews: any
  activeTab: any
  pageIndex: number = 0
  totalPageSize: any[] = []
  reviewPageIndex: number = 0
  reviewTotalPage: any[] = []
  params: ParamMap
  selectedIndex = 0

  constructor(
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    public datepipe: DatePipe,
    private router: Router,
    private location: Location

  ) {
    this.params = this.route.snapshot.queryParamMap
    this.pageIndex = 0
    this.store_id = this.params.get('store_id')
    this.service.storeInfo(this.store_id).subscribe(data => {
      this.storeInfos = data
      // console.log("PersonalStoreComponent-StoreInfo")
      // console.log(data)
    }, e => {
      console.log(e)
    })

    this.fetchReviews(this.store_id, this.reviewPageIndex)

    this.fetchstoreProduct(this.store_id, this.pageIndex)

    this.service.bestSeller(this.store_id).subscribe(data => {
      this.bestSellers = data.products
      // console.log("PersonalStoreComponent-bestseller")
      // console.log(data)
    }, e => {
      console.log(e)
    })

    this.navLinks = [
      {
        label: 'Profil Toko',
        link: 'store-profile',
        queryParams: {store_id: this.store_id},
        index: 0
      }, {
        label: 'Produk',
        link: 'store-product',
        queryParams: {store_id: this.store_id},
        index: 1
      }, {
        label: 'Ulasan',
        link: 'store-review',
        queryParams: {store_id: this.store_id},
        index: 2
      },
    ];

    this.selectedIndex = +this.location.path(true).substr(1) - 1 || 0;
    this.location.subscribe((value: any) => {
      if (value.url) {
        this.selectedIndex = +value.url.substr(1) - 1 || 0;
      }
    });
  }

  fetchReviews(storeId: number, pageIndex: number = 0) {
    this.service.reviewList(null, pageIndex, storeId).subscribe(data => {
      this.reviews = data
      this.reviewTotalPage = Array(Math.round(data.total / 10))
      // console.log("PersonalStoreComponent-Review")
      // console.log(data)
    }, e => {
      console.log(e)
    })
  }

  // public getPageUrl(pageIndex: number): Observable<string> {
  //   return this.route.url.pipe(
  //     map((segments: UrlSegment[]) => {
  //       return { params: segments, queryParams: {startIndex: pageIndex}}
  //     })
  //   )
  // }

  dateTransform(date) {
    return this.datepipe.transform(date, 'yyyy-MM-dd HH:mm');
  }

  fetchstoreProduct(store_id: number, pageIndex: number = 0) {
    // console.log(pageIndex)
    this.service.storeProduct(store_id, pageIndex).subscribe(data => {
      this.storeProducts = data.products
      this.totalPageSize = Array(Math.round(data.total / 24) + 1)
      // console.log("PersonalStoreComponent-storeProduct")
      // console.log(data)
    }, e => {
      console.log(e)
    })
  }

  arrowPagination(arg: string, number: number = 0) {
    if (arg === 'previous') {
      this.pageIndex = this.pageIndex - 1
    } else if (arg === 'next') {
      this.pageIndex = this.pageIndex + 1
    }
    this.fetchstoreProduct(this.store_id, this.pageIndex)
  }
  numberPagination(index: number = 0) {
    this.pageIndex = index
    this.fetchstoreProduct(this.store_id, this.pageIndex)
  }

  arrowPaginations(arg: string, number: number = 0) {
    if (arg === 'previous') {
      this.reviewPageIndex = this.reviewPageIndex - 1
    } else if (arg === 'next') {
      this.reviewPageIndex = this.reviewPageIndex + 1
    }
    this.fetchReviews(this.store_id, this.reviewPageIndex)
  }
  numberPaginations(index: number = 0) {
    this.reviewPageIndex = index
    this.fetchReviews(this.store_id, this.reviewPageIndex)
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }


  inputnumber = 0;

  plus() {
    this.inputnumber = this.inputnumber + 1;
  }
  minus() {
    if (this.inputnumber != 0) {
      this.inputnumber = this.inputnumber - 1;
    }
  }

  navLinks: any[];
  activeLinkIndex = -1;

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
  }

}
