import { FormGroup } from '@angular/forms'

export function clearLocalStorage (){
    localStorage.removeItem('username')
    localStorage.removeItem('jwt')
}

export const regionOptions = ['Jakarta', 'Bandung', 'Medan', 'Yogjakarya','Tangerang']

export function existValidation(form: FormGroup): {[key: string]: boolean } | null {
    return ((form.value.weight != null) || ((form.value.long != null) && (form.value.width != null) && (form.value.height != null)))? null: {'existValue': true}
  }