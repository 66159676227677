<div class="contact-box bg-white rounded p-sm-5 mt-3 test">
    <div class="col-lg-12 col-sm-12 col-md-12 d-flex flex-row flex-wrap no-marpad mb-1">
        <h3 class="pb-3">Bayar PDAM</h3>
        <div class="col-12 d-flex h-100 flex-row flex-wrap p-3">
            <div class="input-group mb-3">
                <select class="form-control" id="exampleFormControlSelect1">
                    <option>Plih Wilayah</option>
                    <option>Jakarta Pusat</option>
                    <option>Jakarta Utara</option>
                    <option>Jakarta Barat</option>
                    <option>Jakarta Timur</option>
                    <option>Jakarta Selatan</option>
                  </select>
            </div>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="No. Meter/ID Pelanggan" aria-label="phone" aria-describedby="basic-addon1">
            </div>
        </div>
    </div>

    <div class="d-flex flex-row justify-content-end pt_50">
        <div class="d-flex flex-column justify-content-end text-right mr-3">
            <h5 class="m-0">Total Bayar</h5>
            <h3 class="price">Rp.100.000</h3>
        </div>
        <div>
            <a href="/checkout-finish" class="btn">Beli</a>
        </div>
    </div>
</div>


<div class="contact-box bg-white rounded test mt-3 mb-3">
    <div class="p-3">
        <h3 class="text-center pb-2">Ketentuan Pembelian Token dan Pembayaran Listrik di On Market</h3>
        <p>On Market menyediakan jasa pembelian token listrik dan pembayaran listrik pasca bayar dengan harga terjangkau. Kamu hanya perlu mengisi nomor telpon mu dan memilih paket yang kamu inginkan.</p>
    </div>
</div>