<ng-template [ngIf]="!isLoading">
  <div class="p-2 pb-3">
    <div
      class="col-12 d-flex flex-row flex-wrap py-4 justify-content-end"
      style="padding: 0px"
    >
      <div class="col-lg-4 col-md-12 col-sm-12 align-items-center">
        <p>
          <i class="fas fa-map-marker-alt"></i><span class="ml-1"></span>Alamat
          Pengiriman
        </p>
      </div>
      <div
        class="col-lg-8 col-md-12 col-sm-12 d-flex flex-row justify-content-end align-items-center"
      >
        <!-- <div class="col-6">
                    <button class="btn" type="submit" ><i class="fas fa-plus"></i><span class="ml-1"></span>Tambah Alamat</button>
                </div> -->
        <div>
          <a href="/user/profile/address" class="btn" type="submit"
            ><i class="fas fa-tasks"></i><span class="ml-1"></span>Kelola
            Alamat</a
          >
        </div>
      </div>
    </div>
    <div *ngFor="let address of dataSource">
      <div *ngIf="address.address_id == address_id; else elseBlock">
        <div class="card card-active mb-3">
          <div class="card-body p-3">
            <div class="col-12 d-flex flex-row">
              <div class="col-8 d-flex flex-column">
                <div class="d-flex flex-column pt-2">
                  <div class="d-flex flex-row flex-wrap">
                    <p class="bold pr-2">{{ address.recipient }}</p>
                    <!-- Recipient -->
                    <p class="gray pr-2" style="margin: 0px; font-size: 11px">
                      ({{ address.category }})
                    </p>
                    <!-- Address Category -->
                    <ng-template [ngIf]="address.primary_address == true">
                      <p class="text-center highlight">Utama</p>
                    </ng-template>
                  </div>
                </div>
                <div class="d-flex flex-column py-1">
                  <p style="margin-bottom: 0px">(+62) {{ address.phone }}</p>
                  <!-- Phone Number -->
                  <p
                    class="gray"
                    style="
                      margin: 0px;
                      height: 22px;
                      width: 400px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ address.address_input }}
                  </p>
                  <!-- Inputted Address -->
                  <p
                    class="no-marpad"
                    style="
                      height: 22px;
                      width: 400px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ address.urban }}, {{ address.city }},
                    {{ address.province }}, ID {{ address.post_id }}
                  </p>
                  <!-- Kecamatan, Kota/Provinsi, Kode Pos -->
                </div>
              </div>
              <div
                class="col-lg-4 col-sm-12 d-flex flex-row flex-wrap justify-content-center align-items-center"
              >
                <div class="p-2" *ngIf="address.address_id != address_id">
                  <button
                    class="btn btn-action"
                    style="background: #3571b6 !important"
                    (click)="onSelectAddress(address.address_id)"
                  >
                    Pilih Alamat
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #elseBlock>
        <div class="card mb-2">
          <div class="card-body p-2">
            <div class="col-12 d-flex flex-row">
              <div class="col-8 d-flex flex-column">
                <div class="d-flex flex-column pt-2">
                  <div class="d-flex flex-row flex-wrap">
                    <p class="bold pr-2">{{ address.recipient }}</p>
                    <!-- Recipient -->
                    <p class="gray pr-2" style="margin: 0px; font-size: 11px">
                      ({{ address.category }})
                    </p>
                    <!-- Address Category -->
                    <ng-template [ngIf]="address.primary_address == true">
                      <p class="text-center highlight">Utama</p>
                    </ng-template>
                  </div>
                </div>
                <div class="d-flex flex-column py-1">
                  <p style="margin-bottom: 0px">(+62) {{ address.phone }}</p>
                  <!-- Phone Number -->
                  <p
                    class="gray"
                    style="
                      margin: 0px;
                      height: 22px;
                      width: 400px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ address.address_input }}
                  </p>
                  <!-- Inputted Address -->
                  <p
                    class="no-marpad"
                    style="
                      height: 22px;
                      width: 400px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ address.urban }}, {{ address.city }},
                    {{ address.province }}, ID {{ address.post_id }}
                  </p>
                  <!-- Kecamatan, Kota/Provinsi, Kode Pos -->
                </div>
              </div>
              <div
                class="col-lg-4 col-sm-12 d-flex flex-row flex-wrap justify-content-center align-items-center"
              >
                <div class="p-2" *ngIf="address.address_id != address_id">
                  <button
                    class="btn btn-action"
                    style="background: #3571b6 !important"
                    (click)="onSelectAddress(address.address_id)"
                  >
                    Pilih Alamat
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="isLoading">
  <div class="col ptb_50" style="text-align: center">
    <mat-spinner style="margin: 80px auto"></mat-spinner><br />
    <h4>Loading</h4>
  </div>
</ng-template>
