<div class="blog">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-whatsapp-corner></app-whatsapp-corner>
        <!-- <app-message-corner></app-message-corner> -->
        <app-main-header></app-main-header>
        <app-breadcrumb-blog-two-column></app-breadcrumb-blog-two-column>
        <app-counter></app-counter>
        <section class="section service-area overflow-hidden ptb_50">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6 order-2 order-lg-1">
                        <!-- Service Text -->
                        <div class="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                            <h2 class="text-capitalize mb-4">VISIONS</h2>
                            <!-- Service List -->
                            <ul class="service-list style-two">
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Our path is marked by a systematic and supported growth, resulting in
                                            certification of our SNI (Standart National Indonesia) in compliance with
                                            all requirements of the automotive & fabrication industry and since then, we
                                            have been consolidating our position in this market, both in Indonesia and
                                            abroad.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Consolidate ourselves as a reference company in the field of
                                            production with high-quality standards and supported by the certification of
                                            our skills.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Contribute to add value and support sustained growth of our
                                            customer.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <!-- <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Akses mudah diberikan kepada pelaku usaha untuk mengembangkan toko bersama kami. Untuk informasi lebih lanjut mengenai On Market dapat menghubungi narahubung kami. </p>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                        <!-- Service Thumb -->
                        <div class="service-thumb mx-auto">
                            <img src="assets/img/broken.s_192.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section counter-area ptb_50">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6 order-2 order-lg-1">
                        <!-- Service Thumb -->
                        <div class="service-thumb mx-auto">
                            <img src="assets/img/service_thumb_1.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                        <!-- Service Text -->
                        <div class="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                            <h2 class="text-capitalize mb-4">COMMITMENT</h2>
                            <!-- Service List -->
                            <ul class="service-list style-two">
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>To provide the highest quality product, we promise you to produce with our
                                            highly skilled and dedicated employees and produce a state-of-the-art and
                                            patented product, and manufacturing process performance. To provide the
                                            highest standards of service available through highly trained, domestic, and
                                            international service personnel.</p>
                                    </div>
                                </li>
                            </ul>
                            <h2 class="text-capitalize mb-4">OFFERS</h2>
                            <!-- Service List -->
                            <ul class="service-list style-two">
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>With years of experience, we are confident to be at your
                                            service.
                                            With the combination of good management, skillful, passionate, and agile work culture, we'll
                                            have a
                                            pleasant journey in achieving goals together while expanding new businesses of a
                                            fast-changing era
                                            on the global stage.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="section-heading text-center">
                        <h2 class="mb-5">OFFERS</h2>
                        <p class="about-desc mt-5 text-gray ">With years of experience, we are confident to be at your
                            service.
                            With the combination of good management, skillful, passionate, and agile work culture, we'll
                            have a
                            pleasant journey in achieving goals together while expanding new businesses of a
                            fast-changing era
                            on the global stage. </p>
                    </div> -->
                </div>
            </div>
        </section>

        <!-- <section id="blog" class="section blog-area">
            <div class="section-heading text-center">
                <h2 class="mb-5">OFFERS</h2>
                <p class="about-desc mt-5 text-gray ">With years of experience, we are confident to be at your service.
                    With the combination of good management, skillful, passionate, and agile work culture, we'll have a
                    pleasant journey in achieving goals together while expanding new businesses of a fast-changing era
                    on the global stage. </p>
            </div>
        </section> -->
        <app-footer-one></app-footer-one>
    </div>
</div>