import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';

export interface PaymentData {
  id_payment: number;
  card: string;
  card_name: string;
  card_number: number;
  card_exp_m: number;
  card_exp_y: number;
  card_cvv: number;
}
const paymentlist: PaymentData[] = [
  {
    id_payment: 1,
    card: 'Debit',
    card_name: 'Yura Yunita',
    card_number: 23587451421,
    card_exp_m: 4,
    card_exp_y: 2021,
    card_cvv: 223,
  }
];

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent {

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  // searchText: any;
  displayedColumns: string[] = ['card_information', 'action'];


  dataSource = paymentlist;


  constructor(public dialog: MatDialog) { }


  openDialog(action, obj) {
    obj.action = action;

    const dialogRef = this.dialog.open(AddPaymentDialog, {
      data: obj,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Tambah') {
        this.addRowData(result.data);
      } else if (result.event === 'Ubah') {
        this.updateRowData(result.data);
      } else if (result.event === 'Hapus') {
        this.deleteRowData(result.data);
      }
    });
  }

  addRowData(row_obj) {
    this.dataSource.push({
      id_payment: paymentlist.length + 1,
      card: row_obj.card,
      card_name: row_obj.card_name,
      card_number: row_obj.card_number,
      card_exp_m: row_obj.card_exp_m,
      card_exp_y: row_obj.card_exp_y,
      card_cvv: row_obj.card_cvv,
    });

    this.table.renderRows();

  }

  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value) => {
      if (value.id_payment === row_obj.id_payment) {
        value.card = row_obj.card;
        value.card_name = row_obj.card_name;
        value.card_number = row_obj.card_number;
        value.card_exp_m = row_obj.card_exp_m;
        value.card_exp_y = row_obj.card_exp_y;
        value.card_cvv = row_obj.card_cvv;
      }
      return true;
    });
  }

  deleteRowData(row_obj: PaymentData) {
    this.dataSource = this.dataSource.filter((value) => {
      return value.id_payment !== row_obj.id_payment;
    });
  }
}

@Component({
  selector: 'add-payment-dialog',
  templateUrl: 'add-payment-dialog.html',
  styleUrls: ['./payment.component.css']
})
export class AddPaymentDialog {

  action: string;
  local_data: any;

  constructor(
    public dialogRef: MatDialogRef<AddPaymentDialog>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PaymentData) {

    // console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
