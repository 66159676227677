import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-address',
  templateUrl: './confirm-address.component.html',
  styleUrls: ['./confirm-address.component.scss']
})
export class ConfirmAddressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@Component({
  selector: 'app-confirm-address',
  templateUrl: './confirm-edit-address.component.html',
  styleUrls: ['./confirm-address.component.scss']
})
export class ConfirmEditAddressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@Component({
  selector: 'app-confirm-address',
  templateUrl: './confirm-delete-address.component.html',
  styleUrls: ['./confirm-address.component.scss']
})
export class ConfirmDeleteAddressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
