import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/api.service';

interface ProvinceData {
  city_id: number
  province_id: number
  sub_district_id: number
  urban_id: number
  city_name: string
  country: string
  country_id: number
  id: number
  postal_code: number
  province_name: string
  sub_district_name: string
  urban_name: string
}

interface AddressData {
  address_id: number;
  primary_address: boolean;
  recipient: string;
  phone: string;
  category: string;
  city: string;
  address_input: string;
  province: string;
  district: string;
  post_id: number;
  urban: string;
  osas_log_id: number
  address: ProvinceData;
}

interface FilterObj {
  // field: string
  keyword: any
}

interface QueryTable {
  sort: null | Object
  filters: FilterObj[]
  pageSize: number
  pageIndex: number
}

@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.component.html',
  styleUrls: ['./select-address.component.css']
})

export class SelectAddressComponent implements OnInit {
  @Output() selectAddressEvent = new EventEmitter<string>();
  listOfData: AddressData[] = []
  isLoading: boolean = false
  totalData: number = 0
  pageEvent: PageEvent;

  dataSource = new MatTableDataSource(this.listOfData);

  constructor(
    public dialogRef: MatDialogRef<SelectAddressComponent>,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private service: ApiService) { }
    
    @Input() address_id: string

    ngOnInit(): void {
      this.address_id = this.data
      this.fetchAddress()
      // console.log(this.data)
  }

  onSelectAddress(address_id: string) {
    // console.log(address_id)
    this.dialogRef.close(address_id);
  }

  fetchAddress() {
    this.isLoading = true
    this.service.listUserAddress(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      for (let i = 0; i < r.addresses.length; i++) {
        r.addresses[i].phone = r.addresses[i].phone.substring(1)
      }
      this.dataSource = r.addresses
      this.totalData = r.total
      // console.log('this.fetchAddress')
      // console.log(this.dataSource)
    })
  }

  pageSize: number = 10
  pageIndex: number = 0

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex,
  }
  
  onQueryParamsChange(params): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = this.pageEvent.pageSize
    this.queryTable.pageIndex = this.pageEvent.pageIndex
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchAddress()
  }

}
