import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import { NumberFormatPipe } from "src/app/number.pipe";

@Component({
  selector: "app-checkout-confirmation",
  templateUrl: "./checkout-confirmation.component.html",
  styleUrls: ["./checkout-confirmation.component.css"],
})
export class CheckoutConfirmationComponent implements OnInit {
  paymentUrl: string;
  orderSummary: {
    orderInfo: [
      {
        product_name: string;
        variant_name: string;
        quantity: number;
        variant_price: number;
        subtotal: number;
      }
    ];
    service_name: string;
    delivery_fee: number;
    overall_price: number;
    final_price: number;
    insurance_exist: boolean;
    insurance_fee: number;
    voucher_id: number;
    total_discount: number;
  };
  invoice: string;
  expiryDate: string;
  invoiceDetails: any;

  pipe = new DatePipe("en-US");
  interval: any;

  constructor(
    // private snackBar: MatSnackBar,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    private routes: Router
  ) {}

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  ngOnInit(): void {
    if (
      history.state.orderSummary != null ||
      history.state.orderSummary != undefined
    ) {
      this.orderSummary = history.state.orderSummary[0];
      if (this.orderSummary.voucher_id) {
        this.orderSummary.total_discount =
          this.orderSummary.overall_price - this.orderSummary.final_price;
      }
      this.paymentUrl = history.state.payment_url;
      this.invoice = history.state.invoice;
      // console.log("orderSummary")
      // console.log(history.state.orderSummary)

      this.checkInvoice();
      this.interval = setInterval(() => {
        this.checkInvoice();
      }, 10000);
    } else {
      // this.routes.navigateByUrl('/**')
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  checkInvoice() {
    this.service.invoiceDetail(this.invoice).subscribe((data) => {
      console.log(data);
      this.expiryDate = this.pipe.transform(
        data.expiry_date,
        "EEEE, d MMMM y, H:mm"
      );

      if (data.status === "PAID" || data.status === "SETTLED") {
        this.routes.navigateByUrl("/user/order");
      }
    });
  }

  copyToClipboard(item) {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }

  makeOrder() {
    window.open(this.paymentUrl);
  }
}
