import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-samsat',
  templateUrl: './samsat.component.html',
  styleUrls: ['./samsat.component.css']
})
export class SamsatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
