import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { NumberFormatPipe } from 'src/app/number.pipe';
import { AddAddress } from '../accounts/profile/myprofile/address/address.component';
import { ConfirmAddressComponent } from '../accounts/profile/myprofile/address/confirm-address/confirm-address.component';
import { SelectAddressComponent } from './select-address/select-address.component';
import { faBasketShopping, faTicket } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectServiceComponent } from './select-service/select-service/select-service.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectVoucherComponent } from './select-voucher/select-voucher.component';
import { DialogAlert } from '../accounts/profile/myprofile/detail-profile/detail-profile.component';

declare var popOverOnClick: any;

interface Expedition {
  value: string;
  viewValue: string;
}

// interface Payment {
//   value: string;
//   viewValue: string;
// }

interface ProvinceData {
  city_id: number
  province_id: number
  sub_district_id: number
  urban_id: number
  city_name: string
  country: string
  country_id: number
  id: number
  postal_code: number
  province_name: string
  sub_district_name: string
  urban_name: string
}

interface AddressData {
  address_id: string;
  primary_address: boolean;
  recipient: string;
  phone: string;
  category: string;
  city: string;
  address_input: string;
  province: string;
  district: string;
  post_id: number;
  urban: string;
  osas_log_id: number
  address: ProvinceData;
}

interface ServiceData {
  cod: boolean;
  desc: string;
  eta: string;
  maxKg: number;
  minKg: number;
  multiplier: number;
  name: string;
  price: number;
  serviceId: number;
  total: number
}

interface FilterObj {
  field: string
  keyword: any
}

interface QueryTable {
  sort: null | Object
  filters: FilterObj[]
  pageSize: number
  pageIndex: number
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  listOfData: AddressData[] = []
  isLoading: boolean = false
  pageEvent: PageEvent;
  primaryAddress: AddressData
  dataSource = new MatTableDataSource(this.listOfData);
  selectedAddressId: string
  selectedServiceId: string;
  deliveryPrice: number = 10000
  faBasketShopping = faBasketShopping
  faTicket = faTicket
  orderInfo: any[];
  productTotalPrice: number = 0
  overallPrice: number = 0
  checkFeeParam = {
    originId: 0,
    destinationId: 0,
    weight: 0,
    dimension: {
      length: 0,
      height: 0,
      width: 0
    }
  }
  services: ServiceData[] = [];
  vouchers = new Array();
  productDetails: any;
  form: FormGroup;
  newVouchers: any;
  testDD: any;
  voucherId: any;
  voucherName: string;
  voucherCode: string;
  insuranceUsed: boolean = false;
  insuranceFee: number = 0;
  discountedPrice: number = 0;

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  message: any;

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private routes: Router,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      notes: [null]
    })
  }

   ngOnInit() {
    //  if (localStorage.getItem('refreshed') === null) {
    //    localStorage['refreshed'] = true;
    //    this.routes.navigateByUrl("/cart")
    //  } else {
    //    localStorage.removeItem('refreshed');
    //  }

    this.fetchAddress()
    this.fetchVouchers()

    if ((history.state.orderInfo != null) || (history.state.orderInfo != undefined)) {
      this.isLoading = true
      this.orderInfo = history.state.orderInfo
      // console.log(this.orderInfo)
      // console.log(history.state.orderInfo, 'order info')
      this.voucherId = history.state.orderInfo[0].voucher_id
      this.voucherCode = history.state.orderInfo[0].voucher_code
      this.voucherName = history.state.orderInfo[0].voucher_name

      for (let i = 0; i < history.state.orderInfo.length; i++) {
        this.productTotalPrice = this.productTotalPrice + history.state.orderInfo[i].subtotal
        this.service.productDetail(history.state.orderInfo[i].product_id).subscribe((r) => {
          // console.log(r.vouchers, 'vouchss')
          this.productDetails = r
          if (!r.weight || r.weight === 0) {
            r.weight = 1
          }
          if (!r.length || r.length === 0) {
            r.length = 1
          }
          if (!r.breadth || r.breadth === 0) {
            r.breadth = 1
          }
          if (!r.height || r.height === 0) {
            r.height = 1
          }
          
          this.service.userAddressDetail(r.store.address_id).subscribe((address) => {
            // console.log(address)
            this.checkFeeParam = {
              originId: address.osas_log_id,
              destinationId: null,
              weight: this.checkFeeParam.weight + r.weight,
              dimension: {
                length: this.checkFeeParam.dimension.length + r.length,
                width: this.checkFeeParam.dimension.width + r.breadth,
                height: this.checkFeeParam.dimension.height + r.height,
              }
            }
            // console.log(this.checkFeeParam)

            this.fetchAddress()
            this.isLoading = false
          })
        })
      }

      this.calcDiscount(this.voucherCode)
    } else {
      this.routes.navigateByUrl("/cart")
      // this.routes.navigateByUrl("/**")
    }
    // this.overallPrice = this.productTotalPrice + this.deliveryPrice
    // console.log(this.productTotalPrice)
    // console.log(this.orderInfo)
    // console.log()
    // console.log(this.vouchers, 'vouchers')
    // console.log(this.vouchers[0][0], 'vouchers')

    // console.log(this.vouchers.length, 'vouchers')

  }

  fetchAddress() {
    this.isLoading = true
    this.service.listUserAddress(this.queryTable).subscribe((r: any) => {
      // this.isLoading = false
      // console.log('this.fetchAddress')
      // console.log(r.addresses)
      // if (r.addresses.length > 0) {
        if (r.addresses.length > 1) {
          for (let i = 0; i < r.addresses.length; i++) {
            if (r.addresses[i].primary_address == true) {
              this.primaryAddress = r.addresses[i]
            }
          }
          if (this.primaryAddress === null || this.primaryAddress === undefined) {
            this.primaryAddress = r.addresses[0]
          }
        } else {
          this.primaryAddress = r.addresses[0]
        }
        if ((this.selectedAddressId === null) || (this.selectedAddressId === undefined)) {
          this.selectedAddressId = this.primaryAddress.address_id
        }
        this.primaryAddress.phone = this.primaryAddress.phone.substring(1)
        // console.log(this.primaryAddress)
        this.checkFeeParam = {
          originId: this.checkFeeParam.originId,
          destinationId: this.primaryAddress.address.id,
          weight: this.checkFeeParam.weight,
          dimension: {
            length: this.checkFeeParam.dimension.length,
            width: this.checkFeeParam.dimension.width,
            height: this.checkFeeParam.dimension.height
          }
        }
        this.service.checkDeliveryFee(this.checkFeeParam).subscribe((res) => {
          // console.log(res)
          this.services = []
          res.services.map((service: ServiceData) => {
            // console.log(service)
            if ((service.total !== 0) && (service.serviceId !== 101)) {
              this.services.push(service)
            }
          })
          // this.services = res.services
          this.deliveryPrice = this.services[0].total
          this.selectedServiceId = (this.services[0].serviceId).toString()
          this.selectedExpedition = this.services[0].name
          this.overallPrice = this.productTotalPrice + this.deliveryPrice
          this.isLoading = false
        })
      // }
      // console.log(this.checkFeeParam)
    })
  }

  voucherList: any

  fetchVouchers() {
    // this.isLoading = true
    this.service.voucherList().subscribe(data => {
      // console.log(data)
      this.voucherList = data
      // this.isLoading = false
    }, e => {
      console.log(e)
      // this.isLoading = false
    })
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  openDialog(action: string, obj: any) {
    debugger;
    obj.action = action;

    const dialogRef = this.dialog.open(AddAddress, {
      data: obj,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Tambah') {
        this.addRowData(result.data);
      }
    });
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'center'

    });
  }

  addRowData(row_obj: AddressData) {
    this.dataSource.data = []

    this.dataSource.data.push({
      address_id: String(this.listOfData.length + 1),
      address: row_obj.address,
      recipient: row_obj.recipient,
      phone: row_obj.phone,
      category: row_obj.category,
      province: row_obj.address.province_name,
      district: row_obj.address.sub_district_name,
      post_id: row_obj.address.postal_code,
      address_input: row_obj.address_input,
      primary_address: row_obj.primary_address,
      city: row_obj.address.city_name,
      urban: row_obj.address.urban_name,
      osas_log_id: row_obj.address.id
    });

    this.service.addUserAddress(this.dataSource.data, localStorage.getItem("jwt")).subscribe((res) => {
      this.dialog.open(ConfirmAddressComponent);
      // console.log("SUCCESS")
      this.fetchAddress()
      this.fetchVouchers()
    }, e => {
      console.log(e)
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Gagal menambah alamat. Mohon coba beberapa saat lagi.'
        }
      });
    })

    this.fetchAddress()
    this.fetchVouchers()
    this.table.renderRows();
  }

  pageSize: number = 10
  pageIndex: number = 0

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex,
  }

  // onQueryParamsChange(params): void {
  //   const { pageSize, pageIndex, sort, filter } = params
  //   this.queryTable.pageSize = this.pageEvent.pageSize
  //   this.queryTable.pageIndex = this.pageEvent.pageIndex
  //   const currentSort = sort.find(item => item.value !== null)
  //   const sortField = (currentSort && currentSort.key) || null
  //   const sortOrder = (currentSort && currentSort.value) || null

  //   if (sortField != null && sortOrder != null) {
  //     this.queryTable.sort = {
  //       field: sortField,
  //       order: sortOrder,
  //     }
  //   } else {
  //     this.queryTable.sort = null
  //   }
  //   this.fetchAddress()
  // }

  selectAddress(address_id: string) {
    let dialogRef = this.dialog.open(SelectAddressComponent, {
      width: '750px',
      data: address_id
    });

    dialogRef.afterClosed().subscribe(result => {
      this.selectedAddressId = result
      if ((result != null) || (result != undefined)) {
        this.queryTable.filters = [
          {
            field: "address_id",
            keyword: result
          }
        ]
        // console.log(this.queryTable.filters)
      } else {
        this.queryTable.filters = [
          {
            field: "address_id",
            keyword: address_id
          }
        ]
        // console.log(this.queryTable.filters)
      }
      this.fetchAddress()
      this.fetchVouchers()
      this.calcDiscount(this.voucherCode)
    })
  }

  selectedExpedition: string = 'Reguler';
  // expeditions: Expedition[] = [
  //   { value: 'ONDELIVERY', viewValue: 'ONDELIVERY' },
  //   {value: 'J&T', viewValue: 'J&T'},
  //   {value: 'JNE', viewValue: 'JNE'},
  //   {value: 'PT POS. Indonesia', viewValue: 'PT POS'}
  // ];

  // selectedPayment: string;
  // payments: Expedition[] = [
  //   {value: 'E-wallet/QRIS', viewValue: 'E-wallet/QRIS'},
  //   {value: 'Transfer Bank', viewValue: 'Transfer Bank'},
  //   {value: 'Kartu Kredit', viewValue: 'Kartu Kredit'},
  //   {value: 'Merchant', viewValue: 'Merchant'},
  // ];

  pickService() {
    if ((this.primaryAddress !== null) && (this.primaryAddress !== undefined)) {
      let dialogRef = this.dialog.open(SelectServiceComponent, {
        width: '500px',
        data: {
          services: this.services,
          selectedService: this.selectedServiceId
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        // console.log("RESULT HERE")
        // console.log(result)
        if ((result != null) && (result != undefined)) {
          this.selectedServiceId = result.serviceId
          this.selectedExpedition = result.name
          this.deliveryPrice = result.total
          this.overallPrice = this.productTotalPrice + this.deliveryPrice
        }
        // this.checkFeeParam = {
        //   originId: this.checkFeeParam.originId,
        //   destinationId: this.primaryAddress.address.id,
        //   weight: this.checkFeeParam.weight,
        //   dimension: {
        //     length: this.checkFeeParam.dimension.length,
        //     width: this.checkFeeParam.dimension.width,
        //     height: this.checkFeeParam.dimension.height
        //   }
        // }
        // this.service.checkDeliveryFee(this.checkFeeParam).subscribe((res) => {
        //   console.log(res)
        //   this.deliveryPrice = res.service_cost
        //   this.overallPrice = this.productTotalPrice + this.deliveryPrice
        // })
        // if (result != null) {
        //   this.queryTable.filters = [
        //     {
        //       field: "address_id",
        //       keyword: result
        //     }
        //   ]
        //   console.log(this.queryTable.filters)
        // } else {
        //   this.queryTable.filters = []
        // }
        // this.fetchAddress()
        this.fetchVouchers()
        this.calcDiscount(this.voucherCode)
      })
    } else {
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Mohon tambah alamat terlebih dahulu'
        }
      });
    }
  }

  pickVoucher() {
    // var newData = []
    // for ( const key of history.state.orderInfo) {
    //   this.service.productDetail(key.product_id).toPromise().then((r) => {
    //     for (const keyTwo of r.vouchers) {
    //       // console.log(keyTwo, 'leytwo')
    //         newData.push(keyTwo)
    //     }
    //       // console.log(r.vouchers, 'reas')
    //   })
    // }
    let dialogRef = this.dialog.open(SelectVoucherComponent, {
      width: '550px',
      data: {
        voucher: this.voucherList.vouchers,
        currentPrice: this.productTotalPrice,
        selectedVoucher: this.voucherId
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(voucher => {
      if (voucher) {
        // console.log(voucher)
        if (voucher.voucher.voucher_id !== this.voucherId) {
          this.calcDiscount(voucher.voucher.code)
        }
        this.voucherCode = voucher.voucher.code
        this.voucherId = voucher.voucher.voucher_id
        this.voucherName = voucher.voucherName
        // this.variantDetails.voucher_id = voucher.data
        this.openSnackBar('Voucher berhasil digunakan', 'OK')
      }
    });
    this.fetchVouchers()
  }

  calcDiscount(code: string) {
    if (code) {
      this.isLoading = true
      this.service.calculateDiscount(code, this.productTotalPrice).subscribe(r => {
        this.isLoading = false
        // console.log(r)
        this.discountedPrice = r.price_after_discount + this.deliveryPrice
      }, e => {
        console.log(e)
        this.dialog.open(DialogAlert, {
          width: '300px',
          data: {
            status: false,
            message: 'Terjadi kesalahan dalam checkout. Mohon coba beberapa saat lagi.'
          }
        });
      })
    }
  }

  checkCheckBoxvalue(event: any){
    this.insuranceUsed = event.checked
    if (event.checked) {
      this.service.insuranceCheckFee(this.selectedServiceId, this.productTotalPrice).subscribe(r => {
        // console.log(Math.floor(r.ins_cost))
        this.overallPrice = this.overallPrice + Math.floor(r.ins_cost)
        this.discountedPrice = this.discountedPrice + Math.floor(r.ins_cost)
        this.insuranceFee = Math.floor(r.ins_cost)
      }, e => {
        console.log(e)
        this.dialog.open(DialogAlert, {
          width: '300px',
          data: {
            status: false,
            message: 'Terjadi kesalahan dalam checkout. Mohon coba beberapa saat lagi.'
          }
        });
      })
    } else {
      this.service.insuranceCheckFee(this.selectedServiceId, this.productTotalPrice).subscribe(r => {
        // console.log(Math.floor(r.ins_cost))
        this.overallPrice = this.overallPrice - Math.floor(r.ins_cost)
        this.discountedPrice = this.discountedPrice - Math.floor(r.ins_cost)
        this.insuranceFee = 0
      }, e => {
        console.log(e)
        this.dialog.open(DialogAlert, {
          width: '300px',
          data: {
            status: false,
            message: 'Terjadi kesalahan dalam checkout. Mohon coba beberapa saat lagi.'
          }
        });
      })
    }
    // this.calcDiscount(this.voucherCode)
  }

  checkout() {
    var orderSummary = [{
      store_id: this.productDetails.store.store_id,
      message: this.form.value.notes,
      cart_id: [],
      variant_id: '',
      quantity: 0,
      delivery_fee: this.deliveryPrice,
      service_name: this.selectedExpedition,
      service_id: this.selectedServiceId,
      orderInfo: this.orderInfo,
      overall_price: this.overallPrice,
      final_price: this.discountedPrice,
      address_id: this.selectedAddressId,
      voucher_id: this.voucherId,
      insurance_exist: this.insuranceUsed,
      insurance_fee: this.insuranceFee
    }]

    for (let i = 0; i < this.orderInfo.length; i++) {
      orderSummary[0].cart_id.push(this.orderInfo[i].cart_id)
    }

    orderSummary[0].variant_id = this.orderInfo[0].variant_id
    orderSummary[0].quantity = this.orderInfo[0].quantity

    // console.log(orderSummary, 'ordr summ')
    // orderSummary.order_info = this.orderInfo
    if ((this.voucherCode !== null) && (this.voucherCode !== '') && (this.voucherCode !== undefined)) {
      this.service.redeemVoucher(this.voucherCode).subscribe(r => {
        this.service.xenditCheckout(orderSummary).subscribe(data => {
          // console.log(data)
          this.routes.navigateByUrl('/checkout-finish', { state: { orderSummary: orderSummary, invoice: data.invoice, payment_url: data.payment_url } })
        }, e => {
          this.message = e.error.message
          console.log(e)
          this.openSnackBar('Checkout gagal. Mohon coba beberapa saat lagi', '')
        })
      }, e => {
        this.message = e.error.message
        console.log(e)
        this.openSnackBar('Checkout gagal. Mohon coba beberapa saat lagi', '')
      })
    } else {
      this.service.xenditCheckout(orderSummary).subscribe(data => {
        // console.log(data)
        this.routes.navigateByUrl('/checkout-finish', { state: { orderSummary: orderSummary, invoice: data.invoice, payment_url: data.payment_url } })
      }, e => {
        this.message = e.error.message
        console.log(e)
        this.openSnackBar('Checkout gagal. Mohon coba beberapa saat lagi', '')
      })
    }
  }

}
