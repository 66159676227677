<div class="d-none d-lg-block"></div>
<footer class="footer-area height-emulator text-white">
  <!-- Footer Top -->
  <div
    class="footer-top pt-2 pb-5"
    style="margin-top: -20px; margin-bottom: -40px"
  >
    <div class="container mt-5">
      <div class="row d-flex">
        <div class="col-12 col-sm-6 col-lg-3 mb-5">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <h3 class="footer-title mb-3">ONMARKET</h3>
            <ul>
              <li class="py-1"><a href="/about-us">Tentang ONMARKET</a></li>
              <li class="py-1"><a href="/help">Bantuan</a></li>
              <li class="py-1"><a href="#">Berita dan Promo</a></li>
              <li class="py-1"><a href="#">FAQ</a></li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <h3 class="footer-title mb-3">LAYANAN USER</h3>
            <ul>
              <li class="py-1">
                <a href="https://seller.gigas.id/">Penjual</a>
              </li>
              <li class="py-1"><a href="">Pembeli</a></li>
              <!-- <li class="py-1"><a href="#">Mitra</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <ul class="service-list">
            <!-- Single Service -->
            <div class="footer-items">
              <h3 class="footer-title mb-3">INFO PERUSAHAAN</h3>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <span class="material-icons">location_city</span>
                </div>
                <div class="service-text media-body">
                  <p>
                    Rukan SCBR, Jalan Boulevard Raya Jalan Agung Sedayu City
                    No.2
                  </p>
                  <p>Cakung Barat, Kec. Cakung</p>
                  <p>Jakarta Timur, ID 14140</p>
                </div>
              </li>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <span class="material-icons">local_phone</span>
                </div>
                <div class="service-text media-body">
                  <p>(021) 50555007</p>
                </div>
              </li>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <span class="material-icons">email</span>
                </div>
                <div class="service-text media-body">
                  <a href="mailto:marketing@onindonesia.id">
                    marketing@onindonesia.id</a
                  >
                </div>
              </li>
            </div>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 mb-4 pl-5">
          <h3 class="footer-title">FOLLOW US</h3>
          <!-- Social Icons -->
          <div class="social-icons d-flex mb-3">
            <a
              href="https://www.instagram.com/ondeliveryid/?hl=en"
              target="_blank"
            >
              <i class="fab fa-instagram fa-lg mr-2"></i>
            </a>
            <a href="https://tiktok.com/@ondelivery.id" target="_blank">
              <i class="fab fa-tiktok fa-lg"></i>
            </a>
            <a href="https://www.facebook.com/Ondeliveryid" target="_blank">
              <i class="fab fa-facebook fa-lg"></i>
            </a>
            <a
              href="https://www.youtube.com/@ondeliveryindonesia"
              target="_blank"
            >
              <i class="fab fa-youtube fa-lg"></i>
            </a>
          </div>
          <!-- <p style="font-weight: bold;">DOWNLOAD APP</p> -->
          <!-- Store Buttons -->
          <!-- <div class="button-group store-buttons store-black d-flex flex-wrap">
                        <a href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps">
                            <img src="assets/img/google-play-black.png" alt="">
                        </a>
                        <a href="#">
                            <img style="height: 35.88;" src="assets/img/app-store-black.png" alt="">
                        </a>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Bottom -->
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Copyright Area -->
          <div
            class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4"
          >
            <!-- Copyright Left -->
            <div class="copyright-left">
              &copy; Copyrights 2023 ONMARKET. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
