import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hiburan',
  templateUrl: './hiburan.component.html',
  styleUrls: ['./hiburan.component.css']
})
export class HiburanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
