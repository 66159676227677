<div fxLayout="row wrap" class="py-3">
    <div fxFlex="100">
        <div class="table-responsive">
            <h3 class="profil-list pl-4">Profil Saya</h3>
            <form [formGroup]="basicProfileForm" (ngSubmit)="OnSubmit()">
                <table class="table no-wrap">
                    <td>
                        <div class="d-flex flex-row text-gray flex-wrap py-3">
                            <!-- <div class="pl-3">
                                    <button (click)="openDialog('Update',element)" class=" cursor-pointer" style="border: none; color: #13AAD4; background-color: transparent!important;">
                                        <i class="fas fa-edit"></i> Edit</button>
                                </div> -->

                            <div class="row col-md-5 col-sm-12 justify-content-center pb-3 text-center">
                                <div class="col-12">
                                    <img class="mat-card-avatar" [src]="imageUrl" (error)="useDefaultProfileImg()">
                                </div>
                                <div class="col-12">
                                    <button type="button" class="btn-avatar" (click)="Image.click()">
                                        <input type="file" (change)="handleFileInput($event.target.files)" accept="image/*" #Image
                                        formControlName="profile_img">
                                        <label style="cursor: pointer; margin: 0px;">
                                            Pilih Gambar
                                        </label>
                                    </button>
                                </div>
                                <small class="small" style="color: #262626;">Besar file maksimal 10MB dengan format ekstensi *.JPG *.JPEG
                                    *.PNG</small>
                            </div>

                            <div class="d-flex row col-md-7 col-sm-12 ">
                                <div class="col-12 d-flex flex-row profile-list">
                                    <div class="col-4 d-flex align-items-center" style="color: #262626;">
                                        Username
                                    </div>
                                    <div class="col-8 text-muted" style="color: #262626;">
                                        {{ userinfo.username }}
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-row profile-list">
                                    <div class="col-4 d-flex align-items-center" style="color: #262626;">
                                        Name
                                    </div>
                                    <div class="col-8">
                                        <mat-form-field appearance="outline" style="pointer-events: none;">
                                            <input matInput style="color: #262626;" type="text" value="{{userinfo.first_name}} {{userinfo.last_name}}"
                                            readonly>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-row profile-list">
                                    <div class="col-4 d-flex align-items-center" style="color: #262626;">
                                        Tanggal Lahir
                                    </div>
                                    <div class="col-8">
                                        <mat-form-field appearance="outline">
                                            <input matInput style="color: #262626;" [matDatepicker]="picker" type="text" value="{{userinfo.date_of_birth}}"
                                                formControlName="date_of_birth" (click)="picker.open()" class="clickable">
                                            <mat-datepicker style="color: #262626;" #picker></mat-datepicker>
                                            <mat-datepicker-toggle matSuffix [for]="picker" style="color: rgb(255, 255, 255);">
                                            </mat-datepicker-toggle>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-row profile-list">
                                    <div class="col-4 d-flex align-items-center" style="color: #262626;">
                                        Phone
                                    </div>
                                    <div class="col-8">
                                        <mat-form-field appearance="outline">
                                            <input matInput style="color: #262626;" type="text" value="{{userinfo.phone_number}}"
                                                formControlName="phone_number">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-row profile-list">
                                    <div class="col-4 d-flex align-items-center" style="color: #262626;">
                                        Email
                                    </div>
                                    <div class="col-8">
                                        <mat-form-field appearance="outline" style="pointer-events: none;">
                                            <input matInput style="color: #262626;" type="text" value="{{userinfo.email}}" readonly class="gray">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-row profile-list">
                                    <div class="col-4 d-flex align-items-center" style="color: #262626;">
                                        Gender
                                    </div>
                                    <div class="col-8">
                                        <mat-form-field appearance="outline">
                                            <mat-select matInput formControlName="gender" style="color: #262626;">
                                                <mat-option *ngFor="let gender of genders" [value]="gender">
                                                    {{ gender }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="pt-3 d-flex row col-12 justify-content-end">
                                    <!-- <button type="submit" class="btn" [disabled]="!basicProfileForm.valid">Submit</button> -->
                                    <button type="submit" class="btn">Submit</button>
                                </div>
                            </div>

                        </div>
                    </td>


                </table>
            </form>
        </div>
    </div>
</div>