import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ApiService } from 'src/app/api.service';
import { NumberFormatPipe } from '../../number.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DialogAlert } from '../accounts/profile/myprofile/detail-profile/detail-profile.component';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;
declare interface TableData {
  headerRow: string[];
  dataRows: string[][];
}

interface variantDetails {
  cart_id: number;
  product_id: number;
  product_name: string;
  variant_id: number;
  variant_name: string;
  quantity: number;
  variant_price: number;
  product_image: any;
  subtotal: number;
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  providers: [NumberFormatPipe, ApiService],
})
export class CartComponent implements OnInit {
  checklist = {
    name: 'Pilih semua produk',
    completed: false,
    subChecklists: []
  }

  totalPrice: number = 0
  total: any
  variantId: number | null = null
  deleted: any
  selection = new SelectionModel(true, [])
  storeID: any
  checked: boolean = false

  variantDetails = []

  constructor(
    private snackBar: MatSnackBar,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    private routes: Router,
    private dialog: MatDialog
  ) {
    this.service.cartList().subscribe(data => {
      this.checklist.subChecklists = data.carts
      this.total = data.total
      // console.log("CartComponentList-cartList")
      // console.log(data)
    }, e => {
      console.log(e)
    })

    this.service.getStoreId().subscribe(data => {
      this.storeID = data.store_id
      // console.log("CartComponentList-getStoreId")
      // console.log(data)
    }, e => {
      console.log(e)
    })
  }

  check() {
    this.service.login.next(true)
  }

  updateCart() {
    this.service.cartList().subscribe(data => {
      this.checklist.subChecklists = data.carts
      this.total = data.total
      // console.log("Cart-Component-List")
      // console.log(data)
    }, e => {
      console.log(e)
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Gagal mengubah keranjang. Mohon coba beberapa saat lagi.'
        }
      });
    })
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  updateQuantity() {
    this.service.cartList().subscribe(data => {
      this.checklist.subChecklists = data.carts
      this.total = data.total
      // console.log("Cart-Component-List")
      // console.log(data)
    }, e => {
      console.log(e)
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Gagal mengubah keranjang. Mohon coba beberapa saat lagi.'
        }
      });
    })
  }

  //////////////////

  public tableData3: TableData;

  ngOnInit() {
    // setInterval(()=>{console.log(this.selection);console.log(this.selection.selected)},1000)
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'center'

    });
  }

  onKeyUp(amount) {
    // console.log(amount)
    this.service.productDetail(this.checklist.subChecklists[amount].product_variant.product.product_id).subscribe(data => {
      for (let i = 0; i < data.product_variants.length; i++) {
        if ((data.product_variants[i].variant_id === this.checklist.subChecklists[amount].product_variant.variant_id) && (this.checklist.subChecklists[amount].quantity < data.product_variants[i].variant_stock)) {
          // console.log(data.product_variants[i].variant_stock)
          this.checklist.subChecklists[amount].quantity = this.checklist.subChecklists[amount].quantity + 1;
          var cart = []
          this.checklist.subChecklists.forEach(item => {
            cart.push(
              {
                quantity: item.quantity,
                variant_id: item.product_variant.variant_id
              }
            )
          });
          this.service.editCart(cart)
            .pipe(
              debounceTime(5000),
            )
            .subscribe(data => {

              // console.log("asdokaod")
              // console.log(data)
              // this.updateCart()
              this.service.isUpdateCart.next(true)

            }, e => {
              console.log(e)
              this.dialog.open(DialogAlert, {
                width: '300px',
                data: {
                  status: false,
                  message: 'Gagal mengubah keranjang. Mohon coba beberapa saat lagi.'
                }
              });
            })
        } else if (this.checklist.subChecklists[amount].quantity > 1) {
          this.checklist.subChecklists[amount].quantity = this.checklist.subChecklists[amount].quantity - 1;
          var cart = []
          this.checklist.subChecklists.forEach(item => {
            cart.push(
              {
                quantity: item.quantity,
                variant_id: item.product_variant.variant_id
              }
            )
          });
          this.service.editCart(cart)
            .pipe(
              debounceTime(5000),
            ).subscribe(data => {

              // console.log("asdokaod")
              // console.log(data)
              // this.updateCart()
              this.service.isUpdateCart.next(true)

            }, e => {
              console.log(e)
              this.dialog.open(DialogAlert, {
                width: '300px',
                data: {
                  status: false,
                  message: 'Gagal mengubah keranjang. Mohon coba beberapa saat lagi.'
                }
              });
            })
        }
      }
    })
  }

  deleteFromCart(event) {
    const variant_id = event.target.id
    // console.log(event.target.id)

    this.service.deleteCart(variant_id).subscribe(data => {
      // console.log("asdokaod")
      // console.log(data)
      this.updateCart()
      this.service.deletedFromCart.next(true)
    }, e => {
      console.log(e)
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Gagal menghapus produk dari keranjang. Mohon coba beberapa saat lagi.'
        }
      });
    })

    this.openSnackBar('Item berhasil dihapus dari keranjang', 'Ok')
  }

  getTotal() {
    let total = 0;
    for (let i = 0; i < this.tableData3.dataRows.length; i++) {
      const integer = parseInt(this.tableData3.dataRows[i][8], 10);
      total += integer;
    }
    return total;
  };

  plus(index: number) {
    // console.log(this.carts[index].product_variant.product.product_id)
    this.service.productDetail(this.checklist.subChecklists[index].product_variant.product.product_id).subscribe(data => {
      for (let i = 0; i < data.product_variants.length; i++) {
        if ((data.product_variants[i].variant_id === this.checklist.subChecklists[index].product_variant.variant_id) && (this.checklist.subChecklists[index].quantity < data.product_variants[i].variant_stock)) {
          // console.log(data.product_variants[i].variant_stock)
          this.checklist.subChecklists[index].quantity = this.checklist.subChecklists[index].quantity + 1;
          var cart = []
          this.checklist.subChecklists.forEach(item => {
            cart.push(
              {
                quantity: item.quantity,
                variant_id: item.product_variant.variant_id
              }
            )
          });
          this.service.editCart(cart)
            .pipe(
              debounceTime(5000),
            )
            .subscribe(data => {
              // console.log("asdokaod")
              // console.log(data)
              // this.updateCart()
              this.service.isUpdateCart.next(true)
            }, e => {
              console.log(e)
              this.dialog.open(DialogAlert, {
                width: '300px',
                data: {
                  status: false,
                  message: 'Gagal mengubah keranjang. Mohon coba beberapa saat lagi.'
                }
              });
            })
        }
      }
    })
  }
  minus(index: number) {
    if (this.checklist.subChecklists[index].quantity > 1) {
      this.checklist.subChecklists[index].quantity = this.checklist.subChecklists[index].quantity - 1;
      var cart = []
      this.checklist.subChecklists.forEach(item => {
        cart.push(
          {
            quantity: item.quantity,
            variant_id: item.product_variant.variant_id
          }
        )
      });
      this.service.editCart(cart)
        .pipe(
          debounceTime(5000),
        ).subscribe(data => {
          // console.log("asdokaod")
          // console.log(data)
          // this.updateCart()
          this.service.isUpdateCart.next(true)
        }, e => {
          console.log(e)
          this.dialog.open(DialogAlert, {
            width: '300px',
            data: {
              status: false,
              message: 'Gagal mengubah keranjang. Mohon coba beberapa saat lagi.'
            }
          });
        })
    }
  }

  checkout() {
    this.routes.navigateByUrl('/checkout', { state: { orderInfo: this.variantDetails } })
  }

  onChecked(event: MatCheckboxChange) {
    var detail: variantDetails = {
      cart_id: 0,
      product_id: 0,
      product_name: '',
      variant_id: 0,
      variant_name: '',
      quantity: 0,
      variant_price: 0,
      product_image: '',
      subtotal: 0
    }

    if (event.checked === true) {
      // console.log(event.source.id)
      for (let i = 0; i < this.checklist.subChecklists.length; i++) {
        if (this.checklist.subChecklists[i].cart_id === event.source.id) {
          detail.cart_id = this.checklist.subChecklists[i].cart_id
          detail.product_id = this.checklist.subChecklists[i].product_variant.product.product_id
          detail.product_name = this.checklist.subChecklists[i].product_variant.product.name
          detail.variant_id = this.checklist.subChecklists[i].product_variant.variant_id
          detail.variant_name = this.checklist.subChecklists[i].product_variant.variant_name
          detail.variant_price = this.checklist.subChecklists[i].product_variant.variant_price
          detail.subtotal = this.checklist.subChecklists[i].product_variant.variant_price * this.checklist.subChecklists[i].quantity
          detail.quantity = this.checklist.subChecklists[i].quantity
          detail.product_image = this.checklist.subChecklists[i].product_variant.product.main_photo_url

          this.totalPrice = this.totalPrice + detail.subtotal
          this.checked = true
        }
      }
      this.variantDetails.push(detail)
    } else {
      for (let j = 0; j < this.variantDetails.length; j++) {
        if (this.variantDetails[j].cart_id === event.source.id) {
          this.totalPrice = this.totalPrice - this.variantDetails[j].subtotal

          this.variantDetails.splice(j, 1)
        }
      }

      if (this.variantDetails.length <= 0) {
        this.checked = false
      }
    }
    // console.log(this.variantDetails)
  }

  allComplete: boolean = false;

  selectAll() {
    this.allComplete = this.checklist.subChecklists != null && this.checklist.subChecklists.every(t => t.completed);
  }

  allSelected(): boolean {
    if (this.checklist.subChecklists == null) {
      return false;
    }
    return this.checklist.subChecklists.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  checkAll(completed: boolean) {
    this.allComplete = completed;
    if (this.checklist.subChecklists == null) {
      return;
    }

    this.totalPrice = 0
    this.variantDetails = []

    this.checklist.subChecklists.forEach(t => (t.completed = completed));
    if (completed === true) {
      this.checklist.subChecklists.map((r) => {
        var cart_id = r.cart_id
        var product_id = r.product_variant.product.product_id
        var product_name = r.product_variant.product.name
        var variant_id = r.product_variant.variant_id
        var variant_name = r.product_variant.variant_name
        var variant_price = r.product_variant.variant_price
        var subtotal = r.product_variant.variant_price * r.quantity
        var quantity = r.quantity
        var product_image = r.product_variant.product.main_photo_url

        this.variantDetails.push({
          cart_id,
          product_id,
          product_name,
          variant_id,
          variant_name,
          variant_price,
          subtotal,
          quantity,
          product_image
        })

        this.totalPrice = this.totalPrice + subtotal
        this.checked = true
      })
    } else {
      this.variantDetails.splice(0, this.variantDetails.length)
      this.totalPrice = 0
      this.checked = false
    }
    // console.log(this.variantDetails)
  }
}
