import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promotion-slider',
  templateUrl: './promotion-slider.component.html',
  styleUrls: ['./promotion-slider.component.css']
})
export class PromotionSliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
