<section id="review" class="review-area py-4" style="margin-bottom: 120px">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="trending text-center py-3">Suggestions</h3>
      </div>
      <div
        class="highligt d-none d-md-block col-md-4 col-lg-3 col-xl-2 mb-3 pl-1 pr-1"
        *ngFor="let highlight of highlights"
      >
        <a href="/product?product_id={{ highlight.product_id }}">
          <mat-card class="content h-100" style="overflow: hidden">
            <img
              mat-card-image
              class="product-card-image"
              src="{{ highlight.main_photo_url }}"
              alt="product image"
            />
            <mat-card-content>
              <div>
                <p class="over">{{ highlight.name }}</p>
              </div>
              <div>
                <p class="bold" style="margin-bottom: 0px; font-size: 13px">
                  Rp {{ priceTransform(highlight.min_price) }}
                </p>
              </div>
            </mat-card-content>
            <mat-card-footer
              align="end"
              class="d-flex align-items-end flex-column"
            >
              <div class="mt-auto">
                <p class="desc">
                  <i class="marker fas fa-star"></i
                  ><i class="marker fas fa-star"></i
                  ><i class="marker fas fa-star"></i
                  ><i class="marker fas fa-star"></i
                  ><i class="marker fas fa-star"></i>
                </p>
              </div>
              <div class="mt-auto">
                <p class="desc">
                  <i class="marker fas fa-map-marker-alt"></i>
                  {{ highlight.city }}
                </p>
              </div>
            </mat-card-footer>
          </mat-card>
        </a>
      </div>
    </div>

    <mat-card
      class="d-block .d-sm-none d-md-none mb-3"
      *ngFor="let highlight of highlights"
    >
      <a href="/product?product_id={{ highlight.product_id }}">
        <div class="col-12">
          <div class="row d-flex flex-wrap">
            <div class="col-4 col-md-2 pl-0 pr-0">
              <img
                class="align-self-center thumbnail-cart"
                src="{{ highlight.main_photo_url }}"
                alt=""
              />
            </div>
            <div class="col-8 col-md-6">
              <p class="over">{{ highlight.name }}</p>
              <p class="bold">Rp {{ priceTransform(highlight.min_price) }}</p>
              <p class="desc text-right">
                <i class="marker fas fa-star"></i
                ><i class="marker fas fa-star"></i
                ><i class="marker fas fa-star"></i
                ><i class="marker fas fa-star"></i
                ><i class="marker fas fa-star"></i>
              </p>
              <p class="desc text-right">
                <i class="marker fas fa-map-marker-alt"></i>
                {{ highlight.city }}
              </p>
            </div>
          </div>
        </div>
      </a>
    </mat-card>
  </div>
</section>
