import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-all-bills',
  templateUrl: './all-bills.component.html',
  styleUrls: ['./all-bills.component.css']
})
export class AllBillsComponent {

  title = 'angular-material-tab-router';
  navLinks: any[];
  activeLinkIndex = -1;

  category: any

  constructor(public dialog: MatDialog) {
    this.category = [
      {
        "image": "assets/img/categories/pulsa.png",
        "link": "pulsa",
        "title": "Pulsa",
      }, {
        "image": "assets/img/categories/paket-data.png",
        "link": "paket-data",
        "title": "Paket Data",
      }, {
        "image": "assets/img/categories/listrik.png",
        "link": "pln",
        "title": "PLN",
      }, {
        "image": "assets/img/categories/game.png",
        "link": "game",
        "title": "Game",
      }, {
        "image": "assets/img/categories/bpjs.png",
        "link": "bpjs",
        "title": "BPJS",
      }, {
        "image": "assets/img/categories/pdam.png",
        "link": "pdam",
        "title": "PDAM",
      }, {
        "image": "assets/img/categories/samsat.png",
        "link": "samsat",
        "title": "e-Samsat",
      },{
        "image": "assets/img/categories/tiket.png",
        "link": "tiket-kereta",
        "title": "Tiket Kereta",
      },{
        "image": "assets/img/categories/tiket.png",
        "link": "tiket-pesawat",
        "title": "Tiket Pesawat",
      },{
        "image": "assets/img/categories/entertainment.png",
        "link": "hiburan",
        "title": "hiburan",
      },
    ]
  }

  openDialog() {
    this.dialog.open(AllBillsDialog, {
      height: '30%',
      width: '6 0%'
    });
  }
}


@Component({
  selector: 'all-bills-dialog',
  templateUrl: 'all-bills-dialog.html',
  styleUrls: ['./all-bills.component.css']
})
export class AllBillsDialog {


}
