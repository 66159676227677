import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { ApiService } from 'src/app/api.service';
import { NumberFormatPipe } from 'src/app/number.pipe';
import { DialogAlert } from '../../myprofile/detail-profile/detail-profile.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  details: any
  address: any
  subtotal: number = 0
  detailWaybillDisplay: boolean = false
  trackingDetail: [{}] = [{ status: 'Menunggu pembayaran dari pembeli.' }]
  seeDetails: boolean = false
  loadingMode: boolean = false
  invoiceDetail: any
  buttonAppear: boolean = false
  interval: any;

  constructor(
    public dialogRef: MatDialogRef<DetailComponent>,
    private dialog: MatDialog,
    private service: ApiService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private currencyFormat: NumberFormatPipe) {
    this.loadingMode = true
    // console.log(data)
    this.details = data

    if (data.voucher_id) {
      this.details.total_discount = data.total_price - data.final_price
      // this.service.voucherDetail(data.voucher_id).subscribe(r => {
      //   console.log(r, 'voucher')
      //   this.details.voucher_code = r.voucher.code
      // })
    }

    // console.log(this.details.snapshoot[0].product_snapshoot.process_status)

    for (let i = 0; i < this.details.snapshoot.length; i++) {
      this.details.snapshoot[i].product_snapshoot.subtotal = (this.details.snapshoot[i].product_snapshoot.price) * (this.details.snapshoot[i].product_snapshoot.quantity)
    }
    
    if (this.details.snapshoot[0].product_snapshoot.process_status === 'Belum Bayar') {
      this.trackingDetail.splice(0, 1)
      this.trackingDetail.push({ status: 'Menunggu pembayaran dari pembeli.', updated_at: this.details.created_at })
      this.buttonAppear = true
    }

    if (this.details.snapshoot[0].product_snapshoot.process_status === 'Dibatalkan') {
      this.trackingDetail.splice(0, 1)
      this.trackingDetail.push({ status: this.details.snapshoot[0].product_snapshoot.process_notes, updated_at: this.details.snapshoot[0].product_snapshoot.updated_at })
    }

    if (data.booking_number !== null) {
      this.service.bookingDetail(data.booking_number).subscribe(r => {
        this.details.booking = r
      })
    }

    this.service.userAddressDetail(this.details.address_id).subscribe((r) => {
      // console.log(r)
      this.address = r

      this.service.invoiceDetail(this.details.invoice).subscribe(invoiceData => {
        // console.log("INVOICE")
        // console.log(invoiceData)
        this.invoiceDetail = invoiceData
    
        if (this.details.snapshoot[0].product_snapshoot.process_status === 'Diproses') {
          this.trackingDetail.splice(0, 1)
          if (data.booking_number !== null) {
            this.trackingDetail.push({ status: 'Toko telah mengkonfirmasi pesanan. Pesananmu akan segera dikirim.', updated_at: dayjs(new Date(this.details.booking.package_info[0].createdAt)).format('D MMMM YYYY, HH:mm')})
            this.trackingDetail.push({ status: 'Pembayaran telah diterima. Menunggu konfirmasi dari toko.', updated_at: this.invoiceDetail.paid_at })
            this.trackingDetail.push({ status: 'Menunggu pembayaran dari pembeli.', updated_at: this.details.created_at })
          } else {
            this.trackingDetail.push({ status: 'Pembayaran telah diterima. Menunggu konfirmasi dari toko.', updated_at: this.invoiceDetail.paid_at })
            this.trackingDetail.push({ status: 'Menunggu pembayaran dari pembeli.', updated_at: this.details.created_at })
          }
        }
    
        if ((this.details.snapshoot[0].product_snapshoot.process_status === 'Dikirim') && (this.details.waybill_number !== null)) {
          this.trackingDetail.splice(0, 1)
          this.traceWaybillBasic()
        }
    
        if (this.details.snapshoot[0].product_snapshoot.process_status === 'Selesai') {
          this.trackingDetail.splice(0, 1)
          this.trackingDetail.push({ status: 'Transaksi telah selesai.', updated_at: this.details.snapshoot[0].product_snapshoot.updated_at })
          this.traceWaybillBasic()
        }

        this.loadingMode = false
      })
    })
  }

  traceWaybillBasic() {
    // if (!this.waybillForm.valid) {
    //   this.waybillForm.markAllAsTouched()
    //   return
    // }

    this.detailWaybillDisplay = false
    // this.waybillBasicTableDisplay = false
    this.loadingMode = true

    var query = (this.details.waybill_number).split(/[,\n\s+]+/)
    var newquery = []
    query.forEach(memberzz => {
      if (memberzz.length > 5) {
        newquery.push(memberzz)
      }
    });
    // console.log(query)
    this.service.waybillBasic(newquery).subscribe((r) => {
      var i = 1
      r.forEach((item) => {
        item.index = i
        i++
        item.waybill_number = item.waybill_number
        // console.log(item.waybill_number.substring(0,19))
      })

      // console.log(r)

      for (let i = 0; i < r[0].tracking_element.length; i++) {
        this.trackingDetail.push(r[0].tracking_element[i])
      }

      this.trackingDetail.push({ status: 'Pesanan dalam proses pengiriman.', updated_at: this.details.snapshoot[0].product_snapshoot.updated_at })
      this.trackingDetail.push({ status: 'Toko telah mengkonfirmasi pesanan. Pesananmu akan segera dikirim.', updated_at: dayjs(new Date(this.details.booking.package_info[0].createdAt)).format('D MMMM YYYY, HH:mm')})
      this.trackingDetail.push({ status: 'Pembayaran telah diterima. Menunggu konfirmasi dari toko.', updated_at: this.invoiceDetail.paid_at })
      this.trackingDetail.push({ status: 'Menunggu pembayaran dari pembeli.', updated_at: this.details.created_at })

      // console.log(this.trackingDetail)

      // console.log(this.dataSourceWaybillBasic); TGR308UDUS126900E

      this.loadingMode = false
      // this.waybillBasicTableDisplay = true
    }, (e) => {
      this.trackingDetail = [{ 'index': 'Not Found' }]

      this.loadingMode = false
      // this.waybillBasicTableDisplay = true
    })
  }

  formatDate(date: any) {
    return dayjs(date).format('DD MMMM YYYY, HH:mm WIB')
  }

  isLinear = false;

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  ngOnInit(): void {
  }

  toggleCollapse(): void {
    this.seeDetails = !this.seeDetails;
  }

  payOrder() {
    window.open(this.invoiceDetail.invoice_url)

    this.interval = setInterval(() => {
      this.service.invoiceDetail(this.details.invoice).subscribe(data => {  
        if (data.status === 'PAID') {
          this.dialogRef.close({ data: 'Paid' });
        }
      })
    }, 10000)
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  cancelOrder() {
    this.service.cancelInvoice(this.details.invoice).subscribe((r) => {
      // console.log(r)
      this.dialogRef.close({ data: 'Cancelled' });
    }, e => {
      console.log(e)
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Pesanan gagal dibatalkan. Mohon coba beberapa saat lagi.'
        }
      });
    })
  }

}
