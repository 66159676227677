<div class="p-4">
    <h3>{{action}} Informasi Kartu</h3>
    
    <mat-dialog-content class="pb-3" *ngIf="action != 'Hapus'; else elseTemplate">
        <form #userForm="ngForm" >
            <div class="form-group row align-items-center">
                <label class="col-sm-3 col-form-label">Jenis Kartu</label>
                <div class="col-sm-9">
                    <select class="form-control selectpicker" required id="card" name="card" [(ngModel)]="local_data.card" >
                        <option value="Debit">Debit</option>
                        <option value="Kredit">Kredit</option>
                    </select>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label class="col-sm-3 col-form-label">Nama Pemegang Kartu</label>
                <div class="col-sm-9">
                    <input class="form-control" type="text"  required id="card_name" name="card_name" [(ngModel)]="local_data.card_name" placeholder="Masukkan nama pemegang kartu">
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label class="col-sm-3 col-form-label">Nomor Kartu</label>
                <div class="col-sm-9">
                    <input class="form-control" type="number"  required id="card_number" name="card_number" [(ngModel)]="local_data.card_number" placeholder="Masukkan nomor kartu">
                </div>
            </div>
            
            
            <div class="form-group row align-items-center">
                <label class="col-sm-3 col-form-label">Masa Berlaku Kartu</label>
                <div class="col-sm-9">
                    <div class="d-flex flex-row">
                        <input class="form-control mr-2" style="width: 100px;" type="number"  required id="card_exp_m" name="card_exp_m" [(ngModel)]="local_data.card_exp_m" placeholder="MM" >
                        <input class="form-control mr-2" style="width: 100px;" type="number"  required id="card_exp_y" name="card_exp_y" [(ngModel)]="local_data.card_exp_y" placeholder="YYYY" >
                    </div>
                </div>
            </div>
            
            <div class="form-group row align-items-center">
                <label class="col-sm-3 col-form-label">CVV</label>
                <div class="col-sm-9 flex-row">                
                    <input class="form-control" style="width: 100px;" type="number"  required id="card_cvv" name="card_cvv" [(ngModel)]="local_data.card_cvv" placeholder="000" >
                    <small class="text-muted">*3 nomor terakhir pada bagian belakang kartu.</small>
                </div>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-end pt-3">
                <div class="p-2">
                    <button class="btn" style="background: #b6244f!important; color: white;" (click)="closeDialog()">Cancel</button>
                </div>
                <div class="p-2">
                    <button class="btn" (click)="doAction()" [disabled]="!userForm.valid">{{action}}</button>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</div>
<ng-template #elseTemplate>
    <p>Sure to delete <b>{{local_data.name}}</b> ?</p>
    <div mat-dialog-actions align="end" class="pt-3">
        <button mat-button (click)="doAction()" >{{action}}</button>
        <button mat-button (click)="closeDialog()">Cancel</button>
    </div>
</ng-template>