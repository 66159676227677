<div class="blog">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-whatsapp-corner></app-whatsapp-corner>
        <!-- <app-message-corner></app-message-corner> -->
        <app-main-header></app-main-header>
        <section id="blog" class="section blog-area  ptb_50">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <aside class="sidebar">
                            <div class="single-widget ">
                                <!-- Post Widget -->
                                <div class="accordions widget post-widget " id="post-accordion">
                                    <div class="single-accordion">
                                        <!-- Post Widget Content -->
                                        <div class="accordion-content widget-content shadow-lg" data-parent="#post-accordion">
                                            <!-- Post Widget Items -->
                                            <ul class="widget-items">
                                                <li>
                                                    <div class="single-post bg-white align-items-center media p-3">
                                                        <!-- Post Thumb -->
                                                        <div class="post-thumb avatar-md">
                                                            <img class="mat-card-avatar" [src]="imageUrl" (error)="useDefaultProfileImg()">
                                                        </div>
                                                        <div class="post-content media-body pl-3">
                                                            <h3 class="post-date mb-2">{{username}}</h3>
                                                            <h5>online</h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-widget">
                                <!-- Post Widget -->
                                <div class="accordions widget post-widget" id="post-accordion">
                                    <div class="single-accordion">
                                        <!-- Post Widget Content -->
                                        <div class="accordion-content widget-content shadow-lg" data-parent="#post-accordion">
                                            <!-- Post Widget Items -->
                                            <ul class="widget-items">
                                                <li>
                                                    <a routerLink="profile" routerLinkActive="active" class="single-post align-items-center align-items-lg-start media p-3">
                                                        <!-- Post Thumb -->
                                                        <div class="post-content media-body pl-3">
                                                            <p class="post-date mb-2">Profil</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerLink="order" routerLinkActive="active" class="single-post align-items-center align-items-lg-start media p-3">
                                                        <!-- Post Thumb -->
                                                        <div class="post-content media-body pl-3">
                                                            <p class="post-date mb-2">Pesanan</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                    <div class="col-12 col-lg-9">
                        <!-- Single Blog Details -->
                        <article class="single-blog-details">
                            <!-- Blog Content -->
                            <div class="blog-content sApp-blog">
                                <!-- Blog Details -->
                                <div class="blog-details ">
                                    <router-outlet></router-outlet>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
        <app-footer-one></app-footer-one>
    </div>
</div>