<div class="contact-box bg-white rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
    <mat-tab-group (selectedTabChange)="orderStatus($event)">
        <mat-tab label="Semua">
            <div *ngIf="orderTotal > 0; else elseBlock">
                <div class="py-3">
                    <div class="card mb-3" *ngFor="let order of orders">
                        <div class="card-body">
                            <div class="col-12 ">
                                <div class="d-flex flex-wrap flex-row pb-2">
                                    <div class="d-flex flex-column col-9" style="padding-left: 0px;">
                                        <div class="row col-12">
                                            <!-- <a href="/store/store-profile?store_id={{order.store.store_id}}">
                                                <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500;"><i class="fas fa-store"></i><span class="ml-2"></span>{{order.store.store_name}}</h4> 
                                            </a> -->
                                            <div class="mt-2"
                                                *ngIf="(((order.snapshoot_lists[0].product_snapshoot.process_status)) === 'Dibatalkan')">
                                                <p class="text-center highlight-cancel">
                                                    {{order.snapshoot_lists[0].product_snapshoot.process_status}}
                                                </p>
                                            </div>
                                            <div class="mt-2"
                                                *ngIf="(((order.snapshoot_lists[0].product_snapshoot.process_status)) === 'Selesai')">
                                                <p class="text-center highlight-done">
                                                    {{order.snapshoot_lists[0].product_snapshoot.process_status}}
                                                </p>
                                            </div>
                                            <div class="mt-2"
                                                *ngIf="(((order.snapshoot_lists[0].product_snapshoot.process_status)) === 'Diproses')">
                                                <p class="text-center highlight-onproses">
                                                    {{order.snapshoot_lists[0].product_snapshoot.process_status}}
                                                </p>
                                            </div>
                                            <div class="mt-2"
                                                *ngIf="(((order.snapshoot_lists[0].product_snapshoot.process_status)) === 'Belum Bayar')">
                                                <p class="text-center highlight-bb">
                                                    {{order.snapshoot_lists[0].product_snapshoot.process_status}}
                                                </p>
                                            </div>
                                            <div class="mt-2"
                                                *ngIf="(((order.snapshoot_lists[0].product_snapshoot.process_status)) === 'Dikirim')">
                                                <p class="text-center highlight-otw">
                                                    {{order.snapshoot_lists[0].product_snapshoot.process_status}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row col-12">
                                            <h5 class="gray" style="margin: 0px;">Waktu Checkout :
                                                <strong>{{dateTransform(order.created_at)}}</strong>
                                            </h5>
                                            <div class="separator"></div>
                                            <h5 class="gray" style="margin: 0px;">Invoice :
                                                <strong>{{order.invoice}}</strong>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row col-3 d-flex align-items-center justify-content-end"
                                        style="padding: 0px;">
                                        <a id={{order.invoice}} (click)="detail($event)"
                                            style="margin: 0px; color: #b6244f; font-weight: 500; cursor: pointer;">Detail
                                            Belanja</a>
                                    </div>
                                </div>

                                <mat-divider class="mt-3 mb-2"></mat-divider>

                                <div *ngFor="let product of order.snapshoot_lists">
                                    <div class="row d-flex flex-wrap pr-4 py-2">
                                        <div class="col-lg-1 col-md-1 col-sm-1" style="padding-right: 0px;">
                                            <img class="align-self-center pb-2" style="width: 100px;"
                                                src="{{product.product_snapshoot.photo}}" alt="">
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                            <h4 style="margin: 0px;">{{product.product_snapshoot.name}}</h4>
                                            <h5 class="text-muted">Variasi :
                                                {{product.product_snapshoot.variant_name}}</h5>
                                        </div>
                                        <div
                                            class="col-lg-3 col-md-3 col-sm-12 pr-2 mt-2 justify-align-right text-right">
                                            <p>Rp {{priceTransform(product.product_snapshoot.price)}}</p>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider class="py-2"></mat-divider>
                                <div class="row d-flex flex-wrap">
                                    <div class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-end">
                                        <div class="row" style="padding-bottom: 0px;">
                                            <p class="pr-2 align-item-middle">Total :</p>
                                            <h3 class="bold align-item-middle" style="color: #3c3c3c;">Rp
                                                {{priceTransform(order.final_price)}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card">
                        <div class="card-body pt-4">
                            <div class="col-12 ">
                                <div class="d-flex flex-wrap flex-row pb-2" >                                
                                    <div class="d-flex flex-column col-9" style="padding-left: 0px;">
                                        <div class="row col-12">
                                            <a href="/product">
                                                <h4 class="pr-2 pl-2" style="margin: 0px; font-weight: 500;"><i class="fas fa-store"></i><span class="ml-2"></span>Acc Official </h4> 
    
                                            </a>
                                            <p class="text-center highlight-done">Selesai</p>
                                        </div>
                                        <div class="row col-12">
                                            <h5 class="gray" style="margin: 0px;">Waktu Checkout : <strong> 10 Maret 2021</strong></h5>
                                            <div class="separator"></div>
                                            <h5 class="gray" style="margin: 0px;">Invoice : <strong> ON789</strong></h5>
                                        </div>
                                    </div>
                                    <div class="row col-3 d-flex align-items-center justify-content-end" style="padding: 0px;">
                                        <a href="#" style="margin: 0px; color: #b6244f; font-weight: 500;">Detail Belanja</a> 
                                    </div>
                                </div>
                                <mat-divider class="py-2"></mat-divider>
                                <div class="row d-flex flex-wrap pr-4 py-2">
                                    <div class="col-lg-1 col-md-1 col-sm-1" style="padding-right: 0px;">
                                        <img class="align-self-center pb-2" style="width: 100px;" src="assets/img/avatar-1.png" alt="">
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-6" >
                                        <h4 style="margin: 0px;">Standing Camera </h4> 
                                        <h5 class="text-muted">Variasi : Black x 2 Pcs</h5>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12 pr-2 justify-align-right text-right">
                                        <p>Rp 150.000</p>
                                    </div>
                                </div>
                                <mat-divider class="py-2"></mat-divider>
                                <div class="row d-flex flex-wrap pr-4 py-2">
                                    <div class="col-lg-1 col-md-1 col-sm-1" style="padding-right: 0px;">
                                        <img class="align-self-center pb-2" style="width: 100px;" src="assets/img/avatar-1.png" alt="">
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-6" >
                                        <h4 style="margin: 0px;">Case iPhone 7 </h4> 
                                        <h5 class="text-muted">Variasi : Flower x 1 Pcs</h5>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12 pr-2 justify-align-right text-right">
                                        <p>Rp 15.000</p>
                                    </div>
                                </div>
                                <mat-divider class="py-2"></mat-divider>
                                <div class="row d-flex flex-wrap">
                                    <div class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-end">
                                        <div class="row" style="padding-bottom: 0px;">
                                            <p class="pr-2">Total :</p>
                                            <h3 class="bold" style="color: #3c3c3c;" >Rp 315.000</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <mat-paginator #paginator (page)="handlePage($event)" [length]=orderTotal [pageSize]="5" aria-label="Select page"></mat-paginator>
                </div>
            </div>
            <ng-template #elseBlock>
                <div class="py-3">
                    <div class="card ">
                        <div class="card-body py-4">
                            <div class="col-12 d-flex flex-row flex-wrap text-center">
                                Belum ada pesanan
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Belum Bayar">
            <div *ngIf="orderTotal > 0; else elseBlock">
                <div class="py-3">
                    <div class="card mb-3" *ngFor="let process of orders">
                        <div class="card-body">
                            <div class="col-12 ">
                                <div class="d-flex flex-wrap flex-row pb-2">
                                    <div class="d-flex flex-column col-9" style="padding-left: 0px;">
                                        <div class="row col-12">
                                            <!-- <a href="/store/store-profile?store_id={{process.store.store_id}}">
                                                <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500;"><i
                                                        class="fas fa-store"></i><span
                                                        class="ml-2"></span>{{process.store.store_name}}</h4>
                                            </a> -->
                                            <div class="mt-2">
                                                <p class="text-center highlight-bb">
                                                    {{process.snapshoot_lists[0].product_snapshoot.process_status}}</p>
                                            </div>
                                        </div>
                                        <div class="row col-12">
                                            <h5 class="gray" style="margin: 0px;">Waktu Checkout :
                                                <strong>{{dateTransform(process.created_at)}}</strong>
                                            </h5>
                                            <div class="separator"></div>
                                            <h5 class="gray" style="margin: 0px;">Invoice :
                                                <strong>{{process.invoice}}</strong>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row col-3 d-flex align-items-center justify-content-end"
                                        style="padding: 0px;">
                                        <a id={{process.invoice}} (click)="detail($event)"
                                            style="margin: 0px; color: #b6244f; font-weight: 500; cursor: pointer;">Detail
                                            Belanja</a>
                                    </div>
                                </div>

                                <mat-divider class="mt-3 mb-2"></mat-divider>

                                <div *ngFor="let product of process.snapshoot_lists">
                                    <div class="row d-flex flex-wrap pr-4 py-2">
                                        <div class="col-lg-1 col-md-1 col-sm-1" style="padding-right: 0px;">
                                            <img class="align-self-center pb-2" style="width: 100px;"
                                                src="{{product.product_snapshoot.photo}}" alt="">
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                            <h4 style="margin: 0px;">{{product.product_snapshoot.name}}</h4>
                                            <h5 class="text-muted">Variasi : {{product.product_snapshoot.variant_name}}
                                            </h5>
                                        </div>
                                        <div
                                            class="col-lg-3 col-md-3 col-sm-12 pr-2 mt-2 justify-align-right text-right">
                                            <p>Rp {{priceTransform(product.product_snapshoot.price)}}</p>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider class="py-2"></mat-divider>
                                <div class="row d-flex flex-wrap">
                                    <div class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-end">
                                        <div class="row" style="padding-bottom: 0px;">
                                            <p class="pr-2 align-item-middle">Total :</p>
                                            <h3 class="bold align-item-middle" style="color: #3c3c3c;">Rp
                                                {{priceTransform(process.final_price)}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-paginator #paginator (page)="handlePage($event)" [length]=orderTotal [pageSize]="5" aria-label="Select page"></mat-paginator>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Diproses">
            <div *ngIf="orderTotal > 0; else elseBlock">
                <div class="py-3">
                    <div class="card mb-3" *ngFor="let process of orders">
                        <div class="card-body">
                            <div class="col-12 ">
                                <div class="d-flex flex-wrap flex-row pb-2">
                                    <div class="d-flex flex-column col-9" style="padding-left: 0px;">
                                        <div class="row col-12">
                                            <!-- <a href="/store/store-profile?store_id={{process.store.store_id}}">
                                                <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500;"><i
                                                        class="fas fa-store"></i><span
                                                        class="ml-2"></span>{{process.store.store_name}}</h4>
                                            </a> -->
                                            <div class="mt-2">
                                                <p class="text-center highlight-onproses">
                                                    {{process.snapshoot_lists[0].product_snapshoot.process_status}}</p>
                                            </div>
                                        </div>
                                        <div class="row col-12">
                                            <h5 class="gray" style="margin: 0px;">Waktu Checkout :
                                                <strong>{{dateTransform(process.created_at)}}</strong>
                                            </h5>
                                            <div class="separator"></div>
                                            <h5 class="gray" style="margin: 0px;">Invoice :
                                                <strong>{{process.invoice}}</strong>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row col-3 d-flex align-items-center justify-content-end"
                                        style="padding: 0px;">
                                        <a id={{process.invoice}} (click)="detail($event)"
                                            style="margin: 0px; color: #b6244f; font-weight: 500; cursor: pointer;">Detail
                                            Belanja</a>
                                    </div>
                                </div>

                                <mat-divider class="mt-3 mb-2"></mat-divider>

                                <div *ngFor="let product of process.snapshoot_lists">
                                    <div class="row d-flex flex-wrap pr-4 py-2">
                                        <div class="col-lg-1 col-md-1 col-sm-1" style="padding-right: 0px;">
                                            <img class="align-self-center pb-2" style="width: 100px;"
                                                src="{{product.product_snapshoot.photo}}" alt="">
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                            <h4 style="margin: 0px;">{{product.product_snapshoot.name}}</h4>
                                            <h5 class="text-muted">Variasi : {{product.product_snapshoot.variant_name}}
                                            </h5>
                                        </div>
                                        <div
                                            class="col-lg-3 col-md-3 col-sm-12 pr-2 mt-2 justify-align-right text-right">
                                            <p>Rp {{priceTransform(product.product_snapshoot.price)}}</p>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider class="py-2"></mat-divider>
                                <div class="row d-flex flex-wrap">
                                    <div class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-end">
                                        <div class="row" style="padding-bottom: 0px;">
                                            <p class="pr-2 align-item-middle">Total :</p>
                                            <h3 class="bold align-item-middle" style="color: #3c3c3c;">Rp
                                                {{priceTransform(process.final_price)}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-paginator #paginator (page)="handlePage($event)" [length]=orderTotal [pageSize]="5" aria-label="Select page"></mat-paginator>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Dikirim">
            <div *ngIf="orderTotal > 0; else elseBlock">
                <div class="py-3">
                    <div class="card mb-3" *ngFor="let process of orders">
                        <div class="card-body">
                            <div class="col-12 ">
                                <div class="d-flex flex-wrap flex-row pb-2">
                                    <div class="d-flex flex-column col-9" style="padding-left: 0px;">
                                        <div class="row col-12">
                                            <!-- <a href="/store/store-profile?store_id={{process.store.store_id}}">
                                                <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500;"><i
                                                        class="fas fa-store"></i><span
                                                        class="ml-2"></span>{{process.store.store_name}}</h4>
                                            </a> -->
                                            <div class="mt-2">
                                                <p class="text-center highlight-otw">
                                                    {{process.snapshoot_lists[0].product_snapshoot.process_status}}</p>
                                            </div>
                                        </div>
                                        <div class="row col-12">
                                            <h5 class="gray" style="margin: 0px;">Waktu Checkout :
                                                <strong>{{dateTransform(process.created_at)}}</strong>
                                            </h5>
                                            <div class="separator"></div>
                                            <h5 class="gray" style="margin: 0px;">Invoice :
                                                <strong>{{process.invoice}}</strong>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row col-3 d-flex align-items-center justify-content-end"
                                        style="padding: 0px;">
                                        <a id={{process.invoice}} (click)="detail($event)"
                                            style="margin: 0px; color: #b6244f; font-weight: 500; cursor: pointer;">Detail
                                            Belanja</a>
                                    </div>
                                </div>

                                <mat-divider class="mt-3 mb-2"></mat-divider>

                                <div *ngFor="let product of process.snapshoot_lists">
                                    <div class="row d-flex flex-wrap pr-4 py-2">
                                        <div class="col-lg-1 col-md-1 col-sm-1" style="padding-right: 0px;">
                                            <img class="align-self-center pb-2" style="width: 100px;"
                                                src="{{product.product_snapshoot.photo}}" alt="">
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                            <h4 style="margin: 0px;">{{product.product_snapshoot.name}}</h4>
                                            <h5 class="text-muted">Variasi : {{product.product_snapshoot.variant_name}}
                                            </h5>
                                        </div>
                                        <div
                                            class="col-lg-3 col-md-3 col-sm-12 pr-2 mt-2 justify-align-right text-right">
                                            <p>Rp {{priceTransform(product.product_snapshoot.price)}}</p>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider class="py-2"></mat-divider>
                                <div class="row d-flex flex-wrap">
                                    <div class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-end">
                                        <div class="row" style="padding-bottom: 0px;">
                                            <p class="pr-2 align-item-middle">Total :</p>
                                            <h3 class="bold align-item-middle" style="color: #3c3c3c;">Rp
                                                {{priceTransform(process.final_price)}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-paginator #paginator (page)="handlePage($event)" [length]=orderTotal [pageSize]="5" aria-label="Select page"></mat-paginator>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Selesai">
            <div *ngIf="orderTotal > 0; else elseBlock">
                <div class="py-3">
                    <div class="card mb-3" *ngFor="let process of orders">
                        <div class="card-body">
                            <div class="col-12 ">
                                <div class="d-flex flex-wrap flex-row pb-2">
                                    <div class="d-flex flex-column col-9" style="padding-left: 0px;">
                                        <div class="row col-12">
                                            <!-- <a href="/store/store-profile?store_id={{process.store.store_id}}">
                                                <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500;"><i
                                                        class="fas fa-store"></i><span
                                                        class="ml-2"></span>{{process.store.store_name}}</h4>
                                            </a> -->
                                            <div class="mt-2">
                                                <p class="text-center highlight-done">
                                                    {{process.snapshoot_lists[0].product_snapshoot.process_status}}</p>
                                            </div>
                                        </div>
                                        <div class="row col-12">
                                            <h5 class="gray" style="margin: 0px;">Waktu Checkout :
                                                <strong>{{dateTransform(process.created_at)}}</strong>
                                            </h5>
                                            <div class="separator"></div>
                                            <h5 class="gray" style="margin: 0px;">Invoice :
                                                <strong>{{process.invoice}}</strong>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row col-3 d-flex align-items-center justify-content-end"
                                        style="padding: 0px;">
                                        <a id={{process.invoice}} (click)="detail($event)"
                                            style="margin: 0px; color: #b6244f; font-weight: 500; cursor: pointer;">Detail
                                            Belanja</a>
                                    </div>
                                </div>

                                <mat-divider class="mt-3 mb-2"></mat-divider>

                                <div *ngFor="let product of process.snapshoot_lists">
                                    <div class="row d-flex flex-wrap pr-4 py-2">
                                        <div class="col-lg-1 col-md-1 col-sm-1" style="padding-right: 0px;">
                                            <img class="align-self-center pb-2" style="width: 100px;"
                                                src="{{product.product_snapshoot.photo}}" alt="">
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                            <h4 style="margin: 0px;">{{product.product_snapshoot.name}}</h4>
                                            <h5 class="text-muted">Variasi : {{product.product_snapshoot.variant_name}}
                                            </h5>
                                        </div>
                                        <div
                                            class="col-lg-3 col-md-3 col-sm-12 pr-2 mt-2 justify-align-right text-right">
                                            <p>Rp {{priceTransform(product.product_snapshoot.price)}}</p>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider class="py-2"></mat-divider>
                                <div class="row d-flex flex-wrap">
                                    <div class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-end">
                                        <div class="row" style="padding-bottom: 0px;">
                                            <p class="pr-2 align-item-middle">Total :</p>
                                            <h3 class="bold align-item-middle" style="color: #3c3c3c;">Rp
                                                {{priceTransform(process.final_price)}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-paginator #paginator (page)="handlePage($event)" [length]=orderTotal [pageSize]="5" aria-label="Select page"></mat-paginator>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Dibatalkan">
            <div *ngIf="orderTotal > 0; else elseBlock">
                <div class="py-3">
                    <div class="card mb-3" *ngFor="let process of orders">
                        <div class="card-body">
                            <div class="col-12 ">
                                <div class="d-flex flex-wrap flex-row pb-2">
                                    <div class="d-flex flex-column col-9" style="padding-left: 0px;">
                                        <div class="row col-12">
                                            <!-- <a href="/store/store-profile?store_id={{process.store.store_id}}">
                                                <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500;"><i
                                                        class="fas fa-store"></i><span
                                                        class="ml-2"></span>{{process.store.store_name}}</h4>
                                            </a> -->
                                            <div class="mt-2">
                                                <p class="text-center highlight-cancel">
                                                    {{process.snapshoot_lists[0].product_snapshoot.process_status}}</p>
                                            </div>
                                        </div>
                                        <div class="row col-12">
                                            <h5 class="gray" style="margin: 0px;">Waktu Checkout :
                                                <strong>{{dateTransform(process.created_at)}}</strong>
                                            </h5>
                                            <div class="separator"></div>
                                            <h5 class="gray" style="margin: 0px;">Invoice :
                                                <strong>{{process.invoice}}</strong>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row col-3 d-flex align-items-center justify-content-end"
                                        style="padding: 0px;">
                                        <a id={{process.invoice}} (click)="detail($event)"
                                            style="margin: 0px; color: #b6244f; font-weight: 500; cursor: pointer;">Detail
                                            Belanja</a>
                                    </div>
                                </div>

                                <mat-divider class="mt-3 mb-2"></mat-divider>

                                <div *ngFor="let product of process.snapshoot_lists">
                                    <div class="row d-flex flex-wrap pr-4 py-2">
                                        <div class="col-lg-1 col-md-1 col-sm-1" style="padding-right: 0px;">
                                            <img class="align-self-center pb-2" style="width: 100px;"
                                                src="{{product.product_snapshoot.photo}}" alt="">
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                            <h4 style="margin: 0px;">{{product.product_snapshoot.name}}</h4>
                                            <h5 class="text-muted">Variasi : {{product.product_snapshoot.variant_name}}
                                            </h5>
                                        </div>
                                        <div
                                            class="col-lg-3 col-md-3 col-sm-12 pr-2 mt-2 justify-align-right text-right">
                                            <p>Rp {{priceTransform(product.product_snapshoot.price)}}</p>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider class="py-2"></mat-divider>
                                <div class="row d-flex flex-wrap">
                                    <div class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-end">
                                        <div class="row" style="padding-bottom: 0px;">
                                            <p class="pr-2 align-item-middle">Total :</p>
                                            <h3 class="bold align-item-middle" style="color: #3c3c3c;">Rp
                                                {{priceTransform(process.final_price)}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-paginator #paginator (page)="handlePage($event)" [length]=orderTotal [pageSize]="5" aria-label="Select page"></mat-paginator>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>