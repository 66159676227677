import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ConfirmAddressComponent, ConfirmDeleteAddressComponent, ConfirmEditAddressComponent } from './confirm-address/confirm-address.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { existValidation } from 'src/app/utility.service';

interface ProvinceData {
  city_id: number
  province_id: number
  sub_district_id: number
  urban_id: number
  city_name: string
  country: string
  country_id: number
  id: number
  postal_code: number
  province_name: string
  sub_district_name: string
  urban_name: string
}

interface AddressData {
  address_id: number;
  primary_address: boolean;
  recipient: string;
  phone: string;
  category: string;
  city: string;
  address_input: string;
  province: string;
  district: string;
  post_id: number;
  urban: string;
  osas_log_id: number
  address: ProvinceData;
}

interface FilterObj {
  // field: string
  keyword: any
}

interface QueryTable {
  sort: null | Object
  filters: FilterObj[]
  pageSize: number
  pageIndex: number
}

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})


export class AddressComponent implements OnInit {
  listOfData: AddressData[] = []
  isLoading: boolean = false
  totalData: number = 0

  // MatPaginator Output
  // pageEvent: PageEvent;

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  displayedColumns: string[] = ['recipient', 'action'];
  dataSource = new MatTableDataSource<AddressData>();

  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;

  form: FormGroup
  constructor(
    public dialog: MatDialog,
    private routes: Router,
    private service: ApiService,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      keyword: [null],
      // prefix: ['name', Validators.compose([Validators.required])],
    })

    // this.service.userBasicProfile().subscribe(data => {
    //   this.username = data.username
    //   console.log("DetailProfileComponent-UserProfile")
    //   console.log(data)
    // }, e => {
    //   console.log(e)
    // })
  }

  ngOnInit() {
    this.fetchAddress()
  }

  pageChanged(event: PageEvent) {
    // console.log({ event });
    this.pageSize = event.pageSize;
    this.queryTable.pageIndex = event.pageIndex;
    this.pageIndex = event.pageIndex;
    this.fetchAddress();
  }

  fetchAddress() {
    this.isLoading = true
    this.service.listUserAddress(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.dataSource.data = r.addresses
      this.totalData = r.total
      setTimeout(() => {
        this.paginator.pageIndex = this.pageIndex;
        this.paginator.length = r.total;
      });
    })
  }

  pageSize: number = 10
  pageIndex: number = 0

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex,
  }

  // onQueryParamsChange(params): void {
  //   const { pageSize, pageIndex, sort, filter } = params
  //   this.queryTable.pageSize = this.pageEvent.pageSize
  //   this.queryTable.pageIndex = this.pageEvent.pageIndex
  //   const currentSort = sort.find(item => item.value !== null)
  //   const sortField = (currentSort && currentSort.key) || null
  //   const sortOrder = (currentSort && currentSort.value) || null

  //   if (sortField != null && sortOrder != null) {
  //     this.queryTable.sort = {
  //       field: sortField,
  //       order: sortOrder,
  //     }
  //   } else {
  //     this.queryTable.sort = null
  //   }
  //   this.fetchAddress()
  // }

  search(event: Event) {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          // field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
      // console.log(this.queryTable.filters)
    } else {
      this.queryTable.filters = []
    }
    this.fetchAddress()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(action: string, obj: any) {
    debugger;
    obj.action = action;

    const dialogRef = this.dialog.open(AddAddress, {
      data: obj,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Tambah') {
        this.addRowData(result.data);
      } else if (result.event === 'Ubah') {
        this.updateRowData(result.data);
      } else if (result.event === 'Hapus') {
        this.deleteRowData(result.data);
      }
    });
  }

  addRowData(row_obj: AddressData) {
    this.dataSource.data = []

    this.dataSource.data.push({
      address_id: this.listOfData.length + 1,
      address: row_obj.address,
      recipient: row_obj.recipient,
      phone: row_obj.phone,
      category: row_obj.category,
      province: row_obj.address.province_name,
      district: row_obj.address.sub_district_name,
      post_id: row_obj.address.postal_code,
      address_input: row_obj.address_input,
      primary_address: row_obj.primary_address,
      city: row_obj.address.city_name,
      urban: row_obj.address.urban_name,
      osas_log_id: row_obj.address.id
    });

    this.service.addUserAddress(this.dataSource.data, localStorage.getItem("jwt")).subscribe((res) => {
      this.dialog.open(ConfirmAddressComponent);
      console.log("SUCCESS")
      this.fetchAddress()
    }, e => {
      console.log(e)
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Gagal menambah alamat. Mohon coba beberapa saat lagi.'
        }
      });
    })

    this.fetchAddress()
    this.table.renderRows();
  }

  updateRowData(row_obj: AddressData) {
    this.dataSource.data = []
    
    this.dataSource.data.push({
      address_id: row_obj.address_id,
      address: row_obj.address,
      recipient: row_obj.recipient,
      phone: row_obj.phone,
      category: row_obj.category,
      province: row_obj.address.province_name,
      district: row_obj.address.sub_district_name,
      post_id: row_obj.address.postal_code,
      address_input: row_obj.address_input,
      primary_address: row_obj.primary_address,
      city: row_obj.address.city_name,
      urban: row_obj.address.urban_name,
      osas_log_id: row_obj.address.id
    });

    this.service.editUserAddress(this.dataSource.data).subscribe((res) => {
      this.dialog.open(ConfirmEditAddressComponent);
      console.log("SUCCESS")
      this.fetchAddress()
    }, e => {
      console.log(e)
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Gagal mengubah alamat. Mohon coba beberapa saat lagi.'
        }
      });
    })

    this.fetchAddress()
    return true;
  }

  deleteRowData(row_obj: AddressData) {
    console.log(row_obj.address_id)
    this.service.deleteUserAddress(row_obj).subscribe((res) => {
      this.dialog.open(ConfirmDeleteAddressComponent);
      console.log("SUCCESS")
      this.queryTable.pageIndex = 0
      this.fetchAddress()
    }, e => {
      console.log(e)
      this.dialog.open(DialogAlert, {
        width: '300px',
        data: {
          status: false,
          message: 'Gagal menghapus alamat. Mohon coba beberapa saat lagi.'
        }
      });
    })
  }
}

/////// DIALOG ADD & EDIT ADDRESS

@Component({
  selector: 'add-address',
  templateUrl: 'add-address.html',
  styleUrls: ['./address.component.css']
})
export class AddAddress implements OnInit {

  action: string;
  local_data: any;
  destinationSearchData: any = [];
  isLoading: boolean = false

  /////// Auto complete Province - START

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private service: ApiService,
    public dialogRef: MatDialogRef<AddAddress>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AddressData) {
    debugger;
    this.local_data = data;
    if (this.local_data.category == null) {
      this.local_data.category = ""
    }
    this.action = this.local_data.action;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      // origin: [null, Validators.compose([Validators.required])],
      dest: [null, Validators.compose([Validators.required])],
    }, { validators: existValidation });

    this.destinationSearchData = this.form.get('dest')?.valueChanges
      .pipe(
        // debounceTime(250),
        distinctUntilChanged(),
        startWith(''),
        switchMap(value => this.destination_serv(value))
      );
  }

  private destination_serv(value: string): any {
    return this.service.destinationSearch(value).pipe(map(res => {
      for (var i = res.length; i--;) {
        if (res[i].urban_name.slice(0, 9) == "KECAMATAN") {
          res.splice(i, 1)
        }
      }
      return res
    }), catchError(err => of([])))
  }

  displayFnDest(consignee?: any): string | undefined {
    // console.log(consignee)
    return consignee ? consignee.urban_name + ", " + consignee.sub_district_name + ", " + consignee.city_name + ", Prov. " + consignee.province_name + ", " + consignee.postal_code : undefined;
  }

  /////// Auto complete Province - END

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}

@Component({
  selector: 'dialog-alert',
  templateUrl: '../detail-profile/dialog-alert.html',
  styleUrls: ['../myprofile.component.css'],
})

export class DialogAlert {
  action: string;
  local_data: any;
  icon: string = 'check_circle';
  message: string = 'Profil berhasil diubah';

  constructor(
    public dialogRef: MatDialogRef<AddAddress>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    debugger;
    this.icon = 'error',
    this.message = data.message
    // this.local_data = data;
    // if (this.local_data.category == null) {
    //   this.local_data.category = ""
    // }
    // this.action = this.local_data.action;
    // console.log(this.action)
  }
}