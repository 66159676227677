import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NumberFormatPipe } from '../../../number.pipe';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.css'],
  providers: [NumberFormatPipe]
})
export class StoreProfileComponent implements OnInit {

  product: any
  bestSellers: any
  store_id: any
  storeInfos: any
  params: ParamMap

  constructor(
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    public datepipe: DatePipe,
  ) {
    this.params = this.route.snapshot.queryParamMap
    this.store_id = this.params.get('store_id')

    this.service.storeInfo(this.store_id).subscribe(data => {
      this.storeInfos = data
      // console.log("PersonalStoreComponent-StoreInfo")
      // console.log(data)
    }, e => {
      console.log(e)
    })

    this.service.bestSeller(this.store_id).subscribe(data => {
      this.bestSellers = data.products
      // console.log("PersonalStoreComponent-bestseller")
      // console.log(data)
    }, e => {
      console.log(e)
    })
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  ngOnInit(): void {
  }

}
