import { Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jwt_decode from "jwt-decode";

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ApiService]
})
export class LoginComponent implements OnInit {
  hide = true
  form: FormGroup
  InvalidEmailPassword = [false, false, false]
  InvalidCredential: boolean = false

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ApiService, 
    private routes: Router, 
    private fb: FormBuilder) {
      this.service.login.subscribe(r => {
        // console.log("This is login from Cart", r)
        if(r){
          this.onSubmit()
        }
      })
    }

  ngOnInit() {
    this.form = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])]
    });
  }
    
  onSubmit() {
    const username = this.form.value.username
    const password = this.form.value.password

    this.service.getUser(username, password).subscribe(data => {

      // console.log("asdokaod")
      // console.log(data)
      const decodedToken:any = jwt_decode(data.token)
      //Set Expiration on Local Storage
      
      localStorage.setItem('username', decodedToken.user)
      localStorage.setItem('jwt', data.token)

      // console.log(localStorage.getItem('jwt'));

      this.service.verifyUserExist().subscribe(r => {
        console.log('User Verified')
      })
      
      this.dialogRef.close()
      window.location.reload()
      // this.routes.navigate(['/'])
      // this.routes.navigate(['/user/profile'])
    }, e => {

      // console.log("eroor coy")
      console.log(e.error.message)
      if(e.error.message != undefined){
        switch(e.error.message){
          case "Invalid Username/Email":
            this.InvalidEmailPassword[0] = true
            //Disable other error
            this.InvalidEmailPassword[2] = false
            break
          case "Invalid Password":
            this.InvalidEmailPassword[1] = true
            break
          case "Account is Disabled":
            this.InvalidEmailPassword[2] = true
            //Disable other error
            this.InvalidEmailPassword[0] = false
            break
          case "Invalid Credential":
            this.InvalidCredential = true
        }
      }
    })
  }
}

