<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <app-whatsapp-corner></app-whatsapp-corner>
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <section id="blog" class="section blog-area mb-3 pb_50">
      <div class="container">
        <div class="row">
          <div class="col-12 w-100">
            <div>
              <div class="card" style="margin-top: 40px">
                <div class="card-body py-4 justify-content-center text-center">
                  <h3>Invoice #{{ invoice }}</h3>
                  <h4>Mohon Selesaikan Pembayaran sebelum</h4>
                  <h3 style="color: #b6244f">
                    <strong>
                      <!-- <countdown [config]="{leftTime: 360 * 10}"></countdown> -->
                      {{ expiryDate }}
                    </strong>
                  </h3>
                  <mat-divider class="py-2"></mat-divider>
                  <img src="../../../assets/img/payment.gif" width="200" />
                  <!-- <div class="col-12">
                                        <div class="row justify-content-between">
                                            <div class="col-6">
                                                Waktu Checkout
                                            </div>
                                            <div class="col-6 d-flex justify-content-end text-right">
                                                <p>Minggu, 23 Maret 2021 22:10 WIB</p>
                                            </div>
                                        </div>
                                        <mat-divider class="py-2"></mat-divider>
                                        <div class="row justify-content-between">
                                            <div class="col-6">
                                                Total Belanja
                                            </div>
                                            <div class="col-6 d-flex justify-content-end text-center">
                                                <h3 style="color: #3571b6;">Rp. 250.000</h3>
                                            </div>
                                        </div>
                                        <mat-divider class="py-2"></mat-divider>
                                        <div class="row justify-content-between">
                                            <div class="col-6 ">
                                                Batas Pembayaran
                                            </div>
                                            <div class="col-6 d-flex justify-content-end text-right">
                                                <h4 style="color: #c03b3b;">Minggu, 23 Maret 2021 Pukul 23:10</h4>
                                            </div>
                                        </div>
                                        <mat-divider class="py-2"></mat-divider>
                                        <div class="row justify-content-between">
                                            <div class="col-6">
                                                Metode Pembayaran
                                            </div>
                                            <div class="col-6 d-flex justify-content-end text-center">
                                                <p>BNI Virtual Account</p>
                                            </div>
                                        </div>
                                        <mat-divider class="py-2"></mat-divider>
                                    </div> -->
                  <!-- <div class="py-4">
                                        <div class="row justify-content-center text-center">
                                            <div class="col-6 py-2">
                                                Nomor Virtual Account
                                            </div>
                                        </div>
                                        <div class="row justify-content-center text-center">
                                            <div class="col-6">
                                                <h3 style="color: #3571b6;">85656421587
                                                    <div class="copy" (click)="copyToClipboard('85656421587')">
                                                        <span class="material-icons-outlined">content_copy</span>
                                                    </div>
                                                </h3>
                                            </div>
                                        </div>
                                    </div> -->
                  <div>
                    <div class="row justify-content-center text-center">
                      <button
                        (click)="makeOrder()"
                        type="button"
                        class="btn btn-checkout m-1"
                        style="background: #3571b6"
                      >
                        Bayar Sekarang
                      </button>
                      <!-- <button href="#" type="button" data-toggle="modal" data-target="#payment" class="btn btn-checkout m-1" style="background: #13AAD4;">
                                                Cara Pembayaran 
                                            </button> -->
                      <a
                        href="/user/order"
                        type="button"
                        class="btn btn-checkout m-1"
                        style="background: #b6244f"
                      >
                        Cek Pesanan
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-3">
                <p style="font-size: 12px; color: #a3a2a2">
                  <strong>Catatan :</strong> Simpan bukti pembayaran jika
                  sewaktu-waktu diperlukan
                </p>
              </div>
              <!-- </div> -->
              <!-- <div class="col-lg-6 col-md-12 d-flex flex-row flex-wrap pb-3 order-2 order-lg-1"> -->
              <div class="card" style="margin-bottom: 70px">
                <div class="card-body pt-4 py-2">
                  <div class="col-12">
                    <h3>Rangkuman Belanja</h3>
                    <mat-divider class="py-2"></mat-divider>
                    <div
                      class="row d-flex flex-wrap header pr-4 py-2"
                      style="color: #8b8b8b"
                    >
                      <div class="col-5 d-none d-md-block">
                        <h4>
                          <i class="fas fa-store"></i
                          ><span class="ml-2"></span> Gigas
                        </h4>
                      </div>
                      <div class="col-2 d-none d-md-block">Jumlah</div>
                      <div class="col-2 d-none d-md-block">Harga Satuan</div>
                      <div class="col-3 d-none d-md-block text-right">
                        Subtotal Produk
                      </div>
                    </div>
                    <mat-divider class="py-2"></mat-divider>
                    <div *ngFor="let order of orderSummary.orderInfo">
                      <div class="row d-flex flex-wrap pr-4 py-2">
                        <div class="col-lg-5 col-md-12 col-sm-12">
                          <h4 style="margin: 0px">{{ order.product_name }}</h4>
                          <p class="text-muted">
                            Variasi : {{ order.variant_name }}
                          </p>
                        </div>
                        <div
                          class="col-lg-2 col-md-12 col-sm-12 align-self-center"
                        >
                          <p>{{ order.quantity }}</p>
                        </div>
                        <div
                          class="col-lg-2 col-md-12 col-sm-12 align-self-center"
                        >
                          <p>Rp {{ priceTransform(order.variant_price) }}</p>
                        </div>
                        <div
                          class="col-lg-3 col-md-12 col-sm-12 align-self-center text-right"
                        >
                          <p>
                            Total Belanja : Rp
                            {{ priceTransform(order.subtotal) }}
                          </p>
                        </div>
                      </div>
                      <mat-divider class="py-2"></mat-divider>
                    </div>
                    <ng-template [ngIf]="orderSummary.insurance_exist">
                      <div class="row d-flex flex-wrap pr-4 py-2">
                        <div class="col-lg-5 col-md-12 col-sm-12">
                          <!-- <h4>Biaya Asuransi : </h4> -->
                          <!-- <p class="text-muted">Variasi : {{order.variant_name}}</p> -->
                        </div>
                        <div
                          class="col-lg-2 col-md-12 col-sm-12 align-self-center"
                        >
                          <!-- <p>{{order.quantity}} </p> -->
                        </div>
                        <div
                          class="col-lg-2 col-md-12 col-sm-12 align-self-center"
                        >
                          <h4>Biaya Asuransi :</h4>
                        </div>
                        <div
                          class="col-lg-3 col-md-12 col-sm-12 align-self-center text-right"
                        >
                          <p>
                            Rp {{ priceTransform(orderSummary.insurance_fee) }}
                          </p>
                        </div>
                      </div>
                    </ng-template>
                    <ng-container *ngIf="orderSummary.voucher_id">
                      <div class="row d-flex flex-wrap pr-4 py-2">
                        <div class="col-lg-5 col-md-12 col-sm-12">
                          <!-- <h4 style="margin: 0px;">{{order.product_name}} </h4>
                                                    <p class="text-muted">Variasi : {{order.variant_name}}</p> -->
                        </div>
                        <div
                          class="col-lg-2 col-md-12 col-sm-12 align-self-center"
                        >
                          <!-- <p>{{order.quantity}} </p> -->
                        </div>
                        <div
                          class="col-lg-2 col-md-12 col-sm-12 align-self-center"
                        >
                          <h4>Diskon :</h4>
                        </div>
                        <div
                          class="col-lg-3 col-md-12 col-sm-12 align-self-center text-right"
                        >
                          <p>
                            - Rp
                            {{ priceTransform(orderSummary.total_discount) }}
                          </p>
                        </div>
                      </div>
                      <mat-divider class="py-2"></mat-divider>
                    </ng-container>
                    <div class="row d-flex flex-wrap pr-4 py-2">
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <p>
                          ONDELIVERY ({{ orderSummary.service_name }}) -
                          <strong
                            >Rp
                            {{
                              priceTransform(orderSummary.delivery_fee)
                            }}</strong
                          >
                        </p>
                      </div>
                      <div
                        class="col-lg-6 col-md-4 justify-align-right text-right"
                      >
                        <p>
                          Sub Total :
                          <strong
                            >Rp
                            {{
                              priceTransform(orderSummary.final_price)
                            }}</strong
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer-one></app-footer-one>
  </div>
</div>

<!-- <div class="modal fade" id="payment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Pembayaran via Bank Transfer</p>

                <p>salin nomor virtual account</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-checkout" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div> -->
