import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/api.service";
import { NumberFormatPipe } from "../../number.pipe";

@Component({
  selector: "app-product-recommendation",
  templateUrl: "./product-recommendation.component.html",
  styleUrls: ["./product-recommendation.component.css"],
  providers: [NumberFormatPipe],
})
// TODO fix when screen is minimized
export class ProductRecommendationComponent implements OnInit {
  recommendations: any;
  currency: any;

  constructor(
    private service: ApiService,
    private currencyFormat: NumberFormatPipe
  ) {
    this.service.mostSoldProducts(0).subscribe(
      (data) => {
        this.recommendations = data.products;
        // console.log("ProductRecommendationComponent")
        // console.log(data)
      },
      (e) => {
        console.log(e);
      }
    );
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  ngOnInit(): void {
    // this.recommendations;
  }
}
