import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [ApiService]
})
export class SignupComponent implements OnInit {
  msg = '';
  form: FormGroup;

  constructor(
    private service: ApiService, 
    private routes: Router, 
    private fb: FormBuilder,
    public dialog: MatDialog) { }

  hide = true;

  ngOnInit() {
    if (localStorage.getItem("jwt") != null) {
      this.routes.navigate(['/profile'])
    }
    this.form = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
      firstname: [null, Validators.compose([Validators.required])],
      lastname: [null, Validators.compose([Validators.required])],
      phone_number: [null, Validators.compose([Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"), Validators.required])],
    });
  }

  register() {
    const username = this.form.value.username;
    const password = this.form.value.password;
    const email = this.form.value.email;
    const first_name = this.form.value.firstname;
    const last_name = this.form.value.lastname;
    const phone_number = this.form.value.phone_number;

    
    this.service.register(username, password, email, first_name, last_name, phone_number).subscribe(data => {
      const dialogRef = this.dialog.open(DialogSignUp, {
        data: {
          title: "Account created successfully.",
          // heading: "Please check your email to get verification link"
          heading: "Please login to utilize the account"
        }
        // disableClose: true
      });
      // this.dialogRef.close()
      
      // this.routes.navigate([''])
    }, e => {
      if (e.status == 400 && e.error.message !== 'Invalid JSON Format') {
        const dialogRef = this.dialog.open(DialogSignUp, {
          data: {
            title: "Account creation failed.",
            heading: e.error.message
          }
          // disableClose: true
        });
      } else {
        const dialogRef = this.dialog.open(DialogSignUp, {
          data: {
            title: "Account creation failed.",
            heading: "Please try again later"
          }
          // disableClose: true
        });
      }
    })
  }

  signIn() {
    this.routes.navigateByUrl('/', { state: { signIn: true } })
  }

}

@Component({
  selector: 'dialog-sign-up',
  templateUrl: 'dialog-sign-up.html',
})

export class DialogSignUp {
  
  title: string
  heading: string

  constructor(
    public dialogRef: MatDialogRef<DialogSignUp>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private routes: Router,) {
      this.title = data.title
      this.heading = data.heading
    }
  
    OK(): void{
      if (this.title === 'Account created successfully.') {
        this.dialogRef.close()
        this.routes.navigate([''])
      } else {
        this.dialogRef.close()
      }
    }

}
