<div mat-dialog-content>
    <div class="d-flex flex-row flex-wrap">
        <a class="bt">
            <div>
                <div class="d-inline-block  ">
                    <img class="category-size" src="assets/img/categories/menu.png" alt="">
                </div>
                <p class="mt-1">Semua</p>
            </div>
        </a>
        <a class="bt">
            <div>
                <div class="d-inline-block  ">
                    <img class="category-size" src="assets/img/categories/menu.png" alt="">
                </div>
                <p class="mt-1">Semua</p>
            </div>
        </a>
        <a class="bt">
            <div>
                <div class="d-inline-block  ">
                    <img class="category-size" src="assets/img/categories/menu.png" alt="">
                </div>
                <p class="mt-1">Semua</p>
            </div>
        </a>
        <a class="bt">
            <div>
                <div class="d-inline-block  ">
                    <img class="category-size" src="assets/img/categories/menu.png" alt="">
                </div>
                <p class="mt-1">Semua</p>
            </div>
        </a>
        <a class="bt">
            <div>
                <div class="d-inline-block  ">
                    <img class="category-size" src="assets/img/categories/menu.png" alt="">
                </div>
                <p class="mt-1">Semua</p>
            </div>
        </a>
        <a class="bt">
            <div>
                <div class="d-inline-block  ">
                    <img class="category-size" src="assets/img/categories/menu.png" alt="">
                </div>
                <p class="mt-1">Semua</p>
            </div>
        </a>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</div>