import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class ApiService {
  //DEMO
  BASEURL = "http://localhost:4200/";
  // API_USERSTATE_SERVER = "https://demousapi.onindonesia.id/";
  API_USERONDELIVERY_SERVER = "http://202.157.187.188:3400/";
  API_OSAS_SERVER = "https://demoapi.ondelivery.id/";
  API_ONMARKET_SERVER = "https://demoapi.onmarket.id/";
  API_ONBOOKING_SERVER = "https://demoapisat.ondelivery.id/";

  //LOCAL
  // API_ONMARKET_SERVER = "http://169.254.19.85:3611/";
  // API_USERSTATE_SERVER = "http://169.254.19.85:3300/";

  //PROD
  // BASEURL = "https://gigas.id/";
  API_USERSTATE_SERVER = "https://usapi.onindonesia.id/";
  // API_USERONDELIVERY_SERVER = "http://202.157.187.188:3400/";
  // API_OSAS_SERVER = "https://api.ondelivery.id/";
  // API_ONMARKET_SERVER = "https://demoapi.onmarket.id/";
  // API_ONBOOKING_SERVER = "https://apisat.ondelivery.id/";

  constructor(private http: HttpClient) {}

  isUpdateCart = new BehaviorSubject(true);
  deletedFromCart = new BehaviorSubject(true);
  login = new BehaviorSubject(true);

  getUser(uname: string, pwd: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      username: uname,
      password: pwd,
      options: {
        nativeApp: false,
      },
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "auth",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  register(
    uname: string,
    pwd: string,
    email: string,
    fname: string,
    lname: string,
    phone_number: string
  ) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      username: uname,
      password: pwd,
      email: email,
      first_name: fname,
      last_name: lname,
      phone_number: phone_number,
    };
    // return this.http.post<any>(this.API_USERSTATE_SERVER + "signup", JSON.stringify(payload), { headers: customHeaders })
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "signup-temp",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  verify(token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify", {
      headers: customHeaders,
    });
  }

  verifySignUp(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify-sign-up", {
      headers: customHeaders,
    });
  }

  logOff(token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "logoff", {
      headers: customHeaders,
    });
  }

  requestResetPwd(email: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      email: email,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "request-reset-pwd",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  verifyLinkResetPwd(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify-reset-pwd", {
      headers: customHeaders,
    });
  }

  resetPwd(password: string, token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    const payload = {
      password: password,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "reset-pwd",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  /////////////////////////////

  verifyUserExist() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "verify-user-exist", {
      headers: customHeaders,
    });
  }

  // activateStore(token: string) {
  //   let customHeaders = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token
  //   });
  //   return this.http.get<any>(this.API_USERSTATE_SERVER + "activate-store", { headers: customHeaders })
  // }

  // editStore(token: string, image: File, name: string, category: string, description: string) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     image: image,
  //     name: name,
  //     category: category,
  //     description: description,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store", payload, { headers: customHeaders })
  // };

  // editStoreSchedule(token: string, open_days: string[], time: string[][]) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     status: false,
  //     schedule: {
  //       open_days: open_days,
  //       time: time,
  //     }
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store-schedule", payload, { headers: customHeaders })
  // };

  // editStoreExpedition(token: string, expedition_setting_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = [{
  //     status: false,
  //     expedition_setting_id: expedition_setting_id,
  //   }, {
  //     status: false,
  //     expedition_setting_id: expedition_setting_id,
  //   }, {
  //     status: false,
  //     expedition_setting_id: expedition_setting_id,
  //   }]
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store-expedition", payload, { headers: customHeaders })
  // };

  // editStoreAddress(token: string, address_id: number, store_address: boolean) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     address_id: address_id,
  //     store_address: false,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store-address", payload, { headers: customHeaders })
  // };

  // addStoreFront(token: string, store_id: number, name: string) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_id: store_id,
  //     name: name,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "add-store-front", payload, { headers: customHeaders })
  // };

  // editStoreFront(token: string, store_id: number, name: string) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_id: store_id,
  //     name: name,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store-front", payload, { headers: customHeaders })
  // };

  // ////////////////////

  // listStoreFront(token: string, store_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_id: store_id,
  //   }
  //   return this.http.get<any>(this.API_USERSTATE_SERVER + "list-store-front", { headers: customHeaders })
  // };

  // ///////////////////////

  // deleteStoreFront(token: string, store_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_id: store_id,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "delete-store-front", payload, { headers: customHeaders })
  // };

  // addFrontList(token: string, store_front_id: number, product_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_front_id: store_front_id,
  //     product_id: product_id,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "add-front-list", payload, { headers: customHeaders })
  // };

  // listFrontList(token: string, store_front_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_front_id: store_front_id,
  //   }
  //   return this.http.get<any>(this.API_USERSTATE_SERVER + "list-front-list", { headers: customHeaders })
  // };

  // deleteFrontList(token: string, front_list_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     front_list_id: front_list_id,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "delete-front-list", payload, { headers: customHeaders })
  // };

  storeInfo(store_id) {
    const params = new HttpParams().set("store_id", store_id);
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/detail", {
      params: params,
    });
  }

  randomizeProducts() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const params = customHeaders
      ? {
          headers: customHeaders,
          params: {
            limiter: "gigas",
          },
        }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/random",
      params
    );
  }

  mostSoldProducts(page) {
    const params = new HttpParams().set("page", page);
    return this.http.get<any>(this.API_ONMARKET_SERVER + "product/most-sold", {
      params: params,
    });
  }

  searchProducts(queryTable, keyword) {
    keyword = keyword == null ? "" : keyword;
    queryTable.keyword = keyword;
    queryTable.limiter = "gigas";
    // const params = new HttpParams().set("page", page).set("keyword", keyword).set("min", min).set("max", max)
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "product/search",
      queryTable
    );
  }

  searchBox(keyword) {
    const params = new HttpParams()
      .set("name", keyword)
      .set("limiter", "gigas");
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/search-autocomplete",
      { params: params }
    );
  }

  categoryList() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const params = customHeaders
      ? {
          headers: customHeaders,
          params: {
            limiter: "gigas",
          },
        }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/list/category",
      params
    );
  }

  categoryProduct(queryTable) {
    queryTable.limiter = "gigas";
    // const params = new HttpParams().set("page", page).set("category_id", category_id)
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "product/list/category/products",
      queryTable
    );
  }

  productDetail(product_id) {
    //?
    const params = new HttpParams()
      .set("product_id", product_id)
      .set("limiter", "gigas");
    return this.http.get<any>(this.API_ONMARKET_SERVER + "product/detail", {
      params: params,
    });
  }

  voucherList() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "voucher/list",
      null,
      { headers: customHeaders }
    );
  }

  voucherDetail(voucher_id: number) {
    const params = new HttpParams()
      .set("voucher_id", voucher_id)
      .set("type", "CUSTOMER");
    return this.http.get<any>(this.API_ONMARKET_SERVER + "voucher/detail", {
      params: params,
    });
  }

  reviewList(product_id, page, store_id) {
    //TODO
    product_id = product_id == null ? "" : product_id;
    store_id = store_id == null ? "" : store_id;
    const params = new HttpParams()
      .set("product_id", product_id)
      .set("page", page)
      .set("store_id", store_id);
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/review/list",
      { params: params }
    );
  }

  discussionList(product_id, page) {
    //TODO
    const params = new HttpParams()
      .set("product_id", product_id)
      .set("page", page);
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/discussion/list",
      { params: params }
    );
  }

  bestSeller(store_id) {
    const params = new HttpParams().set("store_id", store_id);
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/best-seller", {
      params: params,
    });
  }

  storeProduct(store_id, page) {
    const params = new HttpParams().set("page", page).set("store_id", store_id);
    return this.http.get<any>(this.API_ONMARKET_SERVER + "product/list", {
      params: params,
    });
  }

  cartList() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const params = customHeaders
      ? {
          headers: customHeaders,
          params: {
            limiter: "gigas",
          },
        }
      : {};
    return this.http.get<any>(this.API_ONMARKET_SERVER + "cart/list", params);
  }

  addCart(qty: number, varID: number) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const body = {
      quantity: qty,
      variant_id: varID,
    };
    return this.http.post<any>(this.API_ONMARKET_SERVER + "cart/add", body, {
      headers: customHeaders,
    });
  }

  deleteCart(varID: number) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const body = {
      variant_id: varID,
    };
    return this.http.post<any>(this.API_ONMARKET_SERVER + "cart/delete", body, {
      headers: customHeaders,
    });
  }

  editCart(payload) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "cart/edit",
      payload,
      { headers: customHeaders }
    );
  }

  getStoreId() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/get-store-id", {
      headers: customHeaders,
    });
  }

  ///____________ PROFILE ____________///

  userBasicProfile() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "basic-profile", {
      headers: customHeaders,
    });
  }

  updateBasicProfile(payload, token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "update-basic-profile",
      payload,
      { headers: customHeaders }
    );
  }

  getUserImage(username: string) {
    // const params = new HttpParams().set("username", username)
    // return this.http.get<any>(this.API_USERSTATE_SERVER + "get-profile-img/" + username)
    return this.API_USERSTATE_SERVER + "get-profile-img/" + username;
  }

  listUserAddress(queryTable) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {};
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/list",
      queryTable,
      params
    );
  }

  addUserAddress(payload, token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/add",
      payload,
      { headers: customHeaders }
    );
  }

  userAddressDetail(address_id: number) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "user/address/detail/" + address_id,
      params
    );
  }

  editUserAddress(payload) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {};
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/edit",
      payload,
      params
    );
  }

  deleteUserAddress(payload) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {};
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/delete",
      payload,
      params
    );
  }

  userMainAddress() {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "user/address/main",
      params
    );
  }

  destinationSearch(content: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!
    });
    const params = {
      search_dest: content,
    };
    return this.http.get<any>(
      this.API_OSAS_SERVER + "check-fee/destination/searchlanding",
      { headers: customHeaders, params: params }
    );
  }

  listOrderHistory(page: number) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            page: page,
            type: "CUSTOMER",
            limiter: "gigas",
          },
        }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "transaction/list/history",
      params
    );
  }

  listProcess(page: number, process: string) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            page: page,
            process: process,
            type: "CUSTOMER",
            limiter: "gigas",
          },
        }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "transaction/list/orders",
      params
    );
  }

  ///____________ CHECKOUT ____________///

  checkDeliveryFee(content: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!
    });
    return this.http.post<any>(this.API_OSAS_SERVER + "check-fee", content, {
      headers: customHeaders,
    });
  }

  insuranceCheckFee(service_id: string, goods_value: number): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const body = {
      service: service_id,
      goodsVal: goods_value,
    };
    return this.http.post<any>(this.API_OSAS_SERVER + "check-ins", body, {
      headers: customHeaders,
    });
  }

  calculateDiscount(
    code: string,
    price_before_discount: number
  ): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const body = {
      code: code,
      price_before_discount: price_before_discount,
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "voucher/calculate",
      body,
      { headers: customHeaders }
    );
  }

  redeemVoucher(code: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const body = {
      code: code,
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "voucher/redeem",
      body,
      { headers: customHeaders }
    );
  }

  xenditCheckout(orderInfo): Observable<any> {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {};
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "transaction/checkout",
      orderInfo,
      params
    );
  }

  invoiceDetail(invoice: string): Observable<any> {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            invoice: invoice,
          },
        }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "transaction/invoice/detail",
      params
    );
  }

  ///____________ ORDER HISTORY ____________///

  waybillBasic(waybill: Array<string>) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': loca lStorage.getItem("jwt")!,
    });
    const payload = {
      searchMethod: "trackingID",
      searchTrackID: waybill,
    };
    // return this.http.post<any>(this.API_USERONDELIVERY_SERVER+"waybill-basic", JSON.stringify(payload), {headers: customHeaders})
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/waybill/tracking-scan",
      payload,
      { headers: customHeaders }
    );
  }

  cancelInvoice(invoice: string): Observable<any> {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            type: "CUSTOMER",
          },
        }
      : {};
    const body = {
      invoice: invoice,
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "transaction/cancel/invoice",
      body,
      params
    );
  }

  bookingDetail(booking_id: string): Observable<any> {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization:
              "Basic " +
              btoa(
                "on-booking:iRiLiODOSV1X8WMVCThlOhpwnOYdsRYXiaJI3hIfeDZKaiOkKioUBQr1Y7PlnXhM"
              ),
          }),
          params: {
            booking_id: booking_id,
          },
        }
      : {};
    return this.http.get<any>(
      this.API_ONBOOKING_SERVER + "booking/delivery/basic/detail",
      params
    );
  }
}
